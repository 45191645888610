import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CognoraLogo from '../../assets/Cognora Logo High Res (1).png';

const NotFound = () => {
  return (
    <Box className="auth-container">
      <div className="branding">
        <Link to="/" className="logo">
          <img src={CognoraLogo} alt="Cognora Logo" className="cognora-logo" />
        </Link>
      </div>
      <div className="auth-form">
        <Typography 
          variant="h2" 
          component="h1" 
          sx={{ 
            color: '#2c3e50',
            fontSize: '2.5rem',
            fontWeight: 600,
            marginBottom: '1.5rem'
          }}
        >
          Oops!
        </Typography>
        <Typography 
          variant="body1" 
          paragraph
          sx={{ 
            color: '#333',
            fontSize: '1.1rem',
            marginBottom: '2rem',
            textAlign: 'center'
          }}
        >
          We couldn't find the page you're looking for. Let's get you back on track!
        </Typography>
        <Button 
          component={Link} 
          to="/" 
          className="submit-button"
          sx={{
            backgroundColor: '#FF6B6B',
            '&:hover': {
              backgroundColor: '#FF4B4B'
            }
          }}
        >
          Go to Home
        </Button>

      </div>
      <footer className="auth-footer">
        <Link to="/pricing">Pricing</Link>
        <span className="separator">|</span>
        <Link to="/faq">FAQ</Link>
        <span className="separator">|</span>
        <Link to="/terms-of-service">Terms of Service</Link>
        <span className="separator">|</span>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </footer>
    </Box>
  );
};

export default NotFound;