/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../../Styles/AuthForms.css';
import CognoraLogo from '../../assets/Cognora Logo High Res (1).png';


const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();
  const chatState = location.state?.chatState;
  const [showFullForm, setShowFullForm] = useState(false);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (showFullForm && passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showFullForm]);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const user = await login(credentials.email, credentials.password);
      // Navigate back to chat with preserved state
      navigate('/', { 
        state: chatState,
        replace: true 
      });
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
      // Reset only the password field
      setCredentials(prevState => ({ ...prevState, password: '' }));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (credentials.email) {
      setShowFullForm(true);
    } else {
      setError('Please enter your email address.');
    }
  };

  return (
    <div className="auth-container">
      <div className="branding">
        <Link to="/" className="logo">
          <img src={CognoraLogo} alt="Cognora Logo" className="cognora-logo" />
        </Link>
      </div>
      <div className="auth-form">
        <h2 className="auth-title">Welcome Back</h2>
        <form 
          onSubmit={showFullForm ? handleSubmit : handleContinue}
          autoComplete="on"
        >
          {error && <p className="error-message">{error}</p>}
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              autoComplete="username"
              value={credentials.email}
              onChange={handleChange}
            />
          </div>
          {showFullForm ? (
            <>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="password-input-wrapper">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    required
                    ref={passwordRef}
                    autoComplete="current-password"
                    value={credentials.password}
                    onChange={handleChange}
                  />
                  <button 
                    type="button" 
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>
              <button type="submit" className="submit-button" disabled={isLoading}>
                {isLoading ? 'Signing In...' : 'Sign In'}
              </button>
            </>
          ) : (
            <button type="submit" className="submit-button">Continue</button>
          )}
        </form>
        <div className="auth-links">
          <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
          <p className="auth-link">
            Don't have an account? <Link 
              to="/signup" 
              state={{ 
                chatState: location.state?.chatState,
                selectedTier: 'free' // Preserve the default tier
              }}
            >
              Sign Up
            </Link>
          </p>
        </div>
      </div>
      <footer className="auth-footer">
        <a href="//cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
        <span className="separator">|</span>
        <a href="//cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </footer>
    </div>
  );
};

export default Login;
