/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { signUp, createCheckoutSession, login, sendVerificationEmail } from '../../services/api';
import '../../Styles/AuthForms.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import CognoraLogo from '../../assets/Cognora Logo High Res (1).png';



const SignUp = () => {
  const [userData, setUserData] = useState({email: '', password: '', phone_number: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFullForm, setShowFullForm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTier = location.state?.selectedTier || 'free';
  const selectedInterval = location.state?.interval || 'monthly';
  const selectedCurrency = location.state?.currency || 'usd';
  const chatState = location.state?.chatState;
  const passwordRef = useRef(null);
  const promoCode = location.state?.promoCode;
  const trialdays = location.state?.trialdays;

  useEffect(() => {
    console.log('Selected tier:', selectedTier, 'Interval:', selectedInterval, 'Currency:', selectedCurrency);
  }, [selectedTier, selectedInterval, selectedCurrency]);

  useEffect(() => {
    if (showFullForm && passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showFullForm]);

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (!agreeTerms) {
      setError('You must agree to the Terms and Conditions to sign up.');
      setIsLoading(false);
      return;
    }

    // Password validation
    const passwordRegex = /^.{8,}$/;
    if (!passwordRegex.test(userData.password)) {
      setError('Password must be at least 8 characters long.');
      setIsLoading(false);
      return;
    }

    console.log('Submitting signup form for tier:', selectedTier);
    try {
      await signUp({ 
        ...userData, 
        name: '', // Send empty string for name
        selectedTier, 
        selectedInterval, 
        selectedCurrency,
        chatState,
        promoCode,
        trialdays
      });
      setSuccess('Verification email sent! Please check your email to complete the registration.');
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (userData.email) {
      setShowFullForm(true);
    } else {
      setError('Please enter your email address.');
    }
  };

  return (
    <div className="auth-container">
      <div className="branding">
        <Link to="/" className="logo">
          <img src={CognoraLogo} alt="Cognora Logo" className="cognora-logo" />
        </Link>
      </div>
      <div className="auth-form">
        <h2 className="auth-title">Create Account</h2>
        <form onSubmit={showFullForm ? handleSubmit : handleContinue}>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={userData.email}
              onChange={handleChange}
            />
          </div>
          {showFullForm ? (
            <>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="password-input-wrapper">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    required
                    ref={passwordRef}
                    value={userData.password}
                    onChange={handleChange}
                  />
                  <button 
                    type="button" 
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>
              <div className="form-group checkbox-group">
                <input
                  type="checkbox"
                  id="agreeTerms"
                  checked={agreeTerms}
                  onChange={(e) => setAgreeTerms(e.target.checked)}
                />
                <label htmlFor="agreeTerms">
                  I agree to the <a href="//cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="//cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </label>
              </div>
              <button type="submit" className="submit-button" disabled={isLoading || !agreeTerms}>
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </button>
            </>
          ) : (
            <button type="submit" className="submit-button">Continue</button>
          )}
        </form>
        <p className="auth-link">
          Already have an account? {/* Update this link */}
          <Link 
            to="/login" 
            state={{ chatState: location.state?.chatState }} // Pass along the chatState
          >
            Sign In
          </Link>
        </p>
      </div>
      <footer className="auth-footer">
        <a href="//cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
        <span className="separator">|</span>
        <a href="//cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </footer>
    </div>
  );
};

export default SignUp;
