/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { Plus, X, Edit, LogOut, User, BookOpen, MessageCircle, ChevronLeft, ChevronRight, FileText, Trash2, CheckSquare } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import '../../Styles/Sidebar.css'
import { fetchChats, addChat, removeChat, renameChat, removeChats } from '../../services/api';
import { useQuery, useQueryClient } from 'react-query';
import LoadingOverlay from './LoadingOverlay';

const Sidebar = ({ isOpen, toggleSidebar, updateTitle, showCustomToast }) => {
  const { user, logout } = useAuth();
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingChatTitle, setEditingChatTitle] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isUpgradeClicked, setIsUpgradeClicked] = useState(false);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedChats, setSelectedChats] = useState(new Set());

  const { data: chatsData, isLoading: isChatsLoading } = useQuery(
    'chats',
    fetchChats,
    {
      enabled: !!user,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
      refetchOnWindowFocus: true,
      onError: (error) => console.error('Error fetching chats data:', error)
    }
  );

  const chats = chatsData?.chats || [];
  const remainingChats = chatsData?.remainingChats;

  const sortedChats = useMemo(() => {
    return [...chats].sort((a, b) => new Date(b.last_modified) - new Date(a.last_modified));
  }, [chats]);

  const categorizedChats = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setDate(lastMonth.getDate() - 30);

    return sortedChats.reduce((acc, chat) => {
      const chatDate = new Date(chat.last_modified);
      if (chatDate >= today) {
        acc.today.push(chat);
      } else if (chatDate >= yesterday) {
        acc.yesterday.push(chat);
      } else if (chatDate >= lastWeek) {
        acc.lastWeek.push(chat);
      } else if (chatDate >= lastMonth) {
        acc.lastMonth.push(chat);
      } else {
        acc.older.push(chat);
      }
      return acc;
    }, { today: [], yesterday: [], lastWeek: [], lastMonth: [], older: [] });
  }, [sortedChats]);

  const handleAddChat = async () => {
    try {
      navigate(`new`);
      if (window.innerWidth <= 768) {
        toggleSidebar();
      }
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  const handleRemoveChat = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const currentIndex = sortedChats.findIndex(chat => chat.id === id);
      const nextChatId = currentIndex < sortedChats.length - 1 ? sortedChats[currentIndex + 1].id : 
                         currentIndex > 0 ? sortedChats[currentIndex - 1].id : 'new';

      await removeChat(id);
      queryClient.invalidateQueries('chats');
      queryClient.removeQueries(['messages', id]);
      localStorage.removeItem(`chat_${id}`);

      const currentPath = window.location.pathname;
      const currentChatId = currentPath.split('/').pop();

      navigate(`/${nextChatId}`, {
        replace: true,
        state: { keepSidebarOpen: true }
      });
    } catch (error) {
      console.error('Error removing chat:', error);
    }
  };

  const handleRenameChat = async (id) => {
    try {
      await renameChat(id, editingChatTitle);
      queryClient.invalidateQueries('chats');
      setEditingChatId(null);
      setEditingChatTitle('');
    } catch (error) {
      console.error('Error renaming chat:', error);
    }
  };

  const handleLogout = async () => {
    toggleSidebar();
    await logout();
    navigate('/');
  };

  const handleUpgradeClick = (e) => {
    if (isUpgradeClicked) {
      e.preventDefault();
      return;
    }

    setIsUpgradeClicked(true);
    setTimeout(() => setIsUpgradeClicked(false), 1000);
  };

  const handleSelectChat = (chatId, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedChats(prev => {
      const newSet = new Set(prev);
      if (newSet.has(chatId)) {
        newSet.delete(chatId);
      } else {
        newSet.add(chatId);
      }
      return newSet;
    });
  };

  const handleMassDelete = async () => {
    try {
      const chatIdsArray = Array.from(selectedChats);
      await removeChats(chatIdsArray);
      
      queryClient.invalidateQueries('chats');
      showCustomToast('success', 'Chats deleted successfully');
      
      const currentPath = window.location.pathname;
      const currentChatId = currentPath.split('/').pop();
      if (chatIdsArray.includes(currentChatId)) {
        navigate('/new', { replace: true });
      }
      
      setSelectedChats(new Set());
      setIsSelectionMode(false);
    } catch (error) {
      console.error('Error deleting multiple chats:', error);
      console.error('Error details:', error.message);
      showCustomToast('error', 'Failed to delete chats');
    }
  };

  const renderChatList = (chats, title) => {
    if (chats.length === 0) return null;
    return (
      <div className="chat-section">
        <h4 className="chat-section-title">{title}</h4>
        <ul>
          {chats.map((chat) => (
            <li key={chat.id} className={isSelectionMode ? 'selection-mode' : ''}>
              {editingChatId === chat.id ? (
                <form onSubmit={(e) => { 
                  e.preventDefault(); 
                  if (editingChatTitle.trim()) {
                    handleRenameChat(chat.id);
                  } else {
                    setEditingChatId(null);
                    setEditingChatTitle('');
                  }
                }}>
                  <input
                    value={editingChatTitle}
                    onChange={(e) => setEditingChatTitle(e.target.value)}
                    onBlur={() => {
                      if (editingChatTitle.trim()) {
                        handleRenameChat(chat.id);
                      } else {
                        setEditingChatId(null);
                        setEditingChatTitle('');
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        setEditingChatId(null);
                        setEditingChatTitle('');
                      }
                    }}
                    autoFocus
                    placeholder="Enter chat name"
                  />
                </form>
              ) : (
                <NavLink to={`/${chat.id}`} onClick={(e) => {
                  if (isSelectionMode) {
                    handleSelectChat(chat.id, e);
                    return;
                  }
                  if (window.innerWidth <= 668) {
                    toggleSidebar();
                  }
                }}>
                  {isSelectionMode && (
                    <input
                      type="checkbox"
                      checked={selectedChats.has(chat.id)}
                      onChange={(e) => handleSelectChat(chat.id, e)}
                      onClick={(e) => e.stopPropagation()}
                      className="chat-checkbox"
                    />
                  )}
                  <span className="chat-title">{chat.title || 'Untitled Chat'}</span>
                  {!isSelectionMode && (
                    <div className="button-group">
                      <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditingChatId(chat.id); setEditingChatTitle(chat.title); }} className="edit-btn">
                        <Edit size={14} />
                      </button>
                      <button onClick={(e) => handleRemoveChat(chat.id, e)} className="remove-btn">
                        <X size={14} />
                      </button>
                    </div>
                  )}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    if (isOpen) {
      const currentPath = window.location.pathname;
      const currentChatId = currentPath.split('/').pop();
      
      if (currentChatId === 'new') return;

      const activeChatElement = document.querySelector(`a[href='/${currentChatId}']`);
      if (activeChatElement) {
        activeChatElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [isOpen, chats]);

  return (
    <aside className={`sidebar ${isOpen ? 'open' : 'collapsed'}`}>
      {user ? (
        <>
          <div className="sidebar-header">
            <div className="user-avatar" aria-label="User avatar">
              {user.name ? user.name.charAt(0).toUpperCase() : ''}
            </div>
            <span className="user-email">{user.email}</span>
            <button className="collapse-btn" onClick={toggleSidebar}>
              <ChevronLeft size={24} />
              <span className="hover-text right">Collapse Sidebar</span>
            </button>
          </div>
          {(user.tier === 'free' || user.tier === 'premium') && (
            <div className='sidebar-upgrade'>
              <Link 
                to="/pricing" 
                className={`upgrade-but ${isUpgradeClicked ? 'clicked' : ''}`}
                onClick={handleUpgradeClick}
                style={{ pointerEvents: isUpgradeClicked ? 'none' : 'auto' }}
              >
                Upgrade Plan
                <span className="hover-text bottom">Get More Features & Chat Credits</span>
              </Link>
            </div>
          )}
          {isOpen && (
            <>
              <nav className="sidebar-nav">
                {chats.length > 0 && (
                  <div className="nav-controls">
                    <div className="select-toggle">
                      <label className="toggle-label">
                        <span className="toggle-text">
                          {isSelectionMode ? "Selecting" : "Select"}
                        </span>
                        <input
                          type="checkbox"
                          checked={isSelectionMode}
                          onChange={() => {
                            setIsSelectionMode(!isSelectionMode);
                            if (isSelectionMode) {
                              setSelectedChats(new Set());
                            }
                          }}
                        />
                        <span className="toggle-slider"></span>
                        <span className="hover-text right">Select Multiple Chats</span>
                      </label>
                      {isSelectionMode && selectedChats.size > 0 && (
                        <button 
                          onClick={handleMassDelete}
                          className="minimal-delete-btn"
                        >
                          <Trash2 size={14} />
                          <span>{selectedChats.size}</span>
                          <span className="hover-text bottom">Delete Selected Chats</span>
                        </button>
                      )}
                    </div>
                  </div>
                )}

                <div className="scrollable-section">
                  {isChatsLoading ? (
                    <LoadingOverlay />
                  ) : (
                    <>
                      {chats.length > 0 ? (
                        <>
                          {renderChatList(categorizedChats.today, "Today")}
                          {renderChatList(categorizedChats.yesterday, "Yesterday")}
                          {renderChatList(categorizedChats.lastWeek, "Last 7 Days")}
                          {renderChatList(categorizedChats.lastMonth, "Last 30 Days")}
                          {renderChatList(categorizedChats.older, "Older")}
                        </>
                      ) : (
                        <p>No Chats Available</p>
                      )}
                    </>
                  )}

                
                </div>
                <button onClick={handleAddChat} className="add-btn">
                  <Plus size={14} /> New Chat
                  <span className="hover-text top">Start New Conversation</span>
                </button>
              </nav>


              <button onClick={handleLogout} className="logout-btn">
                <LogOut size={16} /> Logout
                <span className="hover-text top">Sign Out of Account</span>
              </button>
            </>
          )}
        </>
      ) : (
        <>
          <div className="sidebar-header">
            <div className="user-avatar empty" aria-label="User avatar">
              {/* Remove user.name check as user is not defined in this context */}
            </div>
            <span className="user-email">Welcome, Guest</span>
            <button className="collapse-btn" onClick={toggleSidebar}>
              <ChevronLeft size={24} />
              <span className="hover-text right">Collapse Sidebar</span>
            </button>
          </div>
          {isOpen && (
            <>
              <div className="sidebar-prompt">
                <span>Please sign in to use StudyBuddy</span>
                <Link to="/login" className="login-btn">
                  <User size={16} /> Sign In
                  <span className="hover-text right">Login to Your Account</span>
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </aside>
  );
};

export default React.memo(Sidebar);
