/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft, FaExternalLinkAlt, FaCopy, FaChevronUp, FaMagic, FaInfoCircle, FaPaperPlane, FaChevronLeft, FaChevronRight, FaExchangeAlt } from 'react-icons/fa';
import { MarkdownRenderer, AIResponseRenderer } from './LLMTextRenderer';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import '../../Styles/Expanded.css';
import { toast } from 'react-toastify';
import { Line, Column, Pie } from '@ant-design/plots';
import { Tabs } from 'antd';
import flowchart from 'flowchart.js';

const ExpandedResource = ({ 
  resource, 
  onImprove, 
  onExplain, 
  onClose, 
  onSendMessage, 
  allVersions,
  desiredVersion,
  showCustomToast
}) => {
  const [isCode, setIsCode] = useState(resource.type === 'code');
  const [ispdf, setIspdf] = useState(resource.type === 'pdf');
  const [selectedText, setSelectedText] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState({ top: 0, left: 0 });
  const [isImproveMode, setIsImproveMode] = useState(false);
  const [improveSuggestion, setImproveSuggestion] = useState('');
  const contentRef = useRef(null);
  const improveInputRef = useRef(null);
  const optionsRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [versions, setVersions] = useState([]);  
  const [showDiff, setShowDiff] = useState(false);

  const [activeTab, setActiveTab] = useState('0');

  useEffect(() => {
    if (allVersions && allVersions.length > 0) {
      const sortedVersions = [...allVersions].sort((a, b) => b.version - a.version);
      setVersions(sortedVersions);

      setCurrentIndex(desiredVersion - 1);
      console.log('Desired version:', desiredVersion);
    } else {
      setVersions([resource]);
      setCurrentIndex(0);
    }
  }, [allVersions, resource, desiredVersion]); // Add desiredVersion to dependencies

  useEffect(() => {
    const handleMouseUp = () => {
      console.log('handleMouseUp triggered');
      
      const selection = window.getSelection();
      const text = selection.toString().trim();
      
      // Check if selection is within contentRef
      const isWithinContent = contentRef.current?.contains(selection.anchorNode);
      
      if (!isWithinContent) {
        setShowOptions(false);
        return;
      }

      // Check if text is in previous/removed content
      const isPreviousVersion = selection.anchorNode.parentElement?.closest('.previous-version');
      const isRemovedBlock = selection.anchorNode.parentElement?.closest('.removed-block');
      console.log('Is previous version:', isPreviousVersion);
      console.log('Is removed block:', isRemovedBlock);

      // Only show options if text is selected and it's not in previous/removed content
      if (text && 
          (resource.type === 'essay' || resource.type === 'code') && 
          !isPreviousVersion &&
          !isRemovedBlock) {
        console.log('Valid selection, showing options');
        
        setSelectedText(text);
        
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        
        // Calculate position relative to the viewport
        const viewportPosition = {
          top: rect.top + window.scrollY,
          left: rect.left + (rect.width / 2) + window.scrollX
        };

        // Get the content element's position
        const contentRect = contentRef.current.getBoundingClientRect();

        // Adjust position to be relative to the content element
        const newPosition = {
          top: viewportPosition.top - contentRect.top,
          left: Math.min(viewportPosition.left - contentRect.left, contentRect.width - 100) // Prevent overflow
        };

        setOptionsPosition(newPosition);
        setShowOptions(true);
      } else {
        setShowOptions(false);
      }
    };

    const handleClickOutside = (event) => {
      const isClickInsideContent = contentRef.current?.contains(event.target);
      const isClickInsideOptions = optionsRef.current?.contains(event.target);
      const isClickInsideImproveInput = improveInputRef.current?.contains(event.target);
      
      // If clicking inside the options area while in improve mode, keep everything open
      if (isClickInsideOptions && isImproveMode) {
        return;
      }
      
      // Handle other cases
      if (isClickInsideImproveInput) {
        setShowOptions(true);
        setIsImproveMode(true);
      } else if (isClickInsideOptions) {
        setShowOptions(true);
      } else if (isClickInsideContent && !isImproveMode) {
        setShowOptions(false);
        setImproveSuggestion('');
      } else if (isClickInsideContent && isImproveMode) {
        setIsImproveMode(false);
      } else if (!isClickInsideContent && !isClickInsideOptions && !isClickInsideImproveInput) {
        setShowOptions(false);
        setIsImproveMode(false);
        setImproveSuggestion('');
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousedown', handleClickOutside);

    // Add this new effect
    if (isImproveMode && improveInputRef.current) {
      improveInputRef.current.focus();
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resource.type, isImproveMode, showOptions]);

  const toggleVersionControl = (direction) => {
    if (versions.length <= 1) return;

    let newIndex;
    if (direction === 'next' && currentIndex < versions.length - 1) {
      newIndex = currentIndex + 1;
    } else if (direction === 'previous' && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else {
      return;
    }
    setCurrentIndex(newIndex);
    console.log('Version toggled. New index:', newIndex);
  };

  const currentResourceVersion = versions[currentIndex] || resource;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentResourceVersion.content)
      .then(() => {
        showCustomToast('success', 'Content copied to clipboard');
      })
      .catch(() => {
        showCustomToast('error', 'Failed to copy content');
      });
  };

  const handleImprove = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    console.log('Improve button clicked');
    setIsImproveMode(true);
    console.log('Improve mode set to:', true);
  };

  const handleExplain = (e) => {
    e.stopPropagation();
    console.log('Explain button clicked');
    const message = isCode
      ? `Please explain this code: \`\`\`${selectedText}\`\`\``
      : `Please explain this text: <quote>${selectedText}</quote>`;
    console.log('Sending explain message:', message);
    onExplain(message);
    setShowOptions(false);
  };

  const handleSendImprovement = (e) => {
    e.stopPropagation();
    console.log('Send improvement button clicked');
    const message = isCode 
      ? `I want to improve this code: \`\`\`${selectedText}\`\`\` Here are my thoughts on what to improve: ${improveSuggestion}`
      : `I want to improve this text: <quote>${selectedText}</quote> Here are my thoughts on what to improve: ${improveSuggestion}`;
    console.log('Sending improve message:', message);
    onImprove(message);
    setShowOptions(false);
    setIsImproveMode(false);
    setImproveSuggestion('');
    toast.info('Improvement request sent!');
  };

  // Update the truncateText function to be responsive
  const truncateText = (text, maxLength) => {
    const isMobile = window.innerWidth <= 768; // Standard mobile breakpoint
    const mobileMaxLength = 20; // Shorter length for mobile
    const finalMaxLength = isMobile ? mobileMaxLength : maxLength;
    return text.length > finalMaxLength ? text.substring(0, finalMaxLength) + '...' : text;
  };

  const handleImproveSuggestionKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendImprovement(e);
    }
  };

  // Add this function to compute diffs between versions
  const computeDiff = () => {
    if (currentIndex === 0 || versions.length <= 1) return null;
    
    const currentContent = versions[currentIndex].content;
    const previousContent = versions[currentIndex - 1].content;
    
    // Split into paragraphs and compare whole paragraphs
    const currentParagraphs = currentContent.split('\n\n');
    const previousParagraphs = previousContent.split('\n\n');
    
    const changes = [];
    let maxLength = Math.max(previousParagraphs.length, currentParagraphs.length);
    
    for (let i = 0; i < maxLength; i++) {
      const prevPara = previousParagraphs[i] || '';
      const currPara = currentParagraphs[i] || '';
      
      if (prevPara !== currPara) {
        changes.push({
          previous: prevPara,
          current: currPara,
          type: prevPara && currPara ? 'modified' : 
                !prevPara ? 'added' : 'removed'
        });
      }
    }
    
    return changes;
  };

  // Add this function to render diff content
  const renderDiffContent = () => {
    const changes = computeDiff();
    if (!changes || changes.length === 0) return null;

    return (
      <div className="diff-content">
        {changes.map((change, index) => (
          <div key={index} className="change-block">
            {change.type === 'modified' && (
              <>
                <div className="previous-version">
                  <div className="change-label">Previous Version:</div>
                  <div className="content removed">
                    <AIResponseRenderer content={change.previous} />
                  </div>
                </div>
                <div className="arrow">↓</div>
                <div className="current-version">
                  <div className="change-label">Current Version:</div>
                  <div className="content added">
                    <AIResponseRenderer content={change.current} />
                  </div>
                </div>
              </>
            )}
            {change.type === 'added' && (
              <div className="added-block">
                <div className="change-label">Added Content:</div>
                <div className="content added">
                  <AIResponseRenderer content={change.current} />
                </div>
              </div>
            )}
            {change.type === 'removed' && (
              <div className="removed-block">
                <div className="change-label">Removed Content:</div>
                <div className="content removed">
                  <AIResponseRenderer content={change.previous} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const parseVisualizationData = (content) => {
    try {
      const data = JSON.parse(content);
      return {
        type: data.type, // 'graph', 'erd', 'chart'
        subtype: data.subtype, // 'line', 'bar', 'pie', 'flow', etc.
        data: data.data,
        config: data.config || {},
        tabs: data.tabs || [] // For multiple visualizations
      };
    } catch (error) {
      console.error('Failed to parse visualization data:', error);
      return null;
    }
  };

  const renderVisualization = (vizData) => {
    if (!vizData) return null;

    // Add default configuration values
    const defaultConfig = {
      xAxis: {
        label: {
          fontSize: 12,
          style: {
            fontSize: 12
          }
        }
      },
      yAxis: {
        label: {
          fontSize: 12,
          style: {
            fontSize: 12
          }
        }
      },
      label: {
        fontSize: 12,
        style: {
          fontSize: 12
        }
      }
    };

    const commonProps = {
      ...defaultConfig,
      ...vizData.config,
      data: vizData.data,
      animation: true,
    };

    switch (vizData.subtype) {
      case 'line':
        return <Line {...commonProps} />;
      case 'bar':
        return <Column {...commonProps} />;
      case 'pie':
        return <Pie 
          {...commonProps}
          angleField={vizData.config?.angleField || 'value'}
          colorField={vizData.config?.colorField || 'type'}
        />;
      case 'flow':
        return (
          <FlowChartRenderer 
            data={vizData.data} 
            config={vizData.config} 
          />
        );
      default:
        return <div>Unsupported visualization type: {vizData.subtype}</div>;
    }
  };

  const FlowChartRenderer = ({ data, config }) => {
    const containerRef = useRef(null);

    useEffect(() => {
      if (containerRef.current && data) {
        try {
          // Parse the flowchart data
          const diagram = flowchart.parse(data);
          // Clear previous content
          containerRef.current.innerHTML = '';
          // Render the flowchart with config
          diagram.drawSVG(containerRef.current, config || {});
        } catch (error) {
          console.error('Error rendering flowchart:', error);
          containerRef.current.innerHTML = 'Error rendering flowchart';
        }
      }
    }, [data, config]);

    return (
      <div 
        ref={containerRef}
        className="flow-chart-container"
      />
    );
  };

  const renderContent = () => {
    if (currentResourceVersion.type === 'visualization') {
      const vizData = parseVisualizationData(currentResourceVersion.content);
      
      if (!vizData) {
        return <div>Invalid visualization data</div>;
      }

      if (vizData.tabs && vizData.tabs.length > 0) {
        return (
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            items={vizData.tabs.map((tab, index) => ({
              key: index.toString(),
              label: tab.title,
              children: renderVisualization(tab.visualization)
            }))}
          />
        );
      }

      return renderVisualization(vizData);
    }

    if (currentResourceVersion.type === 'pdf') {
      return <div>{currentResourceVersion.content}</div>;
    } else if (isCode) {
      return (
        <SyntaxHighlighter
          language={currentResourceVersion.language || 'text'}
          wrapLines={true}
          wrapLongLines={true}
          customStyle={{
            marginTop: '-30px',
            borderRadius: '8px',
            padding: '1rem',
            // Remove maxHeight and height to prevent double scrolling
            width: '100%',
            minHeight: '100%',
            backgroundColor: '#f5f5f5',
          }}
        >
          {currentResourceVersion.content}
        </SyntaxHighlighter>
      );
    } else {
      return (
        <div className="essay-text">
          {showDiff && currentIndex > 0 ? (
            renderDiffContent()
          ) : (
            <AIResponseRenderer content={currentResourceVersion.content} />
          )}
        </div>
      );
    }
  };

  return (
    <div className="expanded-resource">
      <div className="expanded-resource-header">
        <button className="back-button" onClick={onClose}>
          <FaArrowLeft /> 
        </button>
        <h2 title={currentResourceVersion.title}>
          {truncateText(currentResourceVersion.title, 35)}
        </h2>
        {currentResourceVersion.type === 'pdf' && (
          <a 
            href={currentResourceVersion.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="pdf-link"
          >
            View Original PDF <FaExternalLinkAlt />
          </a>
        )}
        {(isCode || currentResourceVersion.type === 'essay') && (
          <button className="copy-button exp" onClick={copyToClipboard}>
            <FaCopy /> Copy
          </button>
        )}
      </div>
      <div 
        ref={contentRef}
        className={`expanded-resource-content ${
          currentResourceVersion.type === 'visualization' ? 'visualization-content' : ''
        } ${isCode ? 'code-content' : ''} ${ispdf ? 'pdf-content' : 'not-pdf-content'} ${currentResourceVersion.type === 'essay' || (!isCode && !ispdf) ? 'essay-content' : ''}`}
      >
        {renderContent()}
      </div>
      {showOptions && (
        <div 
          ref={optionsRef}
          className="text-options"
          style={{
            position: 'absolute',
            top: `${optionsPosition.top}px`,
            left: `${optionsPosition.left}px`,
          }}
        >
          {isImproveMode ? (
            <div className="improve-input-container">
              <input
                type="text"
                ref={improveInputRef}
                value={improveSuggestion}
                onChange={(e) => setImproveSuggestion(e.target.value)}
                onKeyDown={handleImproveSuggestionKeyPress}
                placeholder="What would you like to improve?"
                className="improve-suggestion-input"
              />
              <button onClick={handleSendImprovement} className="send-improvement-button">
                <FaPaperPlane />
              </button>
            </div>
          ) : (
            <>
              {<button onClick={handleImprove} className="option-button improve">
                <FaMagic /> Improve
              </button> }
              <button onClick={(e) => handleExplain(e)} className="option-button explain">
                <FaInfoCircle /> Explain
              </button>
            </>
          )}
        </div>
      )}
      {currentResourceVersion.type !== 'pdf' && versions.length > 0 && (
        <div className="smart-footer">
          {versions.length > 1 && (
            <div className="version-controls">
              <button
                className="version-nav-btn prev"
                onClick={() => toggleVersionControl('previous')}
                disabled={currentIndex <= 0}
                aria-label="Previous version"
              >
                <FaChevronLeft />
              </button>
              <div className="version-display">
                <span className="version-current">{currentIndex + 1}</span>
                <span className="version-divider"> of </span>
                <span className="version-total">{versions.length}</span>
              </div>
              <button
                className="version-nav-btn next"
                onClick={() => toggleVersionControl('next')}
                disabled={currentIndex >= versions.length - 1}
                aria-label="Next version"
              >
                <FaChevronRight />
              </button>
              {currentResourceVersion.type === 'essay' && currentIndex > 0 && (
                <button
                  className="version-nav-btn diff-toggle"
                  onClick={() => setShowDiff(!showDiff)}
                  aria-label="Toggle changes view"
                >
                  <FaExchangeAlt />
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExpandedResource;
