/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// Add this near the top of your file
// eslint-disable-next-line react/no-deprecated


import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate, Switch } from 'react-router-dom';
import SignUp from './Components/Auth/Signup';
import Login from './Components/Auth/Login.jsx';
import Settings from './Components/Settings.jsx';
import PrivateRoute from './Components/Auth/PrivateRoute';
import Chats from './Components/Chats';
import { AuthProvider, useAuth } from './hooks/useAuth';
import './Styles/GlobalStyles.css';
import './App.css';
import { CssBaseline, Container} from '@mui/material';
import Home from './Components/Home';
import DevUpdates from './Components/Info Pages/DevUpdates';
import Features from './Components/Features';
import ContactUs from './Components/ContactUs';
import PrivacyPolicy from './Components/Info Pages/PrivacyPolicy'
import Footer from './Components/Partials/Footer';
import Pricing from './Components/Pricing';
import NavBar from './Components/Partials/NavBar.jsx';
import NotFound from './Components/Info Pages/404';
import ChatWithPDF from './Components/ChatWIthPDF.jsx'
import LearningOptions from './Components/LearningOptions';
import LoadingOverlay from './Components/Partials/LoadingOverlay';
import { QueryClient, QueryClientProvider } from 'react-query';
import TermsofService from './Components/Info Pages/TermsofService.jsx';
import SitewideBanner from './Components/Partials/SitewideBanner'; // Add this import
import { useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Styles/Toast.css';  // Add this import
import PaymentConfirmation from './Components/PaymentConfirmation';
import BetaSignup from './Components/Auth/BetaSignup';
import { fetchChats, getProfile, logout } from './services/api';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import VerifyEmail from './Components/Auth/VerifyEmail';
import BetaLanding from './Components/BetaLanding';
import ScreenSizeWarning from './Components/Partials/ScreenSizeWarning';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

const PublicRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? <Navigate to="/" replace /> : children;
};

const ExternalRedirect = ({ to, onMount }) => {
  useEffect(() => {
    if (onMount) onMount();
    window.location.href = to;
  }, [to, onMount]);
  return null;
};

const AppContent = () => {
  const { isLoading, globalLoading, user } = useAuth();
  const location = useLocation();
  
  // Only for handling NotFound delay
  const [showNotFound, setShowNotFound] = React.useState(false);

  useEffect(() => {
    let timer;
    if (!user) {
      timer = setTimeout(() => {
        setShowNotFound(true);
      }, 1000); // 1 second delay before showing NotFound
    }

    return () => clearTimeout(timer);
  }, [user]);

  // Add state for screen size check
  const [isScreenTooSmall, setIsScreenTooSmall] = React.useState(false);

  // Add effect to check screen size
  useEffect(() => {
    const checkScreenSize = () => {
      setIsScreenTooSmall( (window.innerWidth > 600 && window.innerHeight < 600) || (window.innerWidth < 300));
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // Add scroll to top effect
  useEffect(() => {
    const isChatsPage = /^\/($|[^/]+\/?$)/.test(location.pathname);
    if (!isChatsPage) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  const isChatsPage = /^\/($|[^/]+\/?$)/.test(location.pathname);
  const isFeaturesPage = /^\/features/.test(location.pathname);
  const isPricingPage = /^\/pricing/.test(location.pathname);
  const isPolicyPage = /^\/privacy-policy/.test(location.pathname);
  const isTermsPage = /^\/terms-of-service/.test(location.pathname);
  const isContactPage = /^\/contact/.test(location.pathname);
  
  const showNavBar = isFeaturesPage || isPricingPage || isContactPage ||isTermsPage || isPolicyPage;
  const showFooter = isFeaturesPage || isPricingPage || isPolicyPage || isTermsPage || isContactPage;

  // Use useQuery for subscription data
  const { data: subscriptionData } = useQuery(
    'subscription',
    () => getProfile(),
    {
      enabled: !!user,
      select: (data) => data.subscription,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  // Optimize chats query
  const { data: chatsData, isLoading: isChatsLoading } = useQuery(
    ['chats', user?.id], // Add user.id as part of the key
    fetchChats,
    {
      enabled: !!user,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      onError: (error) => console.error('Error fetching chats data:', error)
    }
  );

  // Memoize derived data
  const { chats, remainingChats } = React.useMemo(() => ({
    chats: chatsData?.chats || [],
    remainingChats: chatsData?.remainingChats
  }), [chatsData]);

  // Add this function to sort chats
  const sortedChats = React.useMemo(() => {
    return [...chats].sort((a, b) => new Date(b.last_modifield) - new Date(a.last_modifield));
  }, [chats]);

  if (isLoading || globalLoading) {
    return <LoadingOverlay />;
  }

  // Add screen size check
  if (isScreenTooSmall) {
    return <ScreenSizeWarning />;
  }

  return (
    <div className="app-container">

      <main className="content">
        <CssBaseline />
        <Container maxWidth disableGutters>
          {showNavBar && <NavBar />}
          <Routes>
          <Route path="/" element={
              <Chats 
                subscription={subscriptionData}
                remainingChats={remainingChats} 
              />
            } />
            <Route 
              path="/new" 
              element={<Navigate to="/" replace />} 
            />
            <Route 
              path="/undefined" 
              element={<Navigate to="/" replace />} 
            />
            <Route path="/home" element={<Home />} />
            <Route path="/beta" element={<BetaLanding />} />
            <Route 
              path="/privacy-policy" 
              element={<ExternalRedirect to="https://cognora.ca/privacy-policy" />} 
            />
            <Route 
              path="/terms-of-service" 
              element={<ExternalRedirect to="https://cognora.ca/terms-of-service" />} 
            />
            <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/beta-signup" element={<BetaSignup />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/pricing/:tierName" element={<Pricing />} />
            <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
            <Route path="/reset-password" element={<PublicRoute><ResetPassword /></PublicRoute>} />
            <Route path="/verify-email" element={<PublicRoute><VerifyEmail /></PublicRoute>} />
            <Route 
              path="/logout" 
              element={
                <PublicRoute>
                  <ExternalRedirect 
                    to="/login" 
                    onMount={() => logout()} 
                  />
                </PublicRoute>
              } 
            />
            <Route 
              path="/:chatId?" 
              element={
                user ? (
                  <Chats 
                    subscription={subscriptionData}
                    remainingChats={remainingChats}
                  />
                ) : (
                  showNotFound ? <NotFound /> : <LoadingOverlay />
                )
              }
            />
          </Routes>
        </Container>
      </main>
      {showFooter && <Footer />}
    </div>
  );
};

const App = () => {
  useEffect(() => {
    const getFontSizeValue = (size) => {
      switch (size) {
        case 'x-small':
          return '12px';
        case 'small':
          return '14px';
        case 'medium':
          return '16px';
        case 'large':
          return '18px';
        case 'x-large':
          return '20px';
        default:
          return '16px'; // medium
      }
    };

    const setFontSize = () => {
      const fontSize = localStorage.getItem('fontSize') || 'medium';
      document.documentElement.style.fontSize = getFontSizeValue(fontSize);
    };

    setFontSize();

    window.addEventListener('storage', setFontSize);

    return () => {
      window.removeEventListener('storage', setFontSize);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <AppContent />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
