import React, { useState } from 'react';
import { ChevronDown, Check } from 'lucide-react';
import '../Styles/BetaLanding.css';
import { sendBetaApplication } from '../services/api';

const BetaLanding = () => {
  const [email, setEmail] = useState('');
  const [aiUsage, setAiUsage] = useState('');
  const [studyHours, setStudyHours] = useState('');
  const [feedbackFrequency, setFeedbackFrequency] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendBetaApplication(email, aiUsage, studyHours, feedbackFrequency);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Failed to submit beta application:', error);
      // Handle the error, maybe show an error message to the user
    }
  };

  return ( 
    <div className="beta-landing">
      <header className="beta-header">
        <h1>StudyBuddy Beta</h1>
        <p>Experience unlimited AI-powered learning, free for a limited time</p>
      </header>

      <section className="beta-cta">
        <h2>Join Our Exclusive Beta Program</h2>
        <p>Be among the first to try StudyBuddy and shape the future of education</p>
        {!isSubmitted ? (
          <>
            <form onSubmit={handleSubmit} className="beta-form">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <select
                value={aiUsage}
                onChange={(e) => setAiUsage(e.target.value)}
                required
              >
                <option value="">How often do you use AI tools for studying?</option>
                <option value="never">Never</option>
                <option value="rarely">Rarely</option>
                <option value="sometimes">Sometimes</option>
                <option value="often">Often</option>
                <option value="daily">Daily</option>
              </select>
              <select
                value={studyHours}
                onChange={(e) => setStudyHours(e.target.value)}
                required
              >
                <option value="">How many hours do you study per week?</option>
                <option value="0-5">0-5 hours</option>
                <option value="6-10">6-10 hours</option>
                <option value="11-20">11-20 hours</option>
                <option value="21-30">21-30 hours</option>
                <option value="30+">30+ hours</option>
              </select>
              <select
                value={feedbackFrequency}
                onChange={(e) => setFeedbackFrequency(e.target.value)}
                required
              >
                <option value="">How often are you willing to provide feedback?</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="biweekly">Bi-weekly</option>
                <option value="monthly">Monthly</option>
                <option value="occasionally">Occasionally</option>
              </select>
              <button type="submit">Apply for Beta Access</button>
            </form>
            <p className="signup-note">
              Note: Applying for beta access will prevent you from signing up normally. 
              Signing up through the regular process will void your beta application.
            </p>
          </>
        ) : (
          <div className="success-message">
            <div className="success-icon">
              <Check size={32} />
            </div>
            <div className="success-content">
              <h3>Application Received!</h3>
              <p>Thank you for applying to our beta program. We'll be in touch soon with next steps upon approval.</p>
            </div>
          </div>
        )}
      </section>

      <section className="beta-features">
        <h2>What's in Store for Beta Testers</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Unlimited AI-Powered Tutoring</h3>
            <p>Get personalized help across various subjects with our advanced AI tutor, without any message limits.</p>
          </div>
          <div className="feature-card">
            <h3>Indefinite Access</h3>
            <p>Enjoy beta access for an indefinite period. We'll notify you well in advance before any changes.</p>
          </div>
          <div className="feature-card">
            <h3>Shape the Future</h3>
            <p>Provide feedback and suggestions to directly influence the development of StudyBuddy.</p>
          </div>
          <div className="feature-card">
            <h3>Early Access to New Features</h3>
            <p>Be the first to try out new StudyBuddy features before they're released to the public.</p>
          </div>
          <div className="feature-card">
            <h3>Personalized Learning Experience</h3>
            <p>Benefit from AI-driven personalization that adapts to your unique learning style and needs.</p>
          </div>
          <div className="feature-card">
            <h3>Priority Support</h3>
            <p>Get fast-track access to our support team for any questions or issues you may encounter.</p>
          </div>
        </div>
      </section>

      <section className="beta-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-list">
          <details>
            <summary>How long will I have access to the beta? <ChevronDown size={20} /></summary>
            <p>You'll have indefinite access to the beta. We'll provide ample notice before making any changes to the program.</p>
          </details>
          <details>
            <summary>Is there a limit to how much I can use StudyBuddy during the beta? <ChevronDown size={20} /></summary>
            <p>No, during the beta you have unlimited access to all features without any message restrictions.</p>
          </details>
          <details>
            <summary>What kind of feedback are you looking for? <ChevronDown size={20} /></summary>
            <p>We're interested in your thoughts on usability, feature suggestions, and any bugs you might encounter. Your input will directly shape the future of StudyBuddy.</p>
          </details>
          <details>
            <summary>Can I cancel my beta participation? <ChevronDown size={20} /></summary>
            <p>Yes, you can cancel your participation in the beta program at any time without any obligations.</p>
          </details>
        </div>
      </section>
    </div>
  );
};

export default BetaLanding;