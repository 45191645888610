/* eslint-disable no-unused-vars */
import axios from 'axios';

const API_URL = 'https://api.studybuddy.lekan.ca';

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const makeRequest = async (method, url, data = null, config = {}) => {
  return axiosInstance({
    method,
    url,
    data,
    ...config,
  });
};

export const signUp = async (userData) => {
  try {
    const response = await makeRequest('post', '/users/register', userData);
    localStorage.setItem('token', response.data.token);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const response = await makeRequest('post', '/users/login', { email, password });
    localStorage.setItem('token', response.data.token);
    return response.data.user;
  } catch (error) {
    throw error;
  }
};

export const getProfile = async () => {
  try {
    const response = await makeRequest('get', '/users/profile');
    return {
      ...response.data,
      memoryArray: Array.isArray(response.data.memoryArray) 
        ? response.data.memoryArray 
        : JSON.parse(response.data.memoryArray || '[]')
    };
  } catch (error) {
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const deleteAccount = async () => {
  try {
    const response = await makeRequest('delete', '/users/delete-account');
    localStorage.removeItem('token');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteChat = async (chatId) => {
  try {
    const response = await makeRequest('delete', `/chats/${chatId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createChat = async (title) => {
  return await makeRequest('post', '/chats', { title });
};

export const updateNotificationPreferences = async () => {
  // Implement notification preferences update logic
};

export const changePassword = async (currentPassword, newPassword) => {
  try {
    const response = await makeRequest('put', '/users/change-password', { current_password: currentPassword, new_password: newPassword });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const renameChat = async (chatId, newTitle) => {
  return await makeRequest('put', `/chats/${chatId}`, { title: newTitle });
};


export const getMessages = async (chatId) => {
  return await makeRequest('get', `/chats/${chatId}/messages`);
};

export const createCheckoutSession = async (tier, interval, currency, promoCode, trialdays) => {
  try {
    const payload = { tier, interval, currency };
    if (promoCode) {
      payload.promoCode = promoCode;  
    }
    if (trialdays) {
      payload.trialdays = trialdays;
    }
    const response = await makeRequest('post', '/create-checkout-session', payload);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to create checkout session');
    }
  } catch (error) {
    throw error;
  }
};

export const handleStripePortalRedirect = async () => {
  try {
    const response = await makeRequest('post', '/create-customer-portal-session');
    const data = response.data;
    if (response.status === 200) {
      window.location.href = data.url;
    } else {
      throw new Error('Failed to redirect to Stripe portal');
    }
  } catch (error) {
    throw error;
  }
};

export function askAITutor(subject, question, onChunk, onError, onComplete) {
  const url = new URL(`${API_URL}/chats/homepage`);
  url.searchParams.append('subject', subject);
  url.searchParams.append('question', question);

  const eventSource = new EventSource(url.toString());

  let fullResponse = '';

  eventSource.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);

      if (data.response !== undefined && data.response !== '') { 
        const chunk = data.response;
        fullResponse += chunk;
        onChunk(chunk);
      } else if (data.response === '') {
        // Handle empty response
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      onError('Error parsing AI response');
      eventSource.close();
    }
  };

  eventSource.onerror = (error) => {
    onError('An error occurred while fetching the response. Please try again.');
    eventSource.close();
  };

  eventSource.addEventListener('end', () => {
    onComplete(fullResponse);
    eventSource.close();
  });

  return () => {
    eventSource.close();
  };
}

export async function askAITutorNonStreaming(subject, question) {
  try {
    const data = { subject, question };
    const response = await makeRequest('post', '/chats/homepage', data);
    return { response: response.data.response, error: null };
  } catch (error) {
    let errorMessage = 'An unexpected error occurred. Please try again.';

    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorMessage = error.response.data?.error || `Server error: ${error.response.status}`;
        if (error.response.status === 404) {
          errorMessage = 'The AI Tutor service is not available at the moment. Please try again later.';
        }
      } else if (error.request) {
        errorMessage = 'No response received from server. Please check your connection.';
      } else {
        errorMessage = error.message || 'An error occurred while setting up the request.';
      }
    }

    return { 
      response: null, 
      error: errorMessage
    };
  }
}

export const updateProfile = async (profileData) => {
  try {
    const response = await makeRequest('put', '/users/profile', profileData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchStudyPlans = async () => {
  return;
};

export const fetchChats = async () => {
  try {
    const timestamp = new Date().getTime();
    const response = await makeRequest('get', `/chats?t=${timestamp}`);
    
    if (response.data && response.data.chats && Array.isArray(response.data.chats)) {
      return {
        chats: response.data.chats,
        subscription: response.data.subscription,
        remainingChats: response.data.remainingChats
      };
    } else {
      return { chats: [], subscription: null, remainingChats: null };
    }
  } catch (error) {
    return { chats: [], subscription: null, remainingChats: null };
  }
};

export const addChat = async (title = 'New Chat') => {
  try {
    const response = await makeRequest('post', '/chats', { title });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeChat = async (id) => {
  try {
    await makeRequest('delete', `/chats/${id}`);
  } catch (error) {
    throw error;
  }
};

export const fetchMessages = async (chatId) => {
  try {
    const response = await makeRequest('get', `/chats/${chatId}/messages`);
    return response.data.messages;
  } catch (error) {
    // Check for specific error conditions
    if (error.response?.status === 403) {
      const customError = new Error('Chat not found');
      customError.isNotFoundError = true;
      throw customError;
    }
    throw error;
  }
};

export const editMessage = async (chatId, messageId, content, branchId) => {
  try {
    const response = await makeRequest('put', `/chats/${chatId}/messages/${messageId}/edit`, {
      content,
      branchId
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async (chatId, message, mode, signal, files = [], branchId = null) => {
  try {
    const formData = new FormData();
    formData.append('content', message);
    formData.append('mode', mode);
    formData.append('branchId', branchId);
    
    if (files.length > 0) {
      formData.append('pdf_0', files[0].file);
    }

    const response = await fetch(`${API_URL}/chats/${chatId}/messages`, {
      method: 'POST',
      headers: {
        'Accept': 'text/event-stream',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Cache-Control': 'no-cache',
        'Connection': 'keep-alive'
      },
      body: formData,
      signal,
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.text();
      throw new Error(`HTTP error! status: ${response.status} - ${errorData}`);
    }

    const stream = streamResponse(response);
    return { stream, response };
  } catch (error) {
    if (error.name === 'AbortError') {
      error.isAborted = true;
    }
    console.error('Send message error:', error);
    throw error;
  }
};

const streamResponse = (response) => {
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let buffer = '';
  
  return {
    async *[Symbol.asyncIterator]() {
      let isComplete = false;
      try {
        while (!isComplete) {
          const { done, value } = await reader.read();
          
          if (done) {
            isComplete = true;
            // Process any remaining data in buffer
            if (buffer.trim()) {
              try {
                const data = JSON.parse(buffer);
                yield data;
              } catch (e) {
                console.warn('Failed to parse final buffer:', e);
              }
            }
            break;
          }
          
          const chunk = decoder.decode(value, { stream: true });
          buffer += chunk;
          
          const lines = buffer.split('\n');
          buffer = lines.pop() || ''; // Keep the last incomplete line in buffer
          
          for (const line of lines) {
            if (line.trim().startsWith('data: ')) {
              try {
                const data = JSON.parse(line.slice(6));
                yield data;
              } catch (e) {
                console.warn('Failed to parse chunk:', e);
                continue;
              }
            }
          }
        }
      } catch (error) {
        console.error('Stream error:', error);
        throw error; // Propagate the error
      } finally {
        try {
          await reader.cancel();
        } catch (e) {
          console.warn('Error cancelling reader:', e);
        }
      }
    }
  };
};

export const regenerateMessage = async (chatId, messageId, onChunk, mode) => {
  try {
    const response = await fetch(`${API_URL}/chats/${chatId}/messages/${messageId}/regenerate`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': 'text/event-stream',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mode })
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to regenerate message: ${errorData.message || response.statusText}`);
    }
    
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let regeneratedContent = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          try {
            const data = JSON.parse(line.slice(5).trim());
            if (data.content) {
              regeneratedContent += data.content;
              onChunk(data.content);
            }
          } catch (error) {
            // Error handling
          }
        }
      }
    }

    return { content: regeneratedContent };
  } catch (error) {
    throw error;
  }
};

export const createNewChat = async () => {
  try {
    const response = await makeRequest('post', '/chats', { title: 'New Chat' });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createPDFChat = async (formData) => {
  try {
    const response = await makeRequest('post', '/pdf-chats', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPDFChats = async () => {
  try {
    const response = await makeRequest('get', '/pdf-chats');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePDFChat = async (chatId) => {
  try {
    const response = await makeRequest('delete', `/pdf-chats/${chatId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPDFMessages = async (chatId) => {
  try {
    const response = await makeRequest('get', `/pdf-chats/${chatId}/messages`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendPDFMessage = async (chatId, content) => {
  try {
    const response = await makeRequest('post', `/pdf-chats/${chatId}/messages`, { content });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendInitialPDFMessage = async (chatId) => {
  try {
    const response = await makeRequest('post', `/pdf-chats/${chatId}/initial-message`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await makeRequest('post', '/users/forgot-password', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
}; 

export const sendVerificationEmail = async (email, userData) => {
  try {
    const response = await makeRequest('post', '/users/verify-email', { email, userData });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyEmailToken = async (token) => {
  try {
    const response = await makeRequest('post', '/users/verify-email-token', { token });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createVerifiedUser = async (oneTimeToken, password) => {
  try {
    const response = await makeRequest('post', '/users/create-verified-user', { oneTimeToken, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await makeRequest('post', '/users/reset-password', { token, newPassword });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendContactMessage = async (contactData) => {
  try {
    const response = await makeRequest('post', '/contact', contactData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendBetaApplication = async (email, aiUsage, studyHours, feedbackFrequency) => {
  try {
    const response = await makeRequest('post', '/beta/apply', {
      email,
      question1: aiUsage,
      question2: studyHours,
      question3: feedbackFrequency
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const betaSignup = async (name, email, password, token) => {
  try {
    const response = await makeRequest('post', '/beta/signup', {
      name,
      email,
      password,
      token
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadPDF = async (pdfKey) => {
  try {
    const response = await makeRequest('get', `/pdf/${pdfKey}`, null, {
      responseType: 'blob',
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `document_${pdfKey.split('/').pop()}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    throw error;
  }
};

export const getUserMemory = async () => {
  try {
    const response = await makeRequest('get', '/users/memory');
    return response.data.memoryArray;
  } catch (error) {
    throw error;
  }
};

export const updateUserMemory = async (memoryArray) => {
  try {
    const response = await makeRequest('put', '/users/memory', { memoryArray });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add these new functions for branch and edit management
export const createBranch = async (chatId, messageId, branchName) => {
  try {
    const response = await makeRequest('post', `/chats/${chatId}/branches`, {
      messageId,
      branchName
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBranches = async (chatId) => {
  try {
    const response = await makeRequest('get', `/chats/${chatId}/branches`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const switchBranch = async (chatId, branchId) => {
  try {
    const response = await makeRequest('put', `/chats/${chatId}/branches/${branchId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add this new function for text-to-speech
export const generateSpeech = async (text, options = {}) => {
  try {
    if (!text || typeof text !== 'string') {
      throw new Error('Invalid text input');
    }

    if (text.length > 1000) {
      throw new Error('Text exceeds maximum length of 1000 characters');
    }

    const token = localStorage.getItem('token');
    console.log('Making request with text:', text); // Debug log

    const response = await fetch(`${API_URL}/voice/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept': 'audio/mpeg',
      },
      body: JSON.stringify({
        text: text.trim(), // Ensure text is trimmed
        options: {
          model: "aura-asteria-en",
          voice: "default",
          speed: 1.0,
          pitch: 1.0,
          ...options
        }
      }),
      credentials: 'include'
    });

    console.log('Response status:', response.status); // Debug log
    console.log('Response headers:', Object.fromEntries(response.headers.entries())); // Debug log

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText); // Debug log
      throw new Error(`Server error: ${response.status} - ${errorText}`);
    }

    const contentType = response.headers.get('content-type');
    console.log('Content-Type:', contentType); // Debug log

    // Handle potential JSON error response
    if (contentType && contentType.includes('application/json')) {
      const jsonResponse = await response.json();
      console.log('JSON response:', jsonResponse); // Debug log
      if (jsonResponse.error) {
        throw new Error(jsonResponse.error);
      }
      throw new Error('Unexpected JSON response when expecting audio');
    }

    // Verify we're getting audio data
    if (!contentType || !contentType.includes('audio/mpeg')) {
      throw new Error(`Invalid content type: ${contentType}`);
    }

    const buffer = await response.arrayBuffer();
    console.log('Received buffer size:', buffer.byteLength); // Debug log

    if (!buffer || buffer.byteLength === 0) {
      throw new Error('Empty audio data received');
    }

    // Verify the buffer contains actual audio data
    const dataView = new DataView(buffer);
    if (buffer.byteLength < 4) {
      throw new Error('Invalid audio data: too small');
    }

    // Create blob and URL
    const audioBlob = new Blob([buffer], { type: 'audio/mpeg' });
    console.log('Created blob size:', audioBlob.size); // Debug log

    if (audioBlob.size === 0) {
      throw new Error('Created audio blob is empty');
    }

    const audioUrl = URL.createObjectURL(audioBlob);

    // Verify the audio URL
    if (!audioUrl) {
      throw new Error('Failed to create audio URL');
    }

    return {
      audio: audioBlob,
      audioUrl,
      originalText: text,
      options: options,
      cleanup: () => {
        try {
          URL.revokeObjectURL(audioUrl);
        } catch (e) {
          console.error('Failed to revoke object URL:', e);
        }
      }
    };

  } catch (error) {
    console.error('Speech generation error details:', {
      error,
      message: error.message,
      stack: error.stack,
      text,
      timestamp: new Date().toISOString()
    });

    // Rethrow with more specific error message
    throw new Error(`Speech generation failed: ${error.message}`);
  }
};

export const sendGuestMessage = async (context, message, mode) => {
  try {
    const response = await fetch(`${API_URL}/guest/chats/messages`, {
      method: 'POST',
      headers: {
        'Accept': 'text/event-stream',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Connection': 'keep-alive'
      },
      body: JSON.stringify({ 
        context,
        content: message,
        mode
      }),
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.text();
      throw new Error(`HTTP error! status: ${response.status} - ${errorData}`);
    }

    const stream = streamResponse(response);
    return { stream, response };
  } catch (error) {
    console.error('Send guest message error:', error);
    throw error;
  }
};

export const removeChats = async (chatIds) => {
  try {
    const response = await makeRequest('post', '/chats/delete-multiple', { 
      chatIds 
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { axiosInstance };
