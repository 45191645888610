export const getRelativeTime = (date) => {
  const now = new Date();
  const chatDate = new Date(date);
  
  // Handle invalid dates
  if (isNaN(chatDate.getTime())) {
    return 'Unknown date';
  }

  // Reset hours to compare just dates
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dateToCompare = new Date(chatDate.getFullYear(), chatDate.getMonth(), chatDate.getDate());
  
  const diffTime = today.getTime() - dateToCompare.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  // Handle future dates
  if (diffDays < 0) {
    return chatDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  }
  
  // Handle relative times
  switch (true) {
    case diffDays === 0:
      return 'Today';
    case diffDays === 1:
      return 'Yesterday';
    case diffDays < 7:
      return `${diffDays} days ago`;
    case diffDays < 30:
      const weeks = Math.floor(diffDays / 7);
      return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
    default:
      return chatDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
  }
};