/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import { login as apiLogin, getProfile, logout as apiLogout, updateUserMemory } from '../services/api';
import { useQueryClient, useQuery } from 'react-query';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [memoryArray, setMemoryArray] = useState([]);

  const queryClient = useQueryClient();

  const { data: profileData } = useQuery(
    'profile',
    getProfile,
    {
      enabled: !!localStorage.getItem('token'),
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      onSuccess: (data) => {
        setUser(data);
        setSubscription(data.subscription);
        setMemoryArray(data.memoryArray || []);
        
        setIsAuthenticated(true);
      },
      onError: () => {
        logout();
      },
      retry: false
    }
  );

  const checkUser = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) {

    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      checkUser();
    }, 500);

    return () => clearTimeout(timer);
  }, [checkUser]);

  const login = async (email, password) => {
    try {
      const userData = await apiLogin(email, password);
      queryClient.invalidateQueries('profile');
      const profileData = await getProfile();
      setUser(profileData);
      setSubscription(profileData.subscription);
      setIsAuthenticated(true);
      return userData.data;
    } catch (error) {
      console.error('Login error in useAuth:', error);
      if (error.response) {
        throw new Error('Login failed. Please check your email and password and try again.');
      } else if (error.request) {
        throw new Error('We\'re having trouble connecting to our servers. Please check your internet connection and try again in a few minutes.');
      } else {
        throw new Error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const logout = useCallback(() => {
    apiLogout();
    setUser(null);
    setIsAuthenticated(false);
    setSubscription(null);

    queryClient.clear();

    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('chat_')) {
        localStorage.removeItem(key);
      }
    });
    
    localStorage.removeItem('token');
  }, [queryClient]);

  const updateMemory = async (newMemoryArray) => {
    try {
      await updateUserMemory(newMemoryArray);
      setMemoryArray(newMemoryArray);
      queryClient.invalidateQueries('profile');
    } catch (error) {
      console.error('Error updating memory:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      isLoading, 
      isAuthenticated, 
      login, 
      logout, 
      checkUser, 
      globalLoading, 
      setGlobalLoading,
      subscription,
      memoryArray,
      updateMemory
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);