import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../services/api';
import '../../Styles/AuthForms.css';
import CognoraLogo from '../../assets/Cognora Logo High Res (1).png';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await forgotPassword(email);
      setMessage(response.message);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred. Please try again.');
    }
  };

  return (
    <div className="auth-container">
          <div className="branding">
            <Link to="/" className="logo">
              <img src={CognoraLogo} alt="Cognora Logo" className="cognora-logo" />
            </Link>
          </div>
      <div className="auth-form">
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          {message && <p className="success-message">{message}</p>}
          <button type="submit" className="submit-button">Reset Password</button>
        </form>
        <p className="auth-link">
          Remember your password? <Link to="/login">Log in</Link>
        </p>
      </div>
      <footer className="auth-footer">
        <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
        <span className="separator">|</span>
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </footer>
    </div>
  );
};

export default ForgotPassword;
