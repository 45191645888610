import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import '../Styles/PaymentConfirmation.css';
import useDocumentTitle from '../UseDocumentTitle';

const WelcomePage = () => {
  useDocumentTitle('Welcome');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { checkUser, user } = useAuth();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    queryClient.clear();
    checkUser();

    const countdownTimer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate('/new');
    }, 5000);

    return () => {
      clearInterval(countdownTimer);
      clearTimeout(redirectTimer);
    };
  }, [navigate, queryClient, checkUser]);

  return (
    <div className="welcome-page">
      <div className="welcome-content">
        <div className="welcome-header">
          <h1>Welcome{user?.name ? `, ${user.name}` : ''}</h1>
          {user ? <h2>Welcome to {user.subscription}</h2> : null}
        </div>

        <p className="welcome-message">
          Redirecting in {countdown}...
        </p>
        <div className="progress-bar">
          <div className="progress" style={{width: `${(5 - countdown) * 20}%`}} />
        </div>
        <button className="start-button" onClick={() => navigate('/new')}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default WelcomePage;