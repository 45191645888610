import React from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import '../../Styles/Toast.css';

const Toast = ({ type, message }) => {
  const isSuccess = type === 'success';
  const Icon = isSuccess ? FaCheckCircle : FaExclamationCircle;
  
  return (
    <div className={`custom-toast ${isSuccess ? 'success' : 'error'}`}>
      <Icon className="toast-icon" />
      <span className="toast-message">{message}</span>
    </div>
  );
};

export default Toast;