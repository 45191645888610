import React from 'react';
import { Link } from 'react-router-dom';
import '../../Styles/Footer.css';
import CognoraLogo from '../../assets/Cognora Logo High Res (1).png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <Link to="/">
                    <img src={CognoraLogo} alt="Cognora Logo" className="footerlogo" />
          </Link>
          {/* <p>Your AI-powered study companion</p> */}
        </div>
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><Link to="/">Chat</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            {/* <li><Link to="/features">Features</Link></li> */}
            <li><Link to="https://cognora.ca/faq">Contact Us</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Legal</h4>
          <ul>
            <li><Link to="https://cognora.ca/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="https://cognora.ca/terms-of-service">Terms of Service</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} LEKAN INC. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;