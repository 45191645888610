/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { useParams, useNavigate, Link, useLocation, useSearchParams } from 'react-router-dom';
import { Send, Menu, Settings, Home, ChevronDown, Copy, Edit, StopCircle, Book, HelpCircle, Lightbulb,  MessageSquare, Check, GitCommit, RefreshCw, Download, Paperclip, X, Globe, Sliders, GitBranch, ChevronRight, Calendar, History, Search } from 'lucide-react'; // Added X icon, Globe icon, and Calendar icon, and History icon, and Search icon
import Sidebar from './Partials/Sidebar';
import '../Styles/Chat.css';
import '../Styles/katex.css';
import '../Styles/TextRenderer.css';
import ResourcesSection from './Partials/Resources';
import { fetchMessages, sendMessage, createNewChat, editMessage, regenerateMessage, downloadPDF, createBranch, switchBranch, sendGuestMessage } from '../services/api';
import { shuffle } from 'lodash';
import { categorizedSuggestions } from './Data/AllSuggestions';
import { MarkdownRenderer, AIResponseRenderer, CodeBlock } from './Partials/LLMTextRenderer';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Toast from './Partials/Toast';
import useDocumentTitle from '../UseDocumentTitle';
import { useAuth } from '../hooks/useAuth';
import { Helmet } from 'react-helmet';
import studyBuddyLogo from '../assets/Empty High-Res Logo.png';
import { FileText } from 'react-feather';
import { fetchChats } from '../services/api';
import { FaChevronRight } from 'react-icons/fa'
import { Code, File } from 'lucide-react';
import ExpandedResource from './Partials/ExpandedResource';
import SalesModal from './Partials/SalesModal';
import CognoraLogo from '../assets/Empty High-Res Logo.png';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useDropzone } from 'react-dropzone';
import { FaFilePdf, FaTimes } from 'react-icons/fa';
import { RotateCcw } from 'lucide-react';
import { FaChevronLeft} from 'react-icons/fa';
import { AlertCircle} from 'lucide-react'; // Add this import
import { BarChartOutlined } from '@ant-design/icons';
import { getRelativeTime } from './helpers/chatHelper';
import debounce from 'lodash/debounce';

// Add this helper function at the top of your component
const generateTempId = () => `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

// Add this helper function at the top of the file
const getGuestChatsFromStorage = () => {
  const stored = localStorage.getItem('guestRemainingChats');
  const lastReset = localStorage.getItem('guestChatsLastReset');
  const today = new Date().toDateString();

  // Check if it's a new day or no last reset exists
  if (!lastReset || lastReset !== today) {
    // Reset to default value and update last reset date
    localStorage.setItem('guestRemainingChats', '3');
    localStorage.setItem('guestChatsLastReset', today);
    return 3;
  }

  // Return stored value if it exists, otherwise default to 3
  return stored ? parseInt(stored, 10) : 3;
};

const Chats = ({ subscription, remainingChats: initialRemainingChats }) => {
  const { user } = useAuth();
  useDocumentTitle('StudyBuddy');
  const [remainingChats, setRemainingChats] = useState(initialRemainingChats);
  const queryClient = useQueryClient();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const inputRef = useRef('');
  const textareaRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // First, declare resources state
  const [resources, setResources] = useState({});

  // Then use it in other state initializations
  const [isResourcesVisible, setIsResourcesVisible] = useState(() => {
    return !isMobile && chatId !== 'new' && chatId !== undefined && user && Object.keys(resources).length > 0;
  });
  
  const [isResourcesExpanded, setIsResourcesExpanded] = useState(false);
  const [expandedResource, setExpandedResource] = useState(null);
  const [expandedVersion, setExpandedVersion] = useState(-1);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [chatIdToUse, setChatIdToUse] = useState(null);
  const [currentChatTitle, setCurrentChatTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInputProcessing, setIsInputProcessing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesContainerRef = useRef(null);
  const [isSendingFirstMessage, setIsSendingFirstMessage] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  const [redirectCountdown, setRedirectCountdown] = useState(5);

  const [searchParams, setSearchParams] = useSearchParams();


  
  // New state for editing and branching
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [activeBranchIndex, setActiveBranchIndex] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const abortControllerRef = useRef(null);
  const [partialResponse, setPartialResponse] = useState('');
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const lastMessageRef = useRef(null);
  const [isDefaultDisplay, setIsDefaultDisplay] = useState(false);

  const [currentMode, setCurrentMode] = useState(() => {
    return localStorage.getItem('defaultChatModel') || 'qa';
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  const [filteredMessages, setFilteredMessages] = useState([]);

  const [abortController, setAbortController] = useState(null);

  // Add these new state variables
  const [regeneratingMessageId, setRegeneratingMessageId] = useState(null);
  const [regeneratedContent, setRegeneratedContent] = useState('');

  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const [generatedContent, setGeneratedContent] = useState('');

  // Add these new state variables
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [showToast, setShowToast] = useState(false);

  // Change this line to allow only one PDF file
  const [pdfFile, setPdfFile] = useState(null);

  const [branches, setBranches] = useState([]);
  const [activeBranch, setActiveBranch] = useState(null);
  const fileInputRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dynamicHeader, setDynamicHeader] = useState('');

  const location = useLocation();


  // Add this new state
  const [lastMessageUsedWeb, setLastMessageUsedWeb] = useState(false);

  // Add this near the other state declarations
  const [remainingGuestChats, setRemainingGuestChats] = useState(getGuestChatsFromStorage);
  const [showAuthPrompt, setShowAuthPrompt] = useState(!user && remainingGuestChats <= 0);

  // Add this useEffect to handle guest chat count
  useEffect(() => {
    if (!user) {
      localStorage.setItem('guestRemainingChats', remainingGuestChats.toString());
      localStorage.setItem('guestChatsLastReset', new Date().toDateString());
    }
  }, [remainingGuestChats, user]);

  // Add this function to show custom toast
  const showCustomToast = (type, message) => {
    setToastType(type);
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000); // Hide after 3 seconds
  };
  // New state variables for smart scrolling
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  // Ref to track processed messages
  const processedMessageIdsRef = useRef(new Set());

  const [showSalesModal, setShowSalesModal] = useState(false);
  const [salesModalFeature, setSalesModalFeature] = useState('');
  const [pdfCount, setPdfCount] = useState(0);
  

  const closeSalesModal = () => {
    setShowSalesModal(false);
    setSalesModalFeature('');
  };

  // Add this new state
  const [isDragging, setIsDragging] = useState(false);

  const editInputRef = useRef(null);

  const [isLoadingResource, setIsLoadingResource] = useState(false);

  // Add this new state variable
  const [currentChatPdfCount, setCurrentChatPdfCount] = useState(0);

  // Add this new function to handle file drop
  const onDrop = useCallback((acceptedFiles) => {
    if (!user) {
      showCustomToast('error', 'Please sign in to use PDF features.');
      return;
    }

    console.log('onDrop called with acceptedFiles:', acceptedFiles);
    const file = acceptedFiles[0];
    if (file && file.type === 'application/pdf') {
      console.log('Accepted PDF file:', file);
      const newPdfFile = {
        id: `${file.name}-${file.size}-${Date.now()}`,
        name: file.name,
        file: file,
      };
      setPdfFile(newPdfFile);
      showCustomToast('success', 'PDF attached successfully.');
    } else {
      console.log('Rejected file:', file);
      showCustomToast('error', 'Only PDF files are allowed.');
    }
  }, [user]);

  // Set up the dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled: !user, // Disable for non-users
  });

  // Update useEffect to handle drag state
  useEffect(() => {
    setIsDragging(isDragActive && user);
  }, [isDragActive, user]);

  useEffect(() => {
    if(filteredMessages.length === 0) {
      setIsDefaultDisplay(true);
    } else {
      setIsDefaultDisplay(false);
    }
  }, [filteredMessages]);

  // Initialize suggestions
  useEffect(() => {
    const randomCategories = shuffle(categorizedSuggestions).slice(0, 3);
    const randomSuggestions = randomCategories.map(category => ({
      icon: category.icon,
      text: shuffle(category.suggestions)[0]
    }));
    setSuggestions(randomSuggestions);
  }, []);

  // Update remaining chats when initialRemainingChats changes
  useEffect(() => {
    setRemainingChats(initialRemainingChats);
  }, [initialRemainingChats]);

  // Add this new effect to log remaining chats
  useEffect(() => {
    console.log('Current remaining chats:', remainingChats);
  }, [remainingChats]);

  // Update the scrollToBottom function
  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current && !isGeneratingResponse) {
      try {
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 100);
      } catch (error) {
        console.error('Error scrolling to bottom:', error);
      }
    }
    setShowScrollButton(false);
  }, [isGeneratingResponse]);

  // Add this new state for tracking if chat exists
  const [chatExists, setChatExists] = useState(true);

  // Fetch chats using react-query (move this up before messages query)
  const { data: chatsData, isSuccess: isChatsLoaded } = useQuery('chats', fetchChats, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    // Add enabled condition to prevent fetching when no user
    enabled: !!user,
  });

  // Update the messages query to check chats cache first
  const { data: messagesData, isLoading: isMessagesLoading, error: messagesError } = useQuery(
    ['messages', chatId],
    () => fetchMessages(chatId),
    {
      enabled: !!chatId && chatId !== 'new' && chatExists && !!user, // Add !!user condition
      staleTime: 5 * 60 * 1000, 
      cacheTime: 30 * 60 * 1000,
      retry: false,
      onSuccess: (data) => {
        setMessages(data);
        try {
          localStorage.setItem(`chat_${chatId}`, JSON.stringify(data));
        } catch (err) {
          console.error('Error saving messages to localStorage:', err);
        }
      },
      onError: (error) => {
        if (error.isAuthError) {
          localStorage.clear();
          queryClient.clear();
          navigate('/login');
        }
      }
    }
  );


  const handleResourceClick = (resourceTitle, version) => {
    if (!resources || !resources[chatId] || !Array.isArray(resources[chatId])) {
      return;
    }

    const foundResources = resources[chatId].filter(resource => resource.title === resourceTitle);
    
    if (foundResources.length === 0) {
      return;
    }

    let selectedResource;

    if (version) {
      // If a specific version is requested, find that version
      selectedResource = foundResources.find(resource => resource.version === version);
      if (!selectedResource) {
        // Fallback to the latest version if the specified version is not found
        selectedResource = foundResources.reduce((latest, current) => 
          ((current.version || 1) > (latest.version || 1)) ? current : latest
        );
      }
    } else {
      // If no version is specified, use the latest version
      selectedResource = foundResources.reduce((latest, current) => 
        ((current.version || 1) > (latest.version || 1)) ? current : latest
      );
    }

    setIsResourcesVisible(true);
    setIsResourcesExpanded(true);
    if (isResourcesVisible && !isResourcesExpanded) {
      setIsResourcesExpanded(true);
    } 

    setExpandedVersion(selectedResource.version || 1);
    setExpandedResource({
      ...selectedResource,
      version: selectedResource.version || 1,
      desiredVersion: foundResources.length
    });

    // Add query params with version check
    setSearchParams({
      ...Object.fromEntries(searchParams),
      resource: resourceTitle,
      version: (selectedResource.version || 1).toString()
    });
  };

  // Add effect to handle URL params on load
  useEffect(() => {
    const resourceTitle = searchParams.get('resource');
    const version = searchParams.get('version');

    if (resourceTitle && resources?.[chatId]) {
      handleResourceClick(resourceTitle, version ? parseInt(version) : undefined);
    }
  }, [chatId, resources, searchParams]);


  const renderResource = useCallback((resource) => (
    <div className="resource-item" onClick={() => handleResourceClick(resource.title, resource.version)}>
      <span className="resource-icon">
        {getResourceIcon(resource.type)}
      </span>
      <div className="resource-info">
        <h3 className="resource-title">{resource.title}</h3>
        <span className="resource-type">{resource.type || 'General'}</span>
      </div>
      <FaChevronRight className="resource-arrow" />
    </div>
  ), [handleResourceClick]);

  // Add this effect to check chats cache
  useEffect(() => {
    if (isChatsLoaded && chatId && chatId !== 'new') {
      const chatExists = chatsData?.chats?.some(chat => chat.id === chatId);
      setChatExists(chatExists);
    }
  }, [isChatsLoaded, chatsData, chatId]);

  // Mutation for editing messages
  const editMessageMutation = useMutation(
    ({ messageId, content, branchId }) => editMessage(chatId, messageId, content, branchId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['messages', chatId]);
      },
    }
  );

  // Add this new mutation
  const regenerateMessageMutation = useMutation(
    ({ messageId }) => regenerateMessage(chatId, messageId, () => {}, currentMode),
    {
      onSuccess: async () => {
        // This will be empty now, as we're handling the streaming in the component
      },
      onError: (error) => {
        // Removed console.error
        showCustomToast('error', 'Failed to regenerate message. Please try again.');
        setRegeneratingMessageId(null);
      },
    }
  );

  // Start editing a message
  const handleEditStart = useCallback((message) => {
    setEditingMessageId(message.id);
    setEditContent(message.content);
    setTimeout(() => {
      if (editInputRef.current) {
        editInputRef.current.style.height = 'auto';
        editInputRef.current.style.height = `${editInputRef.current.scrollHeight}px`;
      }
    }, 0);
  }, []);
  
  useEffect(() => {
    if (editingMessageId && editInputRef.current) {
      editInputRef.current.style.height = 'auto';
      editInputRef.current.style.height = `${editInputRef.current.scrollHeight}px`;
      editInputRef.current.focus();
    }
  }, [editingMessageId]);

  // Cancel editing a message
  const handleEditCancel = useCallback(() => {
    setEditingMessageId(null);
    setEditContent('');
  }, []);

  // Submit edited message
  const handleEditSubmit = useCallback((messageId, content) => {
    editMessageMutation.mutate({ messageId, content, branchId: activeBranch });
    setEditingMessageId(null);
    setEditContent('');
  }, [editMessageMutation, activeBranch]);
      // Update the handleCreateResource function
      const handleCreateResource = useCallback((newResource) => {
        setResources(prevResources => ({
          ...prevResources,
          [chatId]: [...(prevResources[chatId] || []), newResource]
        }));
      }, [chatId]);

  const processArtifacts = useCallback((artifactContent) => {
    let processedContent = artifactContent;
    const artifactsMatch = artifactContent.match(/<artifacts>([\s\S]*?)<\/artifacts>/g);
    
    if (artifactsMatch) {
      artifactsMatch.forEach((artifact, index) => {
        // Parse essay artifacts
        const essayMatch = artifact.match(/<essay>([\s\S]*?)<\/essay>/);
        if (essayMatch) {
          const essayContent = essayMatch[1];
          const titleMatch = essayContent.match(/<title>(.*?)<\/title>/);
          const contentMatch = essayContent.match(/<content>([\s\S]*?)<\/content>/);
          
          if (titleMatch && contentMatch) {
            const title = titleMatch[1];
            const existingResources = resources[chatId] || [];
            const sameTypeResources = existingResources.filter(r => r.type === 'essay' && r.title === title);
            const version = sameTypeResources.length + 1;
            
            const resourceId = `essay-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'essay',
              title: titleMatch[1],
              content: contentMatch[1],
              version: version,
              timestamp: new Date().toISOString()
            };
            handleCreateResource(newResource);
          }
        }

        // Parse code artifacts
        const codeMatch = artifact.match(/<code.*?>([\s\S]*?)<\/code>/);
        if (codeMatch) {
          const codeContent = codeMatch[1];
          const languageMatch = codeMatch[0].match(/language="(.*?)"/);
          const titleMatch = codeMatch[0].match(/title="(.*?)"/);
          
          if (languageMatch && titleMatch) {
            const title = titleMatch[1];
            const existingResources = resources[chatId] || [];
            const sameTypeResources = existingResources.filter(r => r.type === 'code' && r.title === title);
            const version = sameTypeResources.length + 1;
            
            const resourceId = `code-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'code',
              language: languageMatch[1],
              title: title,
              content: codeContent,
              version: version,
              timestamp: new Date().toISOString()
            };
            handleCreateResource(newResource);
          }
        }

        // Parse visualization artifacts
        const vizMatch = artifact.match(/<visualization.*?>([\s\S]*?)<\/visualization>/);
        if (vizMatch) {
          const vizContent = vizMatch[1];
          const titleMatch = vizMatch[0].match(/title="(.*?)"/);
          
          if (titleMatch) {
            const title = titleMatch[1];
            const existingResources = resources[chatId] || [];
            const sameTypeResources = existingResources.filter(r => r.type === 'visualization' && r.title === title);
            const version = sameTypeResources.length + 1;
            
            try {
              JSON.parse(vizContent);
              
              const resourceId = `viz-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
              let newResource = {
                id: resourceId,
                type: 'visualization',
                title: title,
                content: vizContent,
                version: version,
                timestamp: new Date().toISOString()
              };
              
              handleCreateResource(newResource);
            } catch (error) {
              // Silent error handling
            }
          }
        }
      });
    }
    return processedContent;
  }, [chatId, handleCreateResource]); // Remove resources from dependency array since it's used for reading only

  const handleRegenerateMessage = useCallback((messageId) => {
    setRegeneratingMessageId(messageId);
    setRegeneratedContent('');
    
    // Clear resources associated with this message
    setResources(prevResources => {
      const updatedResources = { ...prevResources };
      if (updatedResources[chatId]) {
        // Filter out resources that were created in this message
        updatedResources[chatId] = updatedResources[chatId].filter(resource => 
          !resource.messageId || resource.messageId !== messageId
        );
      }
      return updatedResources;
    });

    // Clear expanded resource if it was from this message
    if (expandedResource?.messageId === messageId) {
      setExpandedResource(null);
      setIsResourcesExpanded(false);
      
      // Clear resource-related URL params
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('resource');
      newParams.delete('version');
      setSearchParams(newParams);
    }

    setMessages(prevMessages => 
      prevMessages.map(msg => 
        msg.id === messageId 
          ? { ...msg, content: '' }
          : msg
      )
    );

    regenerateMessageMutation.mutate(
      { messageId },
      {
        onSuccess: async () => {
          try {
            let accumulatedContent = '';
            let chunkToDisplay = '';
            await regenerateMessage(chatId, messageId, (chunk) => {
              let processedChunk = chunk;

              accumulatedContent += processedChunk;
              chunkToDisplay += processedChunk;

              // Update the message content in real-time
              setMessages(prevMessages => {
                return prevMessages.map(msg => 
                  msg.id === messageId 
                    ? { ...msg, content: accumulatedContent }
                    : msg
                );
              });

              setGeneratedContent(chunkToDisplay);
              scrollToBottom();
            }, currentMode);

            // Process any new artifacts after complete regeneration
            processArtifacts(accumulatedContent);
            
            setRegeneratingMessageId(null);
            
            // Refresh messages to ensure consistency
            queryClient.invalidateQueries(['messages', chatId]);
          } catch (error) {
            showCustomToast('error', 'Failed to process regenerated message. Please try again.');
            setRegeneratingMessageId(null);
          }
        },
        onError: (error) => {
          showCustomToast('error', 'Failed to regenerate message. Please try again.');
          setRegeneratingMessageId(null);
        },
      }
    );
  }, [regenerateMessageMutation, currentMode, processArtifacts, showCustomToast, chatId, expandedResource, searchParams]);

  // Toggle message branch
  const toggleBranch = useCallback((messageId) => {
    setActiveBranchIndex(prev => ({
      ...prev,
      [messageId]: ((prev[messageId] || 0) + 1) % (messages.find(m => m.id === messageId)?.edit_count + 1 || 1)
    }));
  }, [messages]);

  // Update the groupedMessages useMemo
  const groupedMessages = useMemo(() => {
    // Check if messages exists and is an array
    if (!Array.isArray(messages)) {
      return [];
    }

    const groups = {};
    messages.forEach(message => {
      if (!message) return; // Skip if message is undefined
      
      if (message.parent_message_id) {
        if (!groups[message.parent_message_id]) {
          groups[message.parent_message_id] = [];
        }
        groups[message.parent_message_id].push(message);
      } else {
        if (!groups[message.id]) {
          groups[message.id] = [message];
        }
      }
    });
    return Object.values(groups);
  }, [messages]);

  // Update the search state to include chat information
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // Add debounced search function using useCallback and useRef
  const searchTimeoutRef = useRef(null);
  const debouncedSearch = useCallback((query) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      const lowercasedQuery = query.toLowerCase();
      
      // Filter current chat messages
      const currentChatResults = messages.filter(message =>
        message.content.toLowerCase().includes(lowercasedQuery)
      ).map(message => ({
        ...message,
        chatId: chatId,
        chatTitle: currentChatTitle || 'Current Chat'
      }));

      // Set filtered messages for current chat display
      setFilteredMessages(currentChatResults);

      // Only search other chats if query is longer than 2 characters
      if (query.length > 2 && chatsData?.chats) {
        // Search logic for other chats...
        // (Keep your existing other chats search logic here)
      } else {
        setSearchResults([]);
      }
    }, 300); // 300ms delay
  }, [messages, chatId, currentChatTitle, chatsData]);

  // Create a debounced search handler outside the component
  const MobileSearchOverlay = ({ isOpen, onClose, searchQuery, onSearch, searchResults, onResultClick }) => {
    // Local state for input value
    const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);

    // Memoize the debounced search handler
    const debouncedSearch = useMemo(
      () => debounce((value) => {
        onSearch({ target: { value } });
      }, 300),
      [onSearch]
    );

    // Handle input change
    const handleInputChange = useCallback((e) => {
      const value = e.target.value;
      setLocalSearchQuery(value);
      debouncedSearch(value);
    }, [debouncedSearch]);

    // Memoize the search results rendering
    const renderSearchResults = useMemo(() => {
      if (searchResults.length > 0) {
        return searchResults.map((result, index) => (
          <div
            key={result.id}
            className="search-result-item"
            onClick={() => onResultClick(result.id)}
            style={{ animationDelay: `${index * 0.05}s` }}
          >
            <h3>{result.title || 'Untitled Chat'}</h3>
            <p>{result.preview}</p>
          </div>
        ));
      }
      
      if (localSearchQuery) {
        return <div className="no-results">No conversations found</div>;
      }
      
      return <div className="search-prompt">Start typing to search your conversations</div>;
    }, [searchResults, localSearchQuery, onResultClick]);

    // Cleanup debounce on unmount
    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    // Early return after all hooks
    if (!isOpen) return null;

    return (
      <div 
        className="mobile-search-overlay" 
        onClick={(e) => e.target === e.currentTarget && onClose()}
      >
        <div className="mobile-search-header">
          <input
            type="text"
            placeholder="Search conversations..."
            value={localSearchQuery}
            onChange={handleInputChange}
            autoFocus
            className="mobile-search-input"
          />
          <button 
            onClick={onClose} 
            className="mobile-search-close"
            aria-label="Close search"
          >
            <X size={24} />
          </button>
        </div>
        <div className="mobile-search-results">
          {renderSearchResults}
        </div>
      </div>
    );
  };

  // Separate SearchResultItem component
  const SearchResultItem = memo(({ result, onClick, index }) => (
    <div
      className="search-result-item"
      onClick={onClick}
      style={{ animationDelay: `${index * 0.05}s` }}
    >
      <h3>{result.chatTitle || 'Untitled Chat'}</h3>
      <p>{result.content.substring(0, 150)}...</p>
    </div>
  ));

  // Update the handleSearch function
  const handleSearch = useCallback((e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    if (!value.trim()) {
      setSearchResults([]);
      return;
    }

    // Filter messages from current chat
    const filteredMessages = messages.filter(message =>
      message.content.toLowerCase().includes(value.toLowerCase())
    );

    // Filter through chat titles/content
    const filteredChats = chatsData?.chats?.filter(chat => {
      const matchesTitle = chat.title?.toLowerCase().includes(value.toLowerCase());
      // Find the last message in this chat
      const lastMessage = chat.messages?.[chat.messages.length - 1]?.content || '';
      const matchesContent = lastMessage.toLowerCase().includes(value.toLowerCase());
      return matchesTitle || matchesContent;
    }) || [];

    // Combine and format results
    const formattedResults = filteredChats.map(chat => ({
      id: chat.id,
      title: chat.title || 'Untitled Chat',
      // Get preview from the last message or fallback to a default
      preview: chat.messages?.[chat.messages.length - 1]?.content?.substring(0, 100) || 
              chat.preview || 
              'Start a new conversation',
      type: 'chat'
    }));

    setSearchResults(formattedResults);
  }, [messages, chatsData]);

  // Add this component for the search results from other chats
  const OtherChatsSearchResults = ({ results, onResultClick }) => {
    if (!results.length) return null;

    return (
      <div className="other-chats-results">
        <div className="other-chats-header">Search Results from Other Chats</div>
        {results.map((result) => (
          <div
            key={result.id}
            className="other-chat-result"
            onClick={() => onResultClick(result.id)}
          >
            <div className="result-chat-title">
              <MessageSquare size={16} />
              {result.title}
            </div>
            <div className="result-preview">
              {result.preview?.length > 100 
                ? `${result.preview.substring(0, 100)}...` 
                : result.preview}
            </div>
            <ChevronRight className="preview-arrow" size={16} />
          </div>
        ))}
      </div>
    );
  };
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const incomingMessage = params.get('message');
    const incomingMode = params.get('mode') || 'tutor';
    
    if (incomingMessage) {
      // Set the mode first
      selectMode(incomingMode);
      
      // Set the message and submit it
      setInput(decodeURIComponent(incomingMessage));
      inputRef.current = decodeURIComponent(incomingMessage);
      
      // Use setTimeout to ensure state updates have propagated
      setTimeout(() => {
        handleSubmit({ preventDefault: () => {} });
      }, 100);
      
      // Clean up the URL
      const newUrl = window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [location]); // Add any other dependencies as needed


  // Add handler for search result clicks
  const handleSearchResultClick = useCallback((chatId) => {
    setSearchQuery('');
    setSearchResults([]);
    navigate(`/${chatId}`);
  }, [navigate]);

  // Add a new chat
  const handleAddChat = async () => {
    try {
      // Clear messages and input before navigating
      setMessages([]);
      setInput('');
      setFilteredMessages([]);
      setPdfFile(null);
      setIsDefaultDisplay(true);
      setChatIdToUse(null); // Reset chatIdToUse
      navigate('/', { replace: true }); // Use replace to prevent back navigation to the same chat
    } catch (error) {
      showCustomToast('error', 'Failed to create a new chat. Please try again.');
    }
  };


  // Load messages from cache or fetch new ones
  useEffect(() => {
    if (chatId && chatId !== 'new') {
      const cachedMessages = localStorage.getItem(`chat_${chatId}`);
      if (cachedMessages && cachedMessages !== 'undefined') { // Added check
        try {
          const parsedMessages = JSON.parse(cachedMessages);
          setMessages(parsedMessages);
        } catch (error) {
          // Removed console.error
          // Optionally, remove the corrupted cache
          localStorage.removeItem(`chat_${chatId}`);
          // Fallback to messagesData or an empty array
          setMessages(messagesData || []);
        }
      } else if (messagesData) {
        setMessages(messagesData);
      }
    } else if (chatId === 'new') {
      setMessages([]);
    }
  }, [chatId, messagesData]);

  // Auto-scroll to the latest message
  useEffect(() => {
    if (autoScroll && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages, partialResponse, autoScroll]);

  // Update the useEffect for window resize
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      
      // Adjust resources visibility only when transitioning to mobile
      if (newWidth <= 668 ) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  // Add a separate useEffect for setting the initial dynamic header
  useEffect(() => {
    setDynamicHeader(getRandomHeader(user?.name));
  }, [user?.name]); // Only regenerate when user name changes

  // Update the handleUpdateTitle function
  const handleUpdateTitle = useCallback((newTitle) => {
    // Removed console.log
    setCurrentChatTitle(newTitle);
  }, []);

  // Update chatIdToUse based on chatId
  useEffect(() => {
    if (chatId === 'new') {
      setChatIdToUse(null);
      setMessages([]);
      setFilteredMessages([]);
      setCurrentChatTitle('');
    } else if (chatId) {
      setChatIdToUse(chatId);
    }
  }, [chatId]);

const handleImprove = async (message) => {
  setInput(message);
  inputRef.current = message;
  // Wait for state to update before submitting
  await new Promise(resolve => setTimeout(resolve, 0));
  handleSubmit({ preventDefault: () => {} });
};

const handleExplain = async (message) => {
  setInput(message);
  inputRef.current = message;
  // Wait for state to update before submitting
  await new Promise(resolve => setTimeout(resolve, 0));
  handleSubmit({ preventDefault: () => {} });
};

  // Update the processArtifacts function
  

  const handleTextareaInput = (event) => {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };


  useEffect(() => {
    if (chatsData && chatsData.chats && Array.isArray(chatsData.chats)) {
      // Create a temporary object to store all resources
      const newResources = {};

      // Process each chat's resources
      chatsData.chats.forEach(chat => {
        if (!chat || !chat.pdf_content) return;

        // Initialize array for this chat's resources if needed
        if (!newResources[chat.id]) {
          newResources[chat.id] = [];
        }

        // Split PDF content by sections
        const pdfSections = chat.pdf_content.split('<PDF START>');
        
        // Skip first empty section
        pdfSections.slice(1).forEach((section, index) => {
          const titleMatch = section.match(/\[PDF TITLE: (.*?)\]/);
          const title = titleMatch ? titleMatch[1].trim() : `Untitled PDF ${index + 1}`;
          
          const linkMatch = section.match(/\[LINK: (.*?)\]/);
          const link = linkMatch ? linkMatch[1] : null;

          // Find content between header and PDF END
          const contentStart = section.indexOf('\n', section.indexOf('\n') + 1);
          const contentEnd = section.indexOf('<PDF END>');
          
          if (contentStart !== -1 && contentEnd !== -1) {
            let content = section.slice(contentStart, contentEnd).trim();
            // Clean up the content
            content = content.replace(/\[LINK:.*?\]/g, '').trim();

            // Create resource object
            const resourceId = `pdf-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            newResources[chat.id].push({
              id: resourceId,
              title,
              content,
              type: 'pdf',
              link,
              timestamp: new Date().toISOString()
            });
          }
        });

        // Update PDF count for current chat
        if (chat.id === chatId) {
          const pdfCount = newResources[chat.id].filter(resource => resource.type === 'pdf').length;
          setCurrentChatPdfCount(pdfCount);
        }
      });

      // Update resources state, preserving existing non-PDF resources
      setResources(prevResources => {
        const mergedResources = { ...prevResources };
        
        // Merge new PDF resources with existing resources
        Object.keys(newResources).forEach(chatId => {
          mergedResources[chatId] = [
            ...(prevResources[chatId] || []).filter(r => r.type !== 'pdf'), // Keep non-PDF resources
            ...newResources[chatId] // Add new PDF resources
          ];
        });
        
        return mergedResources;
      });

      const hasPDFsOrResources = Object.keys(newResources).some(id => newResources[id].length > 0);
      if (hasPDFsOrResources && !isMobile && user) {
        setIsResourcesVisible(true);
      }
    }

    // Process artifacts from messages
    if (messages && Array.isArray(messages)) {
      messages.forEach(message => {
        if (message?.is_ai && !processedMessageIdsRef.current.has(message.id)) {
          try {
            processArtifacts(message.content);
            processedMessageIdsRef.current.add(message.id);
          } catch (error) {
            console.error('Error processing message artifacts:', error);
          }
        }
      });
    }

    
  }, [chatsData, messages, processArtifacts, chatId]);

  // Updated handleSubmit function
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    
    // Check remaining chats for both authenticated and guest users
    const currentRemaining = user ? remainingChats : remainingGuestChats;

    if(currentRemaining <= 0 && !user ) {
      const chatState = {
        inputValue: inputRef.current,
        attachments: pdfFile ? [pdfFile] : []
      };
      
      // Store in localStorage as a backup
      localStorage.setItem('pendingChatState', JSON.stringify(chatState));
      setShowAuthPrompt(true);
      return;
    }
    
    if (currentRemaining <= 0 && user && currentRemaining != null) {
      showCustomToast('error', 'No remaining chats. Please sign up to continue.');
      setShowSalesModal(true);
      setSalesModalFeature('chats');
      return;
    }

    if (isSubmitting) {
      // Removed console.log
      return;
    }

    setIsSubmitting(true);
    setIsCancelled(false);
    setIsGeneratingResponse(true);
    setGeneratedContent('');

    if (
      (!inputRef.current.trim() && (!pdfFile || pdfFile.length === 0)) ||
      (currentRemaining <= 0 && currentRemaining != null) ||
      isInputProcessing
    ) {
      // Removed console.log
      setIsSubmitting(false);
      setIsGeneratingResponse(false);
      return;
    }

    const currentInput = inputRef.current;
    const currentTimestamp = new Date().toISOString();
    const tempUserMessageId = generateTempId();
    const tempAiMessageId = generateTempId();

    // Create user message object
    const userMessage = {
      id: tempUserMessageId,
      content: currentInput,
      is_ai: false,
      role: 'user', // Add explicit role
      timestamp: currentTimestamp,
      used_pdf: pdfFile ? true : false,
      pdf_title: pdfFile ? pdfFile.name : null,
    };

    let tempid;
    const currentPdfFile = pdfFile;
    try {
      console.log('Starting message submission process');
      setIsLoading(true);
      setIsInputProcessing(true);
      setIsSendingFirstMessage(true);

      let currentChatId = chatIdToUse;
      console.log('Current chat ID:', currentChatId);
      
      if(user) {
      if (!currentChatId || currentChatId === 'new' || currentChatId === 'undefined') {
        console.log('Creating new chat...');
        const newChat = await createNewChat();
        currentChatId = newChat.id;
        tempid = newChat.id;
        setChatIdToUse(currentChatId);
        console.log('New chat created with ID:', currentChatId);
        
        // Update the URL without causing a page reload
        window.history.pushState({}, '', `/${currentChatId}`);

        // Add the new chat to the sidebar immediately with a temporary title
        queryClient.setQueryData('chats', (oldData) => {
          if (!oldData) return { chats: [] };
          return {
            ...oldData,
            chats: [{
              id: currentChatId,
              title: 'New Chat',
              last_modified: new Date().toISOString(),
            }, ...oldData.chats]
          };
        });
      }
    }

      console.log('Adding user message to state');
      setMessages(prevMessages => [...prevMessages, userMessage]);


      console.log('Resetting input states');
      setInput('');
      scrollToBottom();
      setIsGeneratingResponse(true);
      inputRef.current = '';
      setPdfFile(null);

      console.log('Setting up abort controller');
      const controller = new AbortController();
      setAbortController(controller);

      // Format context for guest users
      const formattedContext = messages.map(msg => ({
        role: msg.is_ai ? 'assistant' : 'user',
        content: msg.content
      }));
      
      console.log('chatId', currentChatId);
      let usedWeb = false;
      let usedPDF = false;
      let pdfTitle = null;
      let userMessageId = null;
      let aiMessageId = null;
      let newTitle = null;


      // Use different API call based on auth status
      const { stream, response } = user 
        ? await sendMessage(currentChatId, currentInput, currentMode, controller.signal, [pdfFile].filter(Boolean))
        : await sendGuestMessage(formattedContext, currentInput, currentMode);

        if(user) {
          usedWeb = response.headers.get('X-Used-Web') === 'true';
          usedPDF = response.headers.get('X-Used-PDF') === 'true';
          pdfTitle = response.headers.get('X-PDF-Title');
          userMessageId = response.headers.get('X-User-Message-Id');
          aiMessageId = response.headers.get('X-AI-Message-Id');
          newTitle = response.headers.get('X-New-Title');

          // Update the user message with the correct ID from the server
          setMessages(prevMessages => prevMessages.map(msg => 
            msg.id === tempUserMessageId 
              ? { ...msg, id: userMessageId, used_pdf: usedPDF, pdf_title: pdfTitle }
              : msg
          ));
        }


      // Create initial AI message
      const aiMessage = {
        id: tempAiMessageId,
        content: '',
        is_ai: true,
        role: 'assistant',
        timestamp: new Date().toISOString(),
        used_web: user ? usedWeb : false,
        pdf_title: user ? pdfTitle : null,
      };


      
      // Remove the separate isGeneratingResponse state update
      // setIsGeneratingResponse(true); // Remove this line

      let accumulatedResponse = '';

      // Process the stream
      for await (const chunk of stream) {
        if (chunk.content) {
          const processedChunk = chunk.content;
          accumulatedResponse += processedChunk;

          
          // Update generated content for rendering
          setGeneratedContent(accumulatedResponse);
        }

        if (accumulatedResponse.endsWith("[Generation stopped]")) {
          break;
        }
      }
      // Add AI message to state
      setMessages(prevMessages => [...prevMessages, {...aiMessage, content: accumulatedResponse}]);

      console.log('Stream processing complete');
      console.log('Final response length:', accumulatedResponse.length);
      
      // Add this line to ensure loading state is cleared
      setIsGeneratingResponse(false);

      // Final message update
      if (!user) {
        console.log('Updating messages for guest user');
        setMessages(messages => messages.map(msg => {
          if (msg.id === tempUserMessageId) {
            return {
              ...msg,
              content: currentInput,
              is_ai: false,
              role: 'user'
            };
          }
          if (msg.id === tempAiMessageId) {
            return {
              ...msg,
              content: accumulatedResponse,
              is_ai: true,
              role: 'assistant'
            };
          }
          return msg;
        }));
      } else {
        console.log('Updating messages for authenticated user');
        
        // During generation, only show the temporary message
        if (!userMessageId || !aiMessageId) {
          setMessages(prevMessages => {
            // Remove any existing temporary messages
            const filteredMessages = prevMessages.filter(msg => 
              msg.id !== tempUserMessageId && 
              msg.id !== tempAiMessageId
            );
            
            return [...filteredMessages, userMessage, aiMessage];
          });
          
          return; // Exit early if we're still generating
        }

        // Once we have server IDs, create the final messages
        const finalUserMessage = {
          ...userMessage,
          id: userMessageId,
          used_pdf: usedPDF,
          pdf_title: pdfTitle
        };

        const finalAIMessage = {
          ...aiMessage,
          id: aiMessageId,
          content: accumulatedResponse
        };

        // Replace temporary messages with final ones
        setMessages(prevMessages => {
          const filteredMessages = prevMessages.filter(msg => 
            msg.id !== tempUserMessageId && 
            msg.id !== tempAiMessageId
          );

          return [...filteredMessages, finalUserMessage, finalAIMessage];
        });

        // Update query cache
        queryClient.setQueryData(['messages', currentChatId], (oldData) => {
          if (!oldData) return [finalUserMessage, finalAIMessage];
          
          const filteredOldData = oldData.filter(msg => 
            msg.id !== tempUserMessageId && 
            msg.id !== tempAiMessageId
          );
          
          return [...filteredOldData, finalUserMessage, finalAIMessage];
        });

        // Only navigate if it's a new chat
        if (!chatIdToUse || chatIdToUse === 'new') {
          navigate(`/${currentChatId}`);
        }

        // Update the chat title if a new title was received
        if (newTitle) {
          // Update the current chat title in the component state
          setCurrentChatTitle(newTitle);
          handleUpdateTitle(newTitle);

          // Update the chat list in the sidebar with the new title and ensure it's at the top
          queryClient.setQueryData('chats', (oldData) => {
            if (!oldData || !oldData.chats) return { chats: [] };
            
            const updatedChats = oldData.chats.filter(chat => chat.id !== currentChatId);
            return {
              ...oldData,
              chats: [{
                id: currentChatId,
                title: newTitle,
                last_modified: new Date().toISOString(),
              }, ...updatedChats]
            };
          });
        }
      }

      // Update remaining chats for guest users
      if (!user) {
        setRemainingGuestChats(prev => prev - 1);
      }

    } catch (error) {
      // Add this line to ensure loading state is cleared on error
      setIsGeneratingResponse(false);
      console.error('Error in handleSubmit:', error);
      setMessages(prevMessages => 
        prevMessages.filter(msg => 
          msg.id !== tempUserMessageId && msg.id !== tempAiMessageId
        )
      );
      handleSubmissionError(error, currentInput, tempUserMessageId, currentPdfFile);
    } finally {
      // Ensure loading state is cleared in finally block as well
      setIsGeneratingResponse(false);
      cleanupSubmission();

      const pdfMatch = currentInput.match(/<pdf>([\s\S]*?)<\/pdf>/g);
      if (pdfMatch) {
        pdfMatch.forEach(pdfContent => {
          const titleMatch = pdfContent.match(/<title>(.*?)<\/title>/);
          const contentMatch = pdfContent.match(/<content>([\s\S]*?)<\/content>/);
          
          if (titleMatch && contentMatch) {
            const resourceId = `pdf-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            const newResource = {
              id: resourceId,
              type: 'pdf',
              title: titleMatch[1],
              content: contentMatch[1],
              timestamp: new Date().toISOString(),
              messageId: tempAiMessageId // Link resource to the message
            };
            
            handleCreateResource(newResource);
          }
        });
      }
      
      // Focus the textarea after a short delay to ensure the UI has updated
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }, 100);
    }
  };

  // Updated handleSubmissionError function
  const handleSubmissionError = (error, currentInput, userMessageId, currentPdfFile) => {
    removeTemporaryMessages(userMessageId);
    restoreInput(currentInput);
    setPdfFile(currentPdfFile); // Restore PDF file
    if (error.name === 'AbortError') {
      console.log('Message generation aborted by user.');
    } else {
      console.error('Error sending message:', error);
      showCustomToast('error', 'An error occurred while sending your message. Please try again.');
    }

  };

  // Handle stopping the generation of AI messages
  const handleStopGenerating = () => {
    if (abortController) {
      abortController.abort();
      setIsGenerating(false);
      setIsCancelled(true);
      setIsSubmitting(false);
      
      // Update the last AI message
      updateLastAIMessage("[Generation stopped by user]");
    } else {
      console.warn('No active AbortController to abort.');
    }
  };

  // Add this helper function
  const updateLastAIMessage = (appendText) => {
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
      for (let i = updatedMessages.length - 1; i >= 0; i--) {
        if (updatedMessages[i].is_ai) {
          updatedMessages[i].content += appendText;
          break;
        }
      }
      return updatedMessages;
    });
  };

  // Restore input in the textarea
  const restoreInput = (content) => {
    setInput(content);
    inputRef.current = content;
    if (textareaRef.current) {
      textareaRef.current.value = content;
    }
  };

  // Remove temporary messages in case of error
  const removeTemporaryMessages = (userMessageId, aiMessageId) => {
    setMessages(prevMessages => 
      prevMessages.filter(msg => msg.id !== userMessageId && msg.id !== aiMessageId)
    );
  };

  // Find the last user message for restoration
  const findLastUserMessage = () => {
    for (let i = groupedMessages.length - 1; i >= 0; i--) {
      const lastGroup = groupedMessages[i];
      const lastUserMessage = lastGroup.find(msg => !msg.is_ai);
      if (lastUserMessage) {
        console.log('Found last user message:', lastUserMessage.content);
        return lastUserMessage;
      }
    }
    return null;
  };

  // Cleanup after submission
  const cleanupSubmission = () => {
    setIsLoading(false);
    setIsInputProcessing(false);
    setIsSendingFirstMessage(false);
    setIsGenerating(false);
    setIsSubmitting(false);
    setIsGeneratingResponse(false); // Add this line
    abortControllerRef.current = null;
  };

  // Abort controller cleanup on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        console.log('Component unmounted. Aborting ongoing request.');
      }
    };
  }, []);

  // Handle pending navigation
  useEffect(() => {
    if (pendingNavigation && !abortControllerRef.current?.signal.aborted) {
      navigate(`/${pendingNavigation}`, { replace: true });
      setPendingNavigation(null);
    }
  }, [pendingNavigation, navigate]);

  // Update the toggleSidebar function
  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => {
      const newState = !prev;
      localStorage.setItem('sidebarOpen', newState);
      return newState;
    });
  }, []);

  const switchBranch = useCallback((messageId) => {
    const message = messages.find(m => m.id === messageId);
    if (message && message.branches) {
      const currentIndex = activeBranch ? activeBranch.index : -1;
      const nextIndex = (currentIndex + 1) % (message.branches.length + 1);
      
      if (nextIndex === 0) {
        // Switch to original message
        setActiveBranch(null);
      } else {
        // Switch to a branch
        setActiveBranch({
          messageId,
          index: nextIndex - 1,
          content: message.branches[nextIndex - 1].content
        });
      }
      
      // Here you would typically update the displayed message content
      // This might involve updating the state of displayed messages or
      // triggering a re-render of the specific message
    }
  }, [messages, activeBranch]);


  // Copy message content to clipboard
  const copyToClipboard = useCallback((content, messageId) => {
    // Remove everything after "<|end of input|>"
    const trimmedContent = content.split('<|endofinput|>')[0].trim();
    
    navigator.clipboard.writeText(trimmedContent).then(() => {
      setCopiedMessageId(messageId);
      setTimeout(() => setCopiedMessageId(null), 2000); // Reset after 2 seconds
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      showCustomToast('error', 'Failed to copy the message.');
    });
  }, []);

  // Update the handleFileChange function
  const handleFileChange = (e) => {
    if (!user) {
      showCustomToast('error', 'Please sign in to use PDF features.');
      return;
    }

    const file = e.target.files[0];

    if (file && file.type === 'application/pdf') {
      // Get the current chat's PDF count
      const currentChatResources = resources[chatId] || [];
      const currentPdfCount = currentChatResources.filter(resource => resource.type === 'pdf').length;

      if (currentPdfCount >= 5) {
        showCustomToast('error', 'You can only attach up to 5 PDFs per chat.');
        return;
      }

      setPdfFile({
        id: `${file.name}-${file.size}-${Date.now()}`,
        name: file.name,
        file: file,
      });
      
      // Update the count based on the current chat's resources
      setCurrentChatPdfCount(currentPdfCount + 1);
      showCustomToast('success', 'PDF attached successfully.');
    } else {
      showCustomToast('error', 'Only PDF files are allowed.');
    }

    e.target.value = '';
  };

  // Update the removePDF function
  const removePDF = () => {
    setPdfFile(null);
    setCurrentChatPdfCount(prevCount => prevCount - 1);
  };
  const handleAttachClick = (e) => {
    const currentChatResources = resources[chatId] || [];
    const currentPdfCount = currentChatResources.filter(resource => resource.type === 'pdf').length;

    e.preventDefault();
    
    if (!user) {
      showCustomToast('error', 'Please sign in to use PDF features.');
      return;
    }

    const userTier = user?.subscription?.tier?.toLowerCase();
    
    if ((userTier === 'free' || userTier === 'premium') && currentPdfCount >= 1) {
      setSalesModalFeature('Additional PDF Upload');
      setShowSalesModal(true);
      return;
    }

    if (userTier === 'pro' && currentPdfCount >= 3) {
      setSalesModalFeature('Additional PDF Upload');
      setShowSalesModal(true);
      return;
    }

    if (userTier === 'ultimate' && currentPdfCount >= 5) {
      showCustomToast('error', 'You have reached the maximum number of PDFs per chat.');
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };



  // Update the renderMessage function
  const renderMessage = useCallback((message, index, array) => {
    const isEditing = message.id === editingMessageId;
    const hasEdits = message.edit_count > 0;
    const activeIndex = activeBranchIndex[message.id] || 0;
    const activeMessageGroup = groupedMessages.find(group => group[0].id === message.id);
    const activeMessage = activeMessageGroup ? activeMessageGroup[activeIndex] : message;

    const isLastMessage = index === array.length - 1;
    const isLastAIMessage = message.is_ai && array.slice(index + 1).every(msg => !msg.is_ai);

    // Add this condition at the start of the regenerating message check
    if (message.id === regeneratingMessageId) {
      return (
        <div key={message.id} className={`message ai ${isSidebarOpen ? 'sidebar-open' : ''}`}>
          <img src={studyBuddyLogo} alt="StudyBuddy AI" className="ai-icon" />
          <div className="message-content">
            <AIResponseRenderer 
              content={message.content} // Show the current accumulated content
              renderResource={renderResource} 
            />
            {renderLoadingSymbol()}
          </div>
        </div>
      );
    }

    /**
     * Renders content with support for multiple <quote></quote> tags.
     * Splits the content based on the <quote> tags and renders each part accordingly.
     * 
     * @param {string} content - The message content to render.
     * @returns {JSX.Element} The rendered content with styled quotes.
     */
    const renderContent = (content) => {
      const quoteRegex = /<quote>(.*?)<\/quote>/gs;
      // Updated regex to better handle code blocks
      const codeBlockRegex = /```([\w-]*)\n?([\s\S]*?)```/g;
      const parts = [];
      let lastIndex = 0;
      let match;

      // Function to process text and add it to parts
      const addTextToParts = (text, key) => {
        if (text.trim()) {
          parts.push(
            <MarkdownRenderer 
              key={key} 
              content={text} 
              disallowedElements={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'iframe', 'script', 'style', 'table', 'blockquote', 'pre']}
            />
          );
        }
      };

      // Iterate over all matches (quotes and code blocks)
      while ((match = quoteRegex.exec(content)) !== null || (match = codeBlockRegex.exec(content)) !== null) {
        const start = match.index;
        const fullMatch = match[0];

        // Add the text before the current match
        if (start > lastIndex) {
          const text = content.substring(lastIndex, start);
          addTextToParts(text, lastIndex);
        }

        if (fullMatch.startsWith('<quote>')) {
          // Handle quote content
          const quoteContent = match[1]?.trim();
          if (quoteContent) {
            parts.push(
              <div key={start} className="quote-content">
                {quoteContent}
              </div>
            );
          }
        } else {
          // Handle code block with improved parsing
          const language = match[1]?.trim() || 'text';
          const codeContent = match[2]?.trim();
          
          if (codeContent) {
            parts.push(
              <div key={start} className="modern-code-display">
                <SyntaxHighlighter
                  language={language}
                  wrapLines={true}
                  wrapLongLines={true}
                  customStyle={{
                    margin: 0,
                    borderRadius: '8px',
                    padding: '1rem',
                  }}
                >
                  {codeContent}
                </SyntaxHighlighter>
              </div>
            );
          }
        }

        lastIndex = start + fullMatch.length;
      }

      // Add any remaining text after the last match
      if (lastIndex < content.length) {
        const text = content.substring(lastIndex);
        addTextToParts(text, lastIndex);
      }

      return <>{parts}</>;
    };

    return (
      <div 
        key={message.id || generateTempId()} // Ensure unique key even if id is null
        className={`message ${message.is_ai ? 'ai' : 'user'} ${isSidebarOpen ? 'sidebar-open' : ''}`}
        ref={isLastMessage ? lastMessageRef : null}
      >
        {message.is_ai ? (
          <img src={studyBuddyLogo} alt="StudyBuddy AI" className="ai-icon" />
        ) : null}
        <div className={`message-content ${isEditing ? 'editing' : ''}`}>
          {isEditing ? (
            <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(message.id); }}>
              <textarea
                ref={editInputRef}
                className="edit-input auto-expand"
                value={editContent.split('<|endofinput|>')[0].trim()} // Remove <|endofinput|> and trim
                onChange={(e) => {
                  setEditContent(e.target.value);
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleEditSubmit(message.id);
                  }
                }}
                onInput={handleTextareaInput}
                autoFocus
              />
            </form>
          ) : (
            message.is_ai ? (
              <AIResponseRenderer
                content={activeMessage.content}           
                renderResource={renderResource}
              />
            ) : (
              <div className="user-message-content">
                {renderContent(activeMessage.content.split('<|endofinput|>')[0].trim())}
              </div>
            )
          )}
          {message.attachment && (
            <div className="attached-pdf">
              <div className="pdf-card">
                <Paperclip size={16} className="pdf-icon" />
                <span className="pdf-name">{message.attachment.name}</span>
              </div>
            </div>
          )}
          {message.is_ai ? (
            <div className="message-footer">
              {isLastMessage && isLoading && !regeneratingMessageId && renderLoadingSymbol()}
              <div className="message-actions">
                <button 
                  className="action-button" 
                  aria-label="Copy message"
                  title="Copy message"
                  onClick={() => copyToClipboard(activeMessage.content, message.id)}
                >
                  {copiedMessageId === message.id ? <Check size={16} /> : <Copy size={16} />}
                </button>
                {isLastAIMessage && user && (
                  <button 
                    className="action-button regenerate-button" 
                    aria-label="Regenerate message"
                    title="Regenerate message"
                    onClick={() => handleRegenerateMessage(message.id)}
                    disabled={regeneratingMessageId === message.id}
                  >
                    <RefreshCw size={16} />
                    <span className="hover-text right">Regenerate Message</span>
               
                  </button>
                )}
                {message.used_web ? (
                  <div className="web-indicator" title="This message used the web">
                    <Globe size={16} />
                    <span>Web</span>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            
            <div className={`message-footer ${isEditing ? 'editing' : ''}`}>
              {isEditing ? (
                <div className="edit-actions">
                  <button type="button" onClick={handleEditCancel} className="cancel-button">Cancel</button>
                  <button type="submit" onClick={handleEditSubmit} className="save-button"> Save </button>
                </div>
              ) : (
                <>

                    {/* <div className="message-actions branch">

                        <button
                          className="branch-button previous"
                          onClick={() => switchBranch(message.id, 'previous')}
                          disabled={activeIndex <= 0}
                          aria-label="Previous version"
                        >
                          <FaChevronLeft />
                        </button>
                        <div className="branch-info">
                          <span className="current-version">{activeIndex + 1}</span>
                          <span className="version-separator"> of </span>
                          <span className="total-versions">{message.edit_count + 1}</span>
                        </div>
                        <button
                          className="branch-button next"
                          onClick={() => switchBranch(message.id, 'next')}
                          disabled={activeIndex >= message.edit_count}
                          aria-label="Next version"
                        >
                          <FaChevronRight />
                        </button>

                    </div> */}
                    <div className="message-actions">
                    {message.pdf_title ? (
                      <div className="pdf-indicator" title="This message used a pdf">
                        <FileText size={16} />
                        <span>{message.pdf_title}</span>
                      </div>
                    ) : null}
                    {/* <button 
                      className="action-button edit-button" 
                      title="Edit message" 
                      onClick={() => handleEditStart(message)}
                    >
                      <Edit size={16} />
                      <span>Edit</span>
                    </button>
                    <button  */}
                    <button 
                      className="action-button"
                      aria-label="Copy message"
                      title="Copy message"
                      onClick={() => copyToClipboard(activeMessage.content, message.id)}
                    >
                      {copiedMessageId === message.id ? <Check size={16} /> : <Copy size={16} />}
                    </button>
                    <div className="user-icon" aria-label="User icon" title="User icon">
                      {user?.name ? user.name.charAt(0).toUpperCase() : ''}
                    </div>
                  </div>

                </>
              )}
            </div>
          )}
        </div>
        <div className="message-info">
          {/* Add timestamp or any other info here */}
        </div>
      </div>
    );
  }, [editingMessageId, editContent, handleEditStart, handleEditSubmit, toggleBranch, activeBranchIndex, groupedMessages, user, copyToClipboard, copiedMessageId, handleRegenerateMessage, regeneratingMessageId, regeneratedContent, lastMessageUsedWeb, handleCreateResource, renderResource, switchBranch, activeBranch, generatedContent]);

  // Move getRandomHeader outside the component to prevent recreating it on every render
  const getRandomHeader = (userName) => {
    const greetingWords = ['Hello', 'Hi', 'Hey', 'Welcome', 'Good day'];
    const formattedName = userName && userName.length > 2 ? ` ${userName.split(' ')[0]}` : '';
    const messages = [
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! I'm your AI tutor.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! How can I help you learn?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What shall we explore today?`, 
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Ready to learn?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What questions do you have?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! How can I help?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What topic interests you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Let's tackle any subject.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! I'm here to help.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What would you like to learn?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Let's learn together.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Ready to begin?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What's on your mind?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! How can I assist you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What interests you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Let's get started.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What can we solve?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Let's explore together.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Ready to discover?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What's your goal?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What sparks your interest?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! How can I guide you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Let's overcome challenges.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Where shall we start?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What's on your mind?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Let's begin learning.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Ready to study?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! What's your focus?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Let's learn something new.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords.length)]}${formattedName}! Ready to excel?`,
    ];

    return messages[Math.floor(Math.random() * messages.length)];
  };

  // Render default display when there are no messages
  const renderDefaultDisplay = () => (
    <div className={`default-display ${pdfFile ? 'pdf-attached' : ''}`}>
      <img src={CognoraLogo} alt="Cognora Logo" className="imglogo" />
      {windowWidth > 600 ? (
        <h1 className="dynamic-header">
          {dynamicHeader}
        </h1>
      ) : (
        <h1 className="dynamic-header">
          Hello!
        </h1>
      )}
      
      {!user && <div className="suggestions-section">
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li 
              key={index} 
              onClick={() => (remainingChats > 0 || remainingChats == null) ? submitSuggestion(suggestion.text) : null}
              className={`suggestion-item ${(remainingChats <= 0 && remainingChats != null) ? 'disabled' : ''}`}
            >
              {suggestion.icon}
              <span>{suggestion.text}</span>
            </li>
          ))}
        </ul>
      </div>}

      {pdfFile && (
      <div className="pdf-preview empty">
        <div className="pdf-preview-content">
          <FaFilePdf className="pdf-icon" />
          <span className="pdf-name">{pdfFile.name}</span>
          <button 
            onClick={(e) => {
              e.stopPropagation();
              setPdfFile(null);
            }} 
            className="remove-pdf"
            aria-label="Remove PDF"
          >
            <FaTimes />
          </button>
        </div>
      </div>
    )}
      
      <div className={`input-area empty ${user ? 'user' : ''}`}>
      <form className={`input-wrapper empty ${pdfFile ? 'pdf-attached' : ''}`} onSubmit={handleSubmit}>
        {/* Remove PDF button for non-users */}
        {user && (
          <button
            type="button"
            className="attach-button empty"
            onClick={handleAttachClick}
            aria-label="Attach PDF"
            title="Attach PDF"
          >
            <Paperclip size={21} />
            <span className="hover-text top">
              {user ? 'Upload PDF Document' : 'Sign in to Upload PDFs'}
            </span>
          </button>
        )}
        
        <textarea
          ref={(el) => {
            textareaRef.current = el;
            if (el) {
              el.value = input;
              inputRef.current = input;
              adjustTextareaHeight();
            }
          }}
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            inputRef.current = e.target.value;
            adjustTextareaHeight();
          }}
          onKeyDown={handleKeyDown}
          onInput={adjustTextareaHeight}
          placeholder={isMobile ? "Ask me anything!" : "Ask me anything! I'm here to help you learn."}
          aria-label="Chat input"
          className="chat-input scrollable empty"
          disabled={isSubmitting || (remainingChats <= 0 && remainingChats != null)}
          autoFocus={filteredMessages.length === 0} // Add this line as a backup
        />
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
          id="pdf-upload"
        />
        <div className="mode-selector" ref={dropdownRef}>
          <button 
            type="button"
            className="mode-selector-button" 
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsDropdownOpen(!isDropdownOpen);
            }}
            aria-label={`Select chat mode. Current mode: ${currentMode}`}
            title={`Select chat mode. Current mode: ${currentMode}`}
          >
            {currentMode.charAt(0).toUpperCase() + currentMode.slice(1)} Mode
            <ChevronDown size={16} />
          </button>
          {isDropdownOpen && (
            <div className={`mode-dropdown ${isDropdownOpen ? 'active' : ''}`}>
              <div 
                className="mode-option mode-qa" 
                onClick={(e) => {
                  e.stopPropagation();
                  selectMode('qa');
                }}
                aria-label="Switch to Q&A mode"
                title="Switch to Q&A mode"
              >
                <HelpCircle size={16} /> Q&A
              </div>
              <div 
                className="mode-option mode-tutor" 
                onClick={(e) => {
                  e.stopPropagation();
                  selectMode('tutor');
                }}
                aria-label="Switch to Tutor mode"
                title="Switch to Tutor mode"
              >
                <Book size={16} /> Tutor
              </div>
              <div 
                className="mode-option mode-brainstorm" 
                onClick={(e) => {
                  e.stopPropagation();
                  selectMode('brainstorm');
                }}
                aria-label="Switch to Brainstorm mode"
                title="Switch to Brainstorm mode"
              >
                <Lightbulb size={16} /> Brainstorm
              </div>
              <div 
                className="mode-option mode-debate" 
                onClick={(e) => {
                  e.stopPropagation();
                  selectMode('debate');
                }}
                aria-label="Switch to Debate mode"
                title="Switch to Debate mode"
              >
                <MessageSquare size={16} /> Debate
              </div>
            </div>
          )}
        </div>
        <button 
          type="submit" 
          disabled={
            isSubmitting || 
            isInputProcessing || 
            (!input.trim()) || 
            (remainingChats <= 0 && remainingChats != null)
          } 
          aria-label="Send message"
          className="send-button empty"
        >
          <Send size={21} />
        </button>
      </form>      
    </div>
          
      {user && (
        <>
        {chatsData?.chats && chatsData.chats.length > 0 && (
          <div className="recent-chats-section">
            <h2>
              <History size={24} style={{ marginRight: '0px', color: '#F4806B' }} />
              Recent Conversations
            </h2>
            <ul className="recent-chats-list">
              {[...chatsData.chats]
                .sort((a, b) => new Date(b.last_modified) - new Date(a.last_modified))
                .slice(0, 9)
                .map((chat) => (
                  <li 
                    key={chat.id} 
                    className="recent-chat-item"
                    onClick={() => navigate(`/${chat.id}`)}
                  >
                    <div className="chat-preview">
                      <div className="chat-preview-header">
                        <div className="chat-preview-icon">
                          <MessageSquare size={18} />
                        </div>
                        <div className="chat-info">
                          <span className="chat-title" title={chat.title || 'Untitled Chat'}>
                            {windowWidth < 500 && chat.title && chat.title.length > 20 
                              ? `${chat.title.substring(0, 30)}...` 
                              : (chat.title || 'Untitled Chat')}
                          </span>
                          <span className="chat-date">
                            <Calendar size={14} />
                            {getRelativeTime(chat.last_modified)}
                          </span>
                        </div>
                      </div>
                      <ChevronRight size={18} className="preview-arrow" />
                    </div>
                  </li>
              ))}
            </ul>
          </div>
        )}
        </>
      )}





  </div>
);

  // Submit a suggestion as a message
  const submitSuggestion = (suggestion) => {
    console.log('submitSuggestion called', { suggestion });
    if (remainingChats <= 0 && remainingChats != null) {
      console.log('No remaining chats, cannot submit suggestion');
      return;
    }
    setInput(suggestion);
    inputRef.current = suggestion;
    console.log('Input set, calling handleSubmit');
    handleSubmit({ preventDefault: () => {} });
  };

  // Render chat limit banner
  const renderChatLimitBanner = () => {
    const currentRemaining = user ? remainingChats : remainingGuestChats;
    
    return currentRemaining <= 2 && (
      <div className="chat-limit-banner">
        <div className="chat-limit-content">
          <span>
            {currentRemaining === 0
              ? "You've reached your chat limi."
              : `${currentRemaining} chat${currentRemaining === 1 ? '' : 's'} remaining `}
                 <Link to="/pricing" className="signup-link">Upgrade for more!</Link>
          </span>
        </div>
      </div>
    );
  };

  // Render loading symbol
  const renderLoadingSymbol = () => (
    <div className="loading-symbol">
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
    </div>
  );

  // Initial textarea height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '23px';
    }
  }, []);

  // Adjust textarea height dynamically
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '23px';
      const newHeight = Math.min(textareaRef.current.scrollHeight, 120);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  // Handle scroll to manage the visibility of the scroll button
  const handleScroll = useCallback(() => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const threshold = 100; // pixels from bottom
      const atBottom = scrollHeight - scrollTop - clientHeight <= threshold;
      setIsUserAtBottom(atBottom);


      if (atBottom) {
        setShowScrollButton(false);
        setNewMessagesCount(0);
      } else {
        setShowScrollButton(true);
      }


      if(isGeneratingResponse && atBottom){
        setShowScrollButton(false);
      }
    }
  }, []);

  // Attach scroll event listener
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
      return () => messagesContainer.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  // Handle key down events for the textarea
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      // Check the same conditions as the submit button
      const isDisabled = 
        isSubmitting || 
        isInputProcessing || 
        (!input.trim()) || 
        (remainingChats <= 0 && remainingChats != null);

      if (!isDisabled) {
        handleSubmit(e);
      }
    }
  };

  // Handle search query changes
  useEffect(() => {
    if (searchQuery.trim()) {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = messages.filter(message =>
        message.content.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredMessages(Array.isArray(filtered) ? filtered : []); // Ensure it's an array
    } else {
      setFilteredMessages(Array.isArray(messages) ? messages : []); // Ensure it's an array
    }
  }, [searchQuery, messages]);

  // Add this useEffect to update currentMode when localStorage changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'defaultChatModel') {
        setCurrentMode(e.newValue || 'qa');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Add this useEffect to handle clicks outside the mode selector
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

// ... rest of the code ...
  // Update the mode selection function
  const selectMode = (mode) => {
    setCurrentMode(mode);
    localStorage.setItem('defaultChatModel', mode); // Add this line to persist the selection
    setIsDropdownOpen(false);
  };

  // Add this function to toggle the Resources section
  const toggleResources = () => {
    setIsResourcesVisible(prev => !prev);
    if (!isMobile) {
    }
  };

  // Add this new function to handle closing the Resources section
  const closeResources = () => {
    setIsResourcesVisible(false);
  };

  const closeExpandedResource = () => {
    setIsResourcesExpanded(false);
    setExpandedResource(null);

    // Remove resource-related query params while keeping others
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('resource');
    newParams.delete('version');
    setSearchParams(newParams);
  }

  const getResourceIcon = (type) => {
    switch (type) {
      case 'essay':
        return <FileText size={16} />;
      case 'pdf':
        return <FileText size={16} />;
      case 'visualization':
        return <BarChartOutlined size={16} />;
      default:
        return <File size={16} />;
    }
  };

  const renderExpanded = (onClose, versions) => {
    return (
      <ExpandedResource
        expandedVersion={expandedVersion}
        resource={expandedResource}
        onClose={closeExpandedResource}
        onImprove={handleImprove}
        onExplain={handleExplain}
        allVersions={versions}
        desiredVersion={expandedResource?.desiredVersion}
        showCustomToast={showCustomToast} // Add this prop
      />
    )
  }

  // Add this new useEffect
  useEffect(() => {
    if (isResourcesExpanded) {
      setIsSidebarOpen(false);
    }
  }, [isResourcesExpanded]);

  // Check for state from auth flow
  useEffect(() => {
    if (location.state) { 
      const { inputValue, attachments } = location.state;
      if (inputValue) {
        setInput(inputValue);
        // Clear the navigation state after using it
        window.history.replaceState({}, document.title, window.location.pathname);
      }
      if (attachments) setPdfFile(attachments[0]);
    }

    // Check for pending state from checkout
    const pendingState = localStorage.getItem('pendingChatState');
    if (pendingState) {
      const { inputValue, attachments } = JSON.parse(pendingState);
      if (inputValue) setInput(inputValue);
      if (attachments) setPdfFile(attachments[0]);
      // Remove from localStorage after using it
      localStorage.removeItem('pendingChatState');
    }
  }, [location]);

  // Add this effect near your other useEffects
  useEffect(() => {
    if (location.state?.autoSubmitMessage) {
      // Set the input and mode
      setInput(location.state.autoSubmitMessage);
      if (location.state.mode) {
        selectMode(location.state.mode);
      }
      
      // Auto submit after a short delay
      const timer = setTimeout(() => {
        handleSubmit(new Event('submit'));
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [location.state]);

  // Add this new component for the chat not found message
  const ChatNotFound = () => {
    
    const message = messagesError?.isAuthError
      ? "Please log in to view this chat."
      : "This chat doesn't exist.";

    return (
      <div className="chat-not-found">

        <div className="chat-not-found-content">
        <img src={CognoraLogo} alt="Cognora Logo" className="notexistslogo" />
          <h2>Chat Not Found</h2>
          <p>{message}</p>
          {messagesError?.isAuthError && (
            <Link to="/login" className="login-button">
              Log In
            </Link>
          )}
        </div>
      </div>
    );
  };

  // Add this new useEffect
  useEffect(() => {
    if (chatId && chatId !== 'new' && filteredMessages.length > 0) {
      setTimeout(() => {
        scrollToBottom();
      }, 100); // Small delay to ensure content is loaded
    }
  }, [chatId]);

  // Update the useEffect that handles resources visibility
  useEffect(() => {
    const hasPDFsOrResources = (resources?.[chatId]?.length > 0) || currentChatPdfCount > 0;
    
    // Only auto-open resources if:
    // 1. Not on mobile
    // 2. Not a new chat
    // 3. Chat ID exists
    // 4. User exists
    // 5. Has PDFs or resources
    setIsResourcesVisible(() => {
      return !isMobile && 
             chatId !== 'new' && 
             chatId !== undefined && 
             user && 
             hasPDFsOrResources;
    });
  }, [isMobile, chatId, user, resources, currentChatPdfCount]);

  // Add this effect to handle chat switching
  useEffect(() => {
    if (chatId && expandedResource) {
      // Check if the current expanded resource belongs to the current chat
      const chatResources = resources[chatId] || [];
      const resourceExists = chatResources.some(
        resource => resource.title === expandedResource.title
      );

      if (!resourceExists) {
        // Reset expanded resource and related states
        setExpandedResource(null);
        setIsResourcesExpanded(false);
        
        // Remove resource-related query params
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('resource');
        newParams.delete('version');
        setSearchParams(newParams);
      }
    }
  }, [chatId, expandedResource, resources, setSearchParams]);

  // Update the useEffect cleanup for message streaming
  useEffect(() => {
    return () => {
      // Instead of aborting the request, just clean up the frontend state
      setIsGenerating(false);
      setIsSubmitting(false);
      setIsGeneratingResponse(false);
      setGeneratedContent('');
      
      // Don't abort the controller, let backend continue
      abortControllerRef.current = null;
    };
  }, []);

  // Add this new useEffect to focus the textarea on page load
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []); // Empty dependency array means this runs once on mount

  // Add this useEffect to persist sidebar state
  useEffect(() => {
    const sidebarState = localStorage.getItem('sidebarOpen');
    if (sidebarState !== null) {
      setIsSidebarOpen(sidebarState === 'true');
      localStorage.removeItem('sidebarOpen');
    }
  }, []);

  // Add a new useEffect to handle PDF count when switching chats
  useEffect(() => {
    if (chatId && resources) {
      // Count PDFs in the current chat's resources
      const currentChatResources = resources[chatId] || [];
      const pdfCount = currentChatResources.filter(resource => resource.type === 'pdf').length;
      
      // Update the count
      setCurrentChatPdfCount(pdfCount);
    } else if (chatId === 'new') {
      // Reset count for new chats
      setCurrentChatPdfCount(0);
    }
  }, [chatId, resources]);

  // Add new state for mobile search overlay
  const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(false);

  // Update the header section to show different search UI based on screen size
  const renderHeaderCenter = () => {
    if (!user) return null;

    return (
      <div className="search-container">
        {window.innerWidth > 768 ? (
          <input 
            type="text" 
            placeholder="Search conversations..." 
            className="search-input"
            value={searchQuery}
            onChange={handleSearch}
            aria-label="Search conversations"
            inputMode="search"
            autoComplete="off"
          />
        ) : (
          <button
            className="mobile-search-button"
            onClick={() => setIsSearchOverlayOpen(true)}
            aria-label="Open search"
          >
            <Search size={20} />
          </button>
        )}
      </div>
    );
  };

  return (
    <div 
      className={`chat-page ${isSidebarOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-open' : ''}`}
      {...getRootProps()}
    >
        {showToast && (
      <Toast 
        message={toastMessage} 
        type={toastType} 
        onClose={() => setShowToast(false)} 
      />
    )}
    <Helmet>
      <title>StudyBuddy</title>
      <meta name="description" content="StudyBuddy is your personal AI tutor powered by advanced language models. Get instant help with homework, exam prep, and learning across all subjects." />
      
      {/* Enhanced keywords */}
      <meta name="keywords" content="StudyBuddy, Cognora AI, artificial intelligence tutor, intelligent tutoring, homework help, online tutoring, educational chatbot, study assistant, exam preparation, personalized learning, AI education, virtual tutor, academic support, intelligent tutoring system, machine learning education, ai chat, ai, study buddy, study bdy, study buddy 3, study buddy login, study buddy website, study buddy, study bddy, chat cognora, chat, chatai, chatbot study buddy, studyb, studybuddy login, studybuddy, study buddy chat, cognora, cognora chat, cognora studybuddy, cognora" />
      {/* Additional meta tags */}
      <meta property="og:title" content="StudyBuddy - Your AI-Powered Learning Companion" />
      <meta property="og:description" content="Experience personalized tutoring with our advanced AI technology. Get instant help with any subject, 24/7." />
      
      {/* Primary meta tags */}
      <link rel="canonical" href={`https://studdybuddy.ca/`} />
      
      {/* OpenGraph tags */}
      <meta property="og:title" content={`StudyBuddy`} />
      <meta property="og:description" content="Talk with StudyBuddy, your AI-powered study companion" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://studdybuddy.ca" />
      
      {/* Additional SEO meta tags */}
      <meta name="author" content="Cognora" />
      <meta name="robots" content="index, follow" />
      
      {/* Schema.org markup enhancement */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "SoftwareApplication",
          "name": "StudyBuddy",
          "applicationCategory": "EducationalApplication",
          "creator": {
            "@type": "Organization",
            "name": "Cognora",
            "url": "https://cognora.ca"
          },
          "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
          },
          "description": "AI-powered educational platform by Cognora offering personalized tutoring and academic support",
          "featureList": [
            "Advanced AI tutoring technology",
            "Interactive learning interface",
            "PDF analysis and comprehension",
            "Multiple learning modes",
            "Personalized study assistance",
            "Real-time AI responses"
          ]
        })}
      </script>
    </Helmet>

    {/* Enhanced visually hidden headings */}
    <h1 className="visually-hidden">StudyBuddy by Cognora: Leading AI-Powered Educational Assistant</h1>
    <h2 className="visually-hidden">Next-Generation Learning with Cognora's AI Technology</h2>

    {/* Enhanced hidden semantic content */}
    <div className="visually-hidden">
      <h3>Cognora's Advanced AI Learning Features</h3>
      <ul>
        <li>Powered by Cognora's proprietary AI technology</li>
        <li>Integration with GPT, Claude, and Gemini AI</li>
        <li>Industry-leading educational AI platform</li>
        <li>Advanced natural language processing for education</li>
        <li>Premium alternative to traditional tutoring services</li>
      </ul>
      
      <h3>Comprehensive Educational Support</h3>
      <ul>
        <li>Advanced STEM subjects and humanities support</li>
        <li>Professional academic writing assistance</li>
        <li>Standardized test preparation</li>
        <li>Research methodology guidance</li>
        <li>Custom learning path development</li>
      </ul>
    </div>

    {showSalesModal && (
        <SalesModal
          feature={salesModalFeature}
          onClose={closeSalesModal}
          subscription={subscription}
        />
      )}

      <input {...getInputProps()} />
      {/* Sidebar Component */}
      <Sidebar 
        isOpen={isSidebarOpen} 
        toggleSidebar={toggleSidebar} 
        updateTitle={handleUpdateTitle}
        showCustomToast={showCustomToast}
        currentChatId={chatIdToUse}
        currentChatTitle={currentChatTitle}
      />

      {/* Chat Header */}
      
      <div className={`chat-header ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="header-left">
          {(!user) ? (
            <div className="logo">StudyBuddy</div>
          ) : (
            <>
              <button 
                className="menu-button" 
                onClick={toggleSidebar}
                aria-label="Toggle sidebar"
              >
                <Menu size={24} />
                <span className="hover-text ">Navigation Menu</span>
              </button>
              <button 
                className="new-chat-button" 
                onClick={handleAddChat}
                aria-label="Start new chat"
              >
                <Edit size={24} style={{ marginRight: '8px' }} />
                New Chat
                <span className="hover-text bottom">Start a Fresh Conversation</span>
              </button>
            </>
          )}
          
        </div>
        
          <div className="header-center">
          {renderHeaderCenter()}
          </div>

        <div className="header-right">
          {user ? (
            <>
              <button 
                className={`chat-controls-toggle ${isResourcesVisible ? 'active' : ''}`}
                onClick={toggleResources}
              >
                <Sliders size={24} />
                <span className="hover-text right primary">
                  {isResourcesVisible ? 'Hide Resources Panel' : 'Show Resources Panel'}
                </span>
              </button>
              <Link 
                to="/settings" 
                className="settings-button" 
                aria-label="Settings"
              >
                <Settings size={24} />
                <span className="hover-text right">Account Settings</span>
              </Link>
            </>
          ) : (
            <>
                <Link 
                  to="/signup" 
                  className="auth-button" 
                >
                  Sign Up
                  <span className="hover-text bottom primary">Create Free Account</span>
                </Link>
              <Link 
                to="/login" 
                className="auth-button sign-in"
              >
                Login
                <span className="hover-text bottom">Sign In to Your Account</span>
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Add the mobile search overlay */}
      <MobileSearchOverlay
        isOpen={isSearchOverlayOpen}
        onClose={() => setIsSearchOverlayOpen(false)}
        searchQuery={searchQuery}
        onSearch={handleSearch}
        searchResults={searchResults}
        onResultClick={handleSearchResultClick}
      />

      {/* Chat Area */}
      <div className={`chat-area ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'} ${isResourcesVisible ? 'resources-open' : ''} ${isResourcesExpanded ? 'resources-expanded' : ''}`}>
        {/* Messages Container */}
        <div 
          className={`messages-container ${isSidebarOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-open' : ''}`} 
        >
          <div className="messages-wrapper"
            ref={messagesContainerRef}
            style={{ overscrollBehavior: 'contain' }}
          >
            <div className="messages-content">
              {filteredMessages && filteredMessages.length === 0 ? (
                searchQuery && window.innerWidth > 768 ? (
                  <div className="no-results">No messages found matching your search.</div>
                ) : (
                  isDefaultDisplay ? renderDefaultDisplay() : null
                )
              ) : (
                <>
                  {filteredMessages && filteredMessages.map((message, index, array) => renderMessage(message, index, array))}
                </>
              )}
              {searchQuery && searchResults.length > 0 && window.innerWidth > 768 && (
                <OtherChatsSearchResults 
                  results={searchResults}
                  onResultClick={handleSearchResultClick}
                />
              )}
              {isGeneratingResponse ? (
                <div className="message ai">
                  <img src={studyBuddyLogo} alt="StudyBuddy AI" className="ai-icon" />
                  <div className="message-content">
                    <AIResponseRenderer content={generatedContent} renderResource={renderResource} />
                    {renderLoadingSymbol()}
                  </div>
                </div>
              ) : null}
              <div ref={messagesEndRef} style={{ height: '30px' }} /> {/* Make sure this is at the very end */}
            </div>
            <ResourcesSection
              isLoadingResource={isLoadingResource}
              renderExpanded={renderExpanded}           
              ExpandedResource={ExpandedResource}
              isVisible={isResourcesVisible}
              projectTitle={currentChatTitle}
              isExpanded={isResourcesExpanded}
              setIsExpanded={setIsResourcesExpanded}
              onClose={closeResources}
              resources={resources[chatId] || []}  // Pass the resources specific to the current chat
              onResourceClick={handleResourceClick}
              expandedResource={expandedResource}
              setExpandedResource={setExpandedResource}
              showCustomToast={showCustomToast}
            />
          </div>
        </div>

        {pdfFile && (filteredMessages && (filteredMessages.length > 0)) ? (
                <div className="pdf-preview normal">
                  <div className="pdf-preview-content">
                    <FaFilePdf className="pdf-icon" />
                    <span className="pdf-name">{pdfFile.name}</span>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        setPdfFile(null);
                      }} 
                      className="remove-pdf"
                      aria-label="Remove PDF"
                    >
                      <FaTimes />
                    </button>
                  </div>
                </div>
        ) : null}

      {showAuthPrompt && (
        <div className="auth-prompt">
          <AlertCircle size={24} />
          <div className="auth-prompt-content">
            <button 
              className="close-auth-prompt"
              onClick={() => setShowAuthPrompt(false)}
              aria-label="Close auth prompt"
            >
              <FaTimes />
            </button>
            <p>Please sign up or log in to continue messaging</p>
            <div className="auth-prompt-buttons">
              <Link to="/signup" className="auth-prompt-button signup">
                Sign Up
              </Link>
              <Link to="/login" className="auth-prompt-button login">
                Log In
              </Link>
            </div>
          </div>
        </div>
      )}

        {/* Input Container */}
        <div 
          className={`input-container ${isResourcesVisible ? 'resources-open' : ''} ${isDragActive ? 'dragging' : ''} ${!user ? 'auth-disclaimer' : ''}`}
        >
          {filteredMessages && (filteredMessages.length > 0) ? (
            <>
              <div className={`input-area ${!user ? 'auth-disclaimer' : ''}`}>
                <form className="input-wrapper" onSubmit={handleSubmit}>
                  {user && (
                    <button
                      type="button"
                      className="attach-button"
                      onClick={handleAttachClick}
                    >
                      <Paperclip size={21} />
                      <span className="hover-text top">
                        {user ? 'Upload PDF Document' : 'Sign in to Upload PDFs'}
                      </span>
                    </button>
                  )}
                  <textarea
                    ref={(el) => {
                      textareaRef.current = el;
                      if (el) {
                        el.value = input;
                        inputRef.current = input;
                        adjustTextareaHeight();
                      }
                    }}
                    value={input}
                    onChange={(e) => {
                      setInput(e.target.value);
                      inputRef.current = e.target.value;
                      adjustTextareaHeight();
                    }}
                    onKeyDown={handleKeyDown}
                    onInput={adjustTextareaHeight}
                    placeholder="Type your message here..."
                    aria-label="Chat input"
                    className="chat-input scrollable"
                    disabled={isSubmitting}
                    autoFocus={filteredMessages.length > 0}
                  />
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    id="pdf-upload"
                  />
                  <div className="mode-selector" ref={dropdownRef}>
                    <button 
                      type="button" 
                      className="mode-selector-button" 
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDropdownOpen(!isDropdownOpen);
                      }}
                      aria-label={`Select chat mode. Current mode: ${currentMode}`}
                      title={`Select chat mode. Current mode: ${currentMode}`}
                    >
                      {currentMode.charAt(0).toUpperCase() + currentMode.slice(1)} Mode
                      <ChevronDown size={16} />
                    </button>
                    {isDropdownOpen && (
                      <div className={`mode-dropdown ${isDropdownOpen ? 'active' : ''} ${window.innerWidth < 600 ? '' : 'large'}`}>
                        <div 
                          className="mode-option mode-qa" 
                          onClick={(e) => {
                            e.stopPropagation();
                            selectMode('qa');
                          }}
                          aria-label="Switch to Q&A mode"
                          title="Switch to Q&A mode"
                        >
                          <HelpCircle size={16} /> Q&A
                        </div>
                        <div 
                          className="mode-option mode-tutor" 
                          onClick={(e) => {
                            e.stopPropagation();
                            selectMode('tutor');
                          }}
                          aria-label="Switch to Tutor mode"
                          title="Switch to Tutor mode"
                        >
                          <Book size={16} /> Tutor
                        </div>
                        <div 
                          className="mode-option mode-brainstorm" 
                          onClick={(e) => {
                            e.stopPropagation();
                            selectMode('brainstorm');
                          }}
                          aria-label="Switch to Brainstorm mode"
                          title="Switch to Brainstorm mode"
                        >
                          <Lightbulb size={16} /> Brainstorm
                        </div>
                        <div 
                          className="mode-option mode-debate" 
                          onClick={(e) => {
                            e.stopPropagation();
                            selectMode('debate');
                          }}
                          aria-label="Switch to Debate mode"
                          title="Switch to Debate mode"
                        >
                          <MessageSquare size={16} /> Debate
                        </div>
                      </div>
                    )}
                  </div>
                  <button 
                    type="submit" 
                    disabled={
                      isSubmitting || 
                      isInputProcessing || 
                      (!input.trim()) || 
                      (remainingChats <= 0 && remainingChats != null)
                    } 
                    aria-label="Send message"
                    className="send-button"
                  >
                    <Send size={21} />
                  </button>
                </form>
              </div>

            </>
          ) : null}
          {window.innerWidth > 0 && remainingChats < 5 && (remainingChats != null && user) && renderChatLimitBanner()}
        </div>
        {!user && (
          <div className={`auth-disclaimer ${filteredMessages && filteredMessages.length > 0 ? 'has-messages' : ''}`}>
            <p>Chats are not saved in guest mode. Sign in to unlock full chat capabilities, save your conversations, and get personalized learning.</p>
            {filteredMessages.length === 0 && (
              <p>By sending a message, you agree to our <a href="https://cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
            )}
          </div>
        )}
            {showScrollButton && !isDefaultDisplay && (
              <button 
                className="scroll-to-bottom" 
                onClick={() => {
                  scrollToBottom();
                  setAutoScroll(true);
                }} 
                aria-label="Scroll to bottom"
                title="Scroll to bottom"
              >
                <ChevronDown size={20} />
              </button>
            )}
      </div>

      {isDragActive && (
        <div className="pdf-drop-overlay">
          <div className="pdf-drop-content">
            <FaFilePdf size={64} />
            <h3>Drop your PDF here</h3>
            <p>Release to attach the PDF to your chat</p>
          </div>
        </div>
      )}

      {/* Add this new component for the auth prompt */}


      {/* Update the error handling condition */}
      {(!chatExists && isChatsLoaded) || messagesError?.isNotFoundError || messagesError?.isAuthError ? (
        <ChatNotFound />
      ) : (
        <>
          {/* Rest of your existing JSX */}

        </>
      )}
    </div>
  );
};

export default React.memo(Chats);
