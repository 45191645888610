/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { createCheckoutSession, handleStripePortalRedirect } from '../services/api';
import { FaCheck, FaArrowRight } from 'react-icons/fa';
import useDocumentTitle from '../UseDocumentTitle';
import '../Styles/Pricing.css';
import { Helmet } from 'react-helmet';

const Pricing = () => {
    useDocumentTitle('StudyBuddy - Pricing');
    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuth();
    const subscription = user?.subscription;
    const { tierName } = useParams();
    const searchParams = new URLSearchParams(window.location.search);
    const promoCode = searchParams.get('promo');
    const trialdays = searchParams.get('promodays');
    const [currency, setCurrency] = useState('usd');
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [activeFaqId, setActiveFaqId] = useState(null);

    const pricingTiers = [
        { 
            name: 'Premium', 
            tagline: 'Essential Learning Tools',
            highlight: false,
            price: { 
                usd: { monthly: '$5.59', annual: '$49.99' },
                cad: { monthly: '$6.99', annual: '$59.99' },
                eur: { monthly: '€5.59', annual: '€49.99' }
            },
            freeTrial: '7 days',
            features: [
                'Access to core AI capabilities',
                'Increased interaction limits',
                '1 PDF upload per chat',
            ]
        },
        { 
            name: 'Pro', 
            tagline: 'Extensive Learning Suite',
            highlight: true,
            price: { 
                usd: { monthly: '$12.99', annual: '$119.99' },
                cad: { monthly: '$15.99', annual: '$139.99' },
                eur: { monthly: '€12.99', annual: '€119.99' }
            },
            freeTrial: '7 days',
            features: [
                'Everything in Premium, plus:',
                'Unlimited AI interactions',
                'Up to 3 PDF uploads per chat',
            ]
        },
        { 
            name: 'Ultimate', 
            tagline: 'Complete Learning Suite',
            highlight: false,
            price: { 
                usd: { monthly: '$18.99', annual: '$159.99' },
                cad: { monthly: '$21.99', annual: '$189.99' },
                eur: { monthly: '€18.99', annual: '€159.99' }
            },
            freeTrial: '7 days', 
            features: [
                'Everything in Pro, plus:',
                'Priority response times',
                'Access to beta features',
                'Up to 5 PDF uploads per chat',
            ]
        }
    ];

    useEffect(() => {
        const userLanguage = navigator.language || navigator.userLanguage;
        if (userLanguage.toLowerCase().includes('ca')) {
            setCurrency('cad');
        } else {
            setCurrency('usd');
        }
    }, []);

    const handleSubscription = async (tier, interval = billingCycle, currencyCode = currency, trialdays) => {
        console.log('Starting handleSubscription with params:', { tier, interval, currencyCode, trialdays });

        if (!isAuthenticated) {
            console.log('User not authenticated, redirecting to signup');
            navigate('/signup', { 
                state: { 
                    selectedTier: tier, 
                    interval, 
                    currency: currencyCode,
                    promoCode,
                    trialdays: trialdays || searchParams.get('promodays')
                } 
            });
            return;
        }

        try {
            if(subscription && subscription.tier !== "free"){
                console.log('User has existing non-free subscription, redirecting to portal');
                await handleStripePortalRedirect();
                return;
            }

            console.log('Creating checkout session for tier:', tier);
            let response;
            
            if(tier === "free" || tier === "premium"){
                console.log('Processing free/premium tier without promo code');
                response = await createCheckoutSession(tier, interval, currencyCode, promoCode, trialdays || searchParams.get('promodays'));
            } else {
                console.log('Processing paid tier with promo code:', promoCode);
                response = await createCheckoutSession(tier, interval, currencyCode, promoCode, trialdays || searchParams.get('promodays'));
            }

            if (response.url) {
                window.location.href = response.url;
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    const renderButton = (tier) => {
        if (!user) {
            return (
                <button 
                    onClick={() => handleSubscription(
                        tier.name.toLowerCase(), 
                        billingCycle, 
                        currency, 
                        searchParams.get('promodays')
                    )} 
                    className="signup-button"
                >
                    {tier.name.toLowerCase() === 'free' ? 'Get Started' : `Choose ${tier.name}`}
                </button>
            );
        }

        if (subscription && subscription.tier !== 'free') {
            if (subscription.tier.toLowerCase() === tier.name.toLowerCase()) {
                return (
                    <button onClick={handleStripePortalRedirect} className="signup-button">
                        Manage Subscription
                    </button>
                );
            } else {
                return (
                    <button 
                        className="signup-button" 
                        style={{visibility: 'hidden'}} 
                        disabled
                    >
                        Invisible
                    </button>
                );
            }
        }

        return (
            <button 
                onClick={() => handleSubscription(
                    tier.name.toLowerCase(), 
                    billingCycle, 
                    currency, 
                    searchParams.get('promodays')
                )} 
                className="signup-button"
            >
                {tier.name.toLowerCase() === 'free' ? 'Current Plan' : `Choose ${tier.name}`}
            </button>
        );
    };

    useEffect(() => {
        const detectUserLocation = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                if (data.country_code === 'CA') {
                    setCurrency('cad');
                } else if (data.country_code === 'US') {
                    setCurrency('usd');
                } else if (data.continent_code === 'EU') {
                    setCurrency('eur');
                } else {
                    setCurrency('usd'); // Default to USD for other countries
                }
            } catch (error) {
                console.error('Error detecting user location:', error);
                setCurrency('usd'); // Default to USD if there's an error
            }
        };

        detectUserLocation();
    }, []);

    const toggleFaq = (id) => {
        setActiveFaqId(activeFaqId === id ? null : id);
    };

    const faqs = [
        {
            id: 1,
            question: "How does StudyBuddy's AI tutor work?",
            answer: "StudyBuddy is an expertly crafted interface that leverages the power of advanced language models. It analyzes your inputs and learning patterns to provide tailored, interactive educational experiences. Think of it as having a highly intelligent tutor available 24/7, ready to assist you with personalized explanations, targeted practice questions, and customized study materials."
        },
        {
            id: 2,
            question: "Is StudyBuddy suitable for all subjects?",
            answer: "Yes! StudyBuddy covers a wide range of subjects including mathematics, sciences, humanities, and languages. Our AI is constantly learning and expanding its knowledge base."
        },
        {
            id: 3,
            question: "Can I use StudyBuddy on multiple devices?",
            answer: "Absolutely! StudyBuddy is accessible on desktop, tablet, and mobile devices. Your progress syncs across all platforms for seamless learning. Mobile app coming!."
        },
        {
            id: 4,
            question: "What payment methods do you accept?",
            answer: "We accept all major credit cards, including Visa, MasterCard, American Express, and Discover."
        },
        {
            id: 5,
            question: "How much does StudyBuddy cost?",
            answer: "We offer flexible pricing plans to suit different needs, ranging from a free tier to premium options. Check our pricing section for current rates. We also offer a free trial for new users on paid plans!"
        },
        {
            id: 6,
            question: "Can I cancel my subscription at any time?",
            answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
        },
        {
            id: 7,
            question: "Is there a refund policy?",
            answer: "We offer a 30-day money-back guarantee for all paid plans. Refunds are dealt with on a case-by-case basis. Please reach out to our support team for assistance."
        },
        {
            id: 8,
            question: "Can I upgrade or downgrade my plan?",
            answer: "Yes, you can change your plan at any time. When upgrading, you'll have immediate access to new features. When downgrading, changes will take effect at the start of your next billing cycle."
        }
    ];

    useEffect(() => {
        if (tierName) {
            const tier = pricingTiers.find(t => t.name.toLowerCase() === tierName.toLowerCase());
            if (tier) {
                handleSubscription(tier.name.toLowerCase());
            }
        }
    }, [tierName]);

    return (
        <div className="pricing-container">
            <Helmet>
                <title>StudyBuddy Pricing - Choose Your Study Plan</title>
                <meta name="description" content="Explore StudyBuddy's flexible pricing plans. From free basic tutoring to unlimited AI assistance, find the perfect plan for your academic needs." />
                <meta name="keywords" content="StudyBuddy pricing, AI tutor plans, academic assistance pricing, student tutoring costs" />
                <link rel="canonical" href="https://studdybuddy.ca/pricing" />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div className="pricing-hero">
                <h1>Upgrade Your Learning Experience</h1>
                <p>Unlock advanced features and maximize your potential. Start with a 7-day free trial.</p>
                
                <div className="pricing-toggle">
                    <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                    <div className="toggle-switch">
                        <input
                            type="checkbox"
                            id="billing-toggle"
                            checked={billingCycle === 'annual'}
                            onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
                        />
                        <label htmlFor="billing-toggle"></label>
                    </div>
                    <span className={billingCycle === 'annual' ? 'active' : ''}>
                        Annual
                        <span className="save-badge">
                            {window.innerWidth < 600 ? '30%' : 'Save 30%'}
                        </span>
                    </span>
                </div>
            </div>

            <div className="pricing-cards">
                {pricingTiers.filter(tier => tier.name !== 'Free').map((tier) => (
                    <div 
                        key={tier.name} 
                        className={`pricing-card ${tier.highlight ? 'highlight' : ''}`}
                    >
                        {tier.highlight && <div className="popular-badge">Most Popular</div>}
                        <div className="card-header">
                            <h3>{tier.name}</h3>
                            <p className="tagline">{tier.tagline}</p>
                            <div className="price-container">
                                {tier.name !== 'Free' ? (
                                    <>
                                        <span className="price">
                                            {tier.price[currency][billingCycle]}
                                        </span>
                                        <span className="period">
                                            /{billingCycle === 'monthly' ? 'mo' : 'yr'}
                                        </span>
                                    </>
                                ) : (
                                    <span className="price">Free</span>
                                )}
                            </div>
                        </div>

                        <div className="features">
                            {tier.features.map((feature, index) => (
                                <div key={index} className="feature">
                                    <FaCheck /> {feature}
                                </div>
                            ))}
                        </div>

                        {renderButton(tier)}
                    </div>
                ))}
            </div>

            <div className="free-tier-footer">
                <p>Not ready to upgrade? Try our <Link to="/signup">free plan</Link> to get started with basic features.</p>
            </div>

            <div className="pricing-faq">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-grid">
                    {faqs.map((faq) => (
                        <button 
                            key={faq.id} 
                            className={`faq-item ${activeFaqId === faq.id ? 'active' : ''}`}
                            onClick={() => toggleFaq(faq.id)}
                        >
                            <div className="faq-question">
                                <span>{faq.question}</span>
                                <FaArrowRight className="arrow" />
                            </div>
                            <div className="faq-answer">{faq.answer}</div>
                        </button>
                    ))}
                </div>
            </div>

            <div className="free-tier-footer">
                <p>Have more questions? Visit our <Link to="https://cognora.ca/faq">FAQ page</Link> for help.</p>
            </div>
        </div>
    );
};

export default Pricing;
