/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { updateProfile, deleteAccount, handleStripePortalRedirect } from '../services/api';
import '../Styles/Settings.css';
import '../Styles/LoadingOverlay.css';
import useDocumentTitle from '../UseDocumentTitle';
import { FaUser, FaCreditCard, FaShieldAlt, FaPalette, FaGlobe, FaTrash, FaToggleOn, FaToggleOff, FaSpinner, FaCheck } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import ChangePassword from './Auth/ChangePassword';
import Modal from './Partials/Modal'; // You'll need to create this component
import { MessageSquare, FileText, BookOpen, Star, ChevronLeft } from 'lucide-react';

const Settings = () => {
  useDocumentTitle('Settings - StudyBuddy');
  const navigate = useNavigate();
  const { user, logout, checkUser } = useAuth();
  const [activeSection, setActiveSection] = useState('profile');
  const [settings, setSettings] = useState({
    twoFactorEnabled: false,
    fontSize: 'medium',
    reduceMotion: false,
    timeZone: 'UTC'
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [profileData, setProfileData] = useState({
    name: user?.name || '',
    phone_number: user?.phone_number || '',
  });
  const [defaultChatModel, setDefaultChatModel] = useState(() => {
    return localStorage.getItem('defaultChatModel') || 'qa';
  });
  const [fontSize, setFontSize] = useState(() => {
    return localStorage.getItem('fontSize') || 'medium';
  });

  useEffect(() => {
    setProfileData({
      name: user?.name || '',
      phone_number: user?.phone_number || '',
    });
    setIsLoading(false);
  }, [user]);

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleSettingChange = (key, value) => {
    setSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      await updateProfile(profileData);
      setSuccessMessage('Profile updated successfully');
      // Update the user context if necessary
      // For example: updateUser({ ...user, name: profileData.name, phone_number: profileData.phone_number });
    } catch (error) {
      setError('Failed to update profile: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      setIsLoading(true);
      try {
        await deleteAccount();
        logout();
        window.location.href = '/';
      } catch (error) {
        setError('Failed to delete account: ' + (error.response?.data?.message || error.message));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDefaultChatModelChange = (e) => {
    const newModel = e.target.value;
    setDefaultChatModel(newModel);
    localStorage.setItem('defaultChatModel', newModel);
  };

  const handleFontSizeChange = (e) => {
    const newSize = e.target.value;
    setFontSize(newSize);
    localStorage.setItem('fontSize', newSize);
    document.documentElement.style.fontSize = getFontSizeValue(newSize);
  };

  const getFontSizeValue = (size) => {
    switch (size) {
      case 'x-small':
        return '12px';
      case 'small':
        return '14px';
      case 'medium':
        return '16px';
      case 'large':
        return '18px';
      case 'x-large':
        return '20px';
      default:
        return '16px'; // medium
    }
  };

  useEffect(() => {
    document.documentElement.style.fontSize = getFontSizeValue(fontSize);
  }, [fontSize]);

  const renderProfileSection = () => (
    <div className="settings-section">
      <h2>Profile Information</h2>
      <form onSubmit={handleProfileSubmit} className="settings-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={profileData.name}
            onChange={handleProfileChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email (cannot be changed)</label>
          <input
            type="email"
            id="email"
            name="email"
            value={user?.email || ''}
            disabled
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone_number">Phone Number</label>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            value={profileData.phone_number}
            onChange={handleProfileChange}
          />
        </div>
        <button type="submit" className="submit-button">Update Profile</button>
      </form>
    </div>
  );

  const renderAccountSection = () => (
    <div className="settings-section">
      {user?.tier === 'free' ? (
        <div className="subscription-promo">
          <div className="promo-title">
            <h3>Upgrade to Premium</h3>
            <p>Unlock all features and boost your learning</p>
          </div>
          <ul className="feature-list">
            <div className="feature-list-items">
              <li className="premium-feature">
                Unlimited AI chats
              </li>
              <li className="premium-feature">
                Advanced PDF analysis
              </li>
              <li className="premium-feature">
                Comprehensive study tools
              </li>
              <li className="premium-feature">
                Priority support
              </li>
              <li className="premium-feature">
                Early access to beta features
              </li>
            </div>
          </ul>
          <Link to="/pricing" className="upgrade-button">
            View Plans →
          </Link>
        </div>
      ) : (
        <div className="subscription-info">
          <p><strong>Current Plan:</strong> {user?.tier?.toUpperCase() || 'N/A'}</p>
          <div className="subscription-actions">
            <button onClick={handleStripePortalRedirect} className="submit-button">
              Manage Subscription
            </button>
            <Link to="/pricing" className="subscription-details-link">
              View pricing plans and subscription features
            </Link>
          </div>
        </div>
      )}
      <div className="security-settings">
        <h3>Security Settings</h3>
        <div className="settings-row">
          <span>Change your account password</span>
          <button 
            onClick={() => setShowChangePassword(true)} 
            className="submit-button"
          >
            Change Password
          </button>
        </div>
      </div>
      <div className="delete-account-section">
        <h3>Delete Account</h3>
        <div className="settings-row">
          <div>
            <p>Warning: This action cannot be undone. All your data will be permanently deleted.</p>
          </div>
          <button onClick={handleDeleteAccount} className="delete-account-button">
            Delete My Account
          </button>
        </div>
      </div>
    </div>
  );

  const renderPreferencesSection = () => (
    <div className="settings-section">
      <h2>Preferences</h2>
      <div className="form-group">
        <label htmlFor="fontSize">Font Size</label>
        <select 
          id="fontSize" 
          value={fontSize}
          onChange={handleFontSizeChange}
        >
          <option value="x-small">Extra Small</option>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
          <option value="x-large">Extra Large</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="defaultChatModel">Default Chat Mode</label>
        <select 
          id="defaultChatModel" 
          value={defaultChatModel}
          onChange={handleDefaultChatModelChange}
        >
          <option value="qa">Q&A</option>
          <option value="tutor">Tutor</option>
          <option value="brainstorm">Brainstorm</option>
          <option value="debate">Debate</option>
        </select>
      </div>
    </div>
  );

  const renderDeleteAccountSection = () => (
    <div className="settings-section">
      <h2>Delete Account</h2>
      <p>Warning: This action cannot be undone. All your data will be permanently deleted.</p>
      <button onClick={handleDeleteAccount} className="delete-account-button">
        Delete My Account
      </button>
    </div>
  );

  const sections = [
    { id: 'profile', icon: FaUser, label: 'Profile', render: renderProfileSection },
    { id: 'account', icon: FaCreditCard, label: 'Account', render: renderAccountSection },
    { id: 'preferences', icon: FaPalette, label: 'Preferences', render: renderPreferencesSection },
  ];

  const renderLoadingOverlay = () => (
    <div className="loading-overlay">
      <div className="loading-content">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );

  const renderReturnToChatButton = () => (
    <Link to="/" className="return-to-chat-button">
      <ChevronLeft size={24} />
      <span>Return to Chats</span>
    </Link>
  );

  return (
    <div className="settings-container">
      <header className="settings-header">
        {renderReturnToChatButton()}
        <h1>Settings</h1>
      </header>
      <div className="settings-content">
        <nav className="settings-nav">
          {sections.map((section) => (
            <button
              key={section.id}
              className={`nav-item ${activeSection === section.id ? 'active' : ''}`}
              onClick={() => setActiveSection(section.id)}
            >
              <section.icon className="icon" />
              <span>{section.label}</span>
            </button>
          ))}
        </nav>
        <main className="settings-main">
          {isLoading ? renderLoadingOverlay() : (
            sections.find(section => section.id === activeSection)?.render()
          )}
        </main>
      </div>
      {showChangePassword && (
        <Modal onClose={() => setShowChangePassword(false)}>
          <ChangePassword onClose={() => setShowChangePassword(false)} />
        </Modal>
      )}
    </div>
  );
};

export default Settings;
