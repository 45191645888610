import React from 'react';
import { Box, Typography } from '@mui/material';

const ScreenSizeWarning = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        textAlign: 'center',
        backgroundColor: '#f5f5f5'
      }}
    >
      <Typography 
        variant="h4" 
        component="h1" 
        gutterBottom
        sx={{
          fontWeight: 600,
          color: '#FF6B6B',
          marginBottom: '1rem',
          textShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}
      >
        Screen Size Too Small
      </Typography>
    </Box>
  );
};

export default ScreenSizeWarning;
