import { FaLaptopCode, FaCalculator, FaAtom, FaFlask, FaDna, FaBook, FaFeatherAlt, FaBrain, FaPalette, FaMusic, FaChartLine, FaGlobe, FaLeaf, FaRocket, FaMountain, FaLanguage, FaUsers, FaPray, FaAppleAlt, FaFilm, FaBuilding } from 'react-icons/fa';

export const categorizedSuggestions = [
  {
    category: "Computer Science & Programming",
    icon: FaLaptopCode,
    suggestions: [
      "What's the difference between a compiler and an interpreter?",
      "Can you explain the concept of recursion in programming?",
      "How does machine learning work?",
      "What are the main principles of object-oriented programming?",
      "Explain the difference between HTTP and HTTPS.",
      "What is the purpose of version control systems like Git?",
      "How does blockchain technology work?",
      "What are the key features of Python programming language?",
      "Explain the concept of Big O notation in algorithm complexity.",
      "What is the difference between SQL and NoSQL databases?",
      "How does a neural network function?",
      "What is the difference between deep learning and machine learning?",
      "Explain the concept of functional programming.",
      "What are the main features of JavaScript?",
      "How does garbage collection work in programming languages?",
      "What is the difference between stack and heap memory?",
      "Explain the concept of multithreading in programming.",
      "What is the purpose of an API?",
      "How does public key cryptography work?",
      "What is the difference between TCP and UDP protocols?",
    ]
  },
  {
    category: "Mathematics",
    icon: FaCalculator,
    suggestions: [
      "What is the Pythagorean theorem and how is it used?",
      "Can you explain the concept of logarithms?",
      "What is the difference between permutations and combinations?",
      "How do you solve a system of linear equations?",
      "What is the fundamental theorem of calculus?",
      "Explain the concept of complex numbers.",
      "What is the binomial theorem?",
      "How do you find the derivative of a function?",
      "What is the difference between mean, median, and mode?",
      "Explain the concept of probability distributions.",
      "What is the purpose of matrix multiplication?",
      "How do you solve a quadratic equation?",
      "What is the difference between a function and a relation?",
      "Explain the concept of limits in calculus.",
      "What is the purpose of imaginary numbers?",
      "How do you calculate the area under a curve?",
      "What is the difference between correlation and causation?",
      "Explain the concept of vector spaces.",
      "What is the central limit theorem?",
      "How do you prove a mathematical statement by induction?",
    ]
  },
  {
    category: "Physics",
    icon: FaAtom,
    suggestions: [
      "What is Newton's first law of motion?",
      "Can you explain the concept of gravity?",
      "What is the difference between potential and kinetic energy?",
      "How does a nuclear reactor work?",
      "What is the theory of relativity?",
      "Explain the concept of quantum entanglement.",
      "What is the difference between classical and quantum physics?",
      "How does a laser work?",
      "What is the Doppler effect?",
      "Explain the concept of wave-particle duality.",
      "What is the purpose of the Large Hadron Collider?",
      "How does a black hole form?",
      "What is the difference between fission and fusion?",
      "Explain the concept of dark matter and dark energy.",
      "What is the second law of thermodynamics?",
      "How does an electric motor work?",
      "What is the difference between AC and DC current?",
      "Explain the concept of supersymmetry.",
      "What is the Heisenberg uncertainty principle?",
      "How does a transistor function?",
    ]
  },
  {
    category: "Chemistry",
    icon: FaFlask,
    suggestions: [
      "What is the periodic table and how is it organized?",
      "Can you explain the concept of chemical bonding?",
      "What is the difference between an acid and a base?",
      "How does electrolysis work?",
      "What is the difference between organic and inorganic chemistry?",
      "Explain the concept of redox reactions.",
      "What is the difference between a mixture and a compound?",
      "How does a catalytic converter work?",
      "What is the difference between an exothermic and endothermic reaction?",
      "Explain the concept of molecular orbital theory.",
      "What is the purpose of a buffer solution?",
      "How does chromatography work?",
      "What is the difference between a covalent and ionic bond?",
      "Explain the concept of chirality in organic molecules.",
      "What is the purpose of the Haber process?",
      "How does a fuel cell work?",
      "What is the difference between a strong and weak electrolyte?",
      "Explain the concept of resonance in organic chemistry.",
      "What is the difference between a Lewis acid and a Brønsted-Lowry acid?",
      "How does nuclear magnetic resonance (NMR) spectroscopy work?",
    ]
  },
  {
    category: "Biology",
    icon: FaDna,
    suggestions: [
      "What is the central dogma of molecular biology?",
      "Can you explain the process of photosynthesis?",
      "What is the difference between mitosis and meiosis?",
      "How does the human immune system work?",
      "What is the theory of evolution by natural selection?",
      "Explain the concept of genetic inheritance.",
      "What is the difference between prokaryotic and eukaryotic cells?",
      "How does the human digestive system function?",
      "What is the purpose of the Krebs cycle?",
      "Explain the concept of homeostasis.",
      "What is the difference between DNA and RNA?",
      "How does a vaccine work?",
      "What is the purpose of the endocrine system?",
      "Explain the concept of biodiversity and its importance.",
      "What is the difference between aerobic and anaerobic respiration?",
      "How does the human nervous system function?",
      "What is the purpose of the lymphatic system?",
      "Explain the concept of epigenetics.",
      "What is the difference between a virus and a bacterium?",
      "How does gene editing technology like CRISPR work?",
    ]
  },
  {
    category: "History",
    icon: FaBook,
    suggestions: [
      "What were the main causes of World War I?",
      "Can you explain the significance of the Industrial Revolution?",
      "What was the Renaissance and how did it impact European society?",
      "How did the Cold War shape global politics in the 20th century?",
      "What were the key events of the American Civil Rights Movement?",
      "Explain the fall of the Roman Empire.",
      "What was the impact of the French Revolution?",
      "How did the Mongol Empire influence world history?",
      "What were the main achievements of ancient Greek civilization?",
      "Explain the significance of the Scientific Revolution.",
      "What was the impact of colonialism on Africa?",
      "How did the Ottoman Empire influence European history?",
      "What were the key events of the Cuban Missile Crisis?",
      "Explain the causes and consequences of the Russian Revolution.",
      "What was the significance of the Magna Carta?",
      "How did the Black Death affect medieval Europe?",
      "What were the main features of the Enlightenment?",
      "Explain the impact of the Vietnam War on American society.",
      "What was the significance of the Berlin Wall?",
      "How did the Islamic Golden Age contribute to world knowledge?",
    ]
  },
  {
    category: "Literature",
    icon: FaFeatherAlt,
    suggestions: [
      "What are the main themes in Shakespeare's 'Hamlet'?",
      "Can you explain the concept of magical realism in literature?",
      "What is the significance of George Orwell's '1984' in modern society?",
      "How did the Romantic movement influence literature?",
      "What are the key features of modernist literature?",
      "Explain the importance of the Harlem Renaissance in American literature.",
      "What is the difference between a metaphor and a simile?",
      "How does stream of consciousness narration work?",
      "What are the main characteristics of Gothic literature?",
      "Explain the concept of the unreliable narrator.",
      "What is the significance of James Joyce's 'Ulysses'?",
      "How does feminist literary criticism approach texts?",
      "What are the key features of postmodern literature?",
      "Explain the concept of the hero's journey in storytelling.",
      "What is the difference between tragedy and comedy in drama?",
      "How does symbolism function in literature?",
      "What is the significance of the Beat Generation in American literature?",
      "Explain the concept of intertextuality.",
      "What are the main characteristics of epic poetry?",
      "How does postcolonial literature address issues of identity and culture?",
    ]
  },
  {
    category: "Philosophy",
    icon: FaBrain,
    suggestions: [
      "What is the concept of existentialism?",
      "Can you explain Plato's Theory of Forms?",
      "What is the difference between deontological and consequentialist ethics?",
      "How does Descartes' 'Cogito, ergo sum' relate to skepticism?",
      "What is the concept of the social contract in political philosophy?",
      "Explain Kant's categorical imperative.",
      "What is the mind-body problem in philosophy of mind?",
      "How does utilitarianism approach ethical decision-making?",
      "What is the concept of 'the absurd' in existentialist philosophy?",
      "Explain Aristotle's concept of eudaimonia.",
      "What is the difference between empiricism and rationalism?",
      "How does Nietzsche's concept of the 'will to power' function?",
      "What is the trolley problem and what ethical issues does it raise?",
      "Explain Sartre's concept of 'bad faith'.",
      "What is the difference between analytic and continental philosophy?",
      "How does Hegel's dialectical method work?",
      "What is the concept of 'the other' in phenomenology?",
      "Explain John Rawls' 'veil of ignorance' thought experiment.",
      "What is the problem of evil in philosophy of religion?",
      "How does Foucault's concept of power/knowledge function?",
    ]
  },
  {
    category: "Psychology",
    icon: FaBrain,
    suggestions: [
      "What is the difference between classical and operant conditioning?",
      "Can you explain Maslow's hierarchy of needs?",
      "What is cognitive dissonance and how does it affect behavior?",
      "How does the nature vs. nurture debate relate to human development?",
      "What are the main stages of Piaget's theory of cognitive development?",
      "Explain the concept of confirmation bias.",
      "What is the difference between short-term and long-term memory?",
      "How does the placebo effect work?",
      "What are the key features of Freudian psychoanalysis?",
      "Explain the concept of emotional intelligence.",
      "What is the difference between intrinsic and extrinsic motivation?",
      "How does social identity theory explain group behavior?",
      "What are the main symptoms of depression and anxiety?",
      "Explain the concept of neuroplasticity.",
      "What is the difference between IQ and EQ?",
      "How does attribution theory explain how people interpret events?",
      "What are the key features of cognitive-behavioral therapy?",
      "Explain the concept of the unconscious mind.",
      "What is the difference between positive and negative reinforcement?",
      "How does the bystander effect influence helping behavior?",
    ]
  },
  {
    category: "Art & Art History",
    icon: FaPalette,
    suggestions: [
      "What are the main characteristics of Renaissance art?",
      "Can you explain the concept of perspective in painting?",
      "What is the difference between Impressionism and Expressionism?",
      "How did the Bauhaus movement influence modern design?",
      "What are the key features of Cubism?",
      "Explain the significance of Jackson Pollock's drip painting technique.",
      "What is the difference between sculpture in the round and relief sculpture?",
      "How does pop art comment on consumer culture?",
      "What are the main characteristics of Gothic architecture?",
      "Explain the concept of chiaroscuro in painting.",
      "What is the significance of Marcel Duchamp's 'Fountain'?",
      "How does street art challenge traditional notions of art?",
      "What are the key features of Art Nouveau?",
      "Explain the concept of site-specific art.",
      "What is the difference between abstract and representational art?",
      "How did the invention of photography influence painting?",
      "What are the main characteristics of Surrealism?",
      "Explain the concept of appropriation in contemporary art.",
      "What is the significance of the Lascaux cave paintings?",
      "How does installation art engage with space and audience?",
    ]
  },
  {
    category: "Music Theory & History",
    icon: FaMusic,
    suggestions: [
      "What is the circle of fifths and how is it used?",
      "Can you explain the concept of counterpoint in music?",
      "What is the difference between major and minor scales?",
      "How does a fugue work?",
      "What are the main characteristics of Baroque music?",
      "Explain the concept of syncopation in rhythm.",
      "What is the difference between a symphony and a concerto?",
      "How does serialism work in music composition?",
      "What are the main features of jazz improvisation?",
      "Explain the concept of modulation in music.",
      "What is the significance of John Cage's '4'33",
      "How does electronic music production differ from traditional composition?",
      "What are the key elements of sonata form?",
      "Explain the concept of timbre in music.",
      "What is the difference between diatonic and chromatic harmony?",
      "How did the invention of the piano forte influence music composition?",
      "What are the main characteristics of minimalism in music?",
      "Explain the concept of polyrhythm.",
      "What is the significance of Gregorian chant in Western music history?",
      "How does music notation work?",
    ]
  },
  {
    category: "Economics",
    icon: FaChartLine,
    suggestions: [
      "What is the law of supply and demand?",
      "Can you explain the concept of opportunity cost?",
      "What is the difference between microeconomics and macroeconomics?",
      "How does inflation affect an economy?",
      "What are the main types of market structures?",
      "Explain the concept of comparative advantage in international trade.",
      "What is the difference between fiscal and monetary policy?",
      "How does the stock market work?",
      "What are the main causes of economic recessions?",
      "Explain the concept of elasticity in economics.",
      "What is the purpose of central banks?",
      "How does game theory apply to economic decision-making?",
      "What is the difference between GDP and GNP?",
      "Explain the concept of externalities in economics.",
      "What is the difference between a progressive and regressive tax system?",
      "How does the multiplier effect work in economics?",
      "What are the main arguments for and against free trade?",
      "Explain the concept of moral hazard in economics.",
      "What is the difference between nominal and real interest rates?",
      "How does behavioral economics challenge traditional economic theory?",
    ]
  },
  {
    category: "Political Science",
    icon: FaGlobe,
    suggestions: [
      "What is the difference between a democracy and a republic?",
      "Can you explain the concept of separation of powers?",
      "What are the main features of a federal system of government?",
      "How does proportional representation differ from first-past-the-post voting?",
      "What are the key principles of liberalism?",
      "Explain the concept of soft power in international relations.",
      "What is the difference between realism and idealism in foreign policy?",
      "How does the United Nations Security Council function?",
      "What are the main characteristics of authoritarianism?",
      "Explain the concept of civil disobedience.",
      "What is the difference between a parliamentary and presidential system?",
      "How does lobbying influence the political process?",
      "What are the main features of socialism?",
      "Explain the concept of checks and balances in government.",
      "What is the difference between a unitary and federal state?",
      "How does gerrymandering affect political representation?",
      "What are the main theories of international relations?",
      "Explain the concept of public opinion and its role in democracy.",
      "What is the difference between hard and soft power in geopolitics?",
      "How does the European Union function as a supranational organization?",
    ]
  },
  {
    category: "Environmental Science",
    icon: FaLeaf,
    suggestions: [
      "What is the greenhouse effect and how does it relate to climate change?",
      "Can you explain the concept of biodiversity and why it's important?",
      "What is the difference between renewable and non-renewable resources?",
      "How does deforestation impact the environment?",
      "What are the main causes of air pollution?",
      "Explain the concept of ecosystem services.",
      "What is the difference between weather and climate?",
      "How does ocean acidification affect marine ecosystems?",
      "What are the main sources of water pollution?",
      "Explain the concept of sustainable development.",
      "What is the ozone layer and why is it important?",
      "How do invasive species impact local ecosystems?",
      "What are the main challenges in waste management?",
      "Explain the concept of carbon footprint.",
      "What is the difference between organic and conventional farming?",
      "How does urbanization affect the environment?",
      "What are the main environmental impacts of fossil fuel use?",
      "Explain the concept of environmental justice.",
      "What is the importance of wetlands in ecosystems?",
      "How do plastic pollution affect marine life?",
    ]
  },
  {
    category: "Astronomy",
    icon: FaRocket,
    suggestions: [
      "What is the Big Bang theory?",
      "Can you explain the life cycle of a star?",
      "What is the difference between a planet and a dwarf planet?",
      "How do black holes form and function?",
      "What are the main components of the Solar System?",
      "Explain the concept of dark matter and dark energy.",
      "What is the difference between a galaxy and a nebula?",
      "How do astronomers measure distances in space?",
      "What are exoplanets and how are they detected?",
      "Explain the concept of redshift in astronomy.",
      "What is the cosmic microwave background radiation?",
      "How do telescopes work and what are their different types?",
      "What are the phases of the Moon and what causes them?",
      "Explain the concept of stellar parallax.",
      "What is the difference between asteroids, comets, and meteors?",
      "How does gravity affect the orbits of celestial bodies?",
      "What are the main theories about the origin of the Moon?",
      "Explain the concept of the habitable zone around stars.",
      "What is the significance of the discovery of gravitational waves?",
      "How do scientists search for signs of extraterrestrial life?",
    ]
  },
  {
    category: "Geology",
    icon: FaMountain,
    suggestions: [
      "What is plate tectonics and how does it shape the Earth's surface?",
      "Can you explain the rock cycle?",
      "What is the difference between igneous, sedimentary, and metamorphic rocks?",
      "How do earthquakes occur and how are they measured?",
      "What are the main types of volcanoes and how do they form?",
      "Explain the concept of geological time scales.",
      "What is the difference between weathering and erosion?",
      "How do glaciers shape the landscape?",
      "What are the main components of soil and how does it form?",
      "Explain the concept of continental drift.",
      "What is the water cycle and how does it function?",
      "How do geologists use fossils to understand Earth's history?",
      "What are the main theories about the formation of the Earth?",
      "Explain the concept of isostasy in geology.",
      "What is the difference between absolute and relative dating methods?",
      "How do mineral deposits form and how are they extracted?",
      "What are the main causes of landslides and how can they be prevented?",
      "Explain the concept of geothermal energy.",
      "What is the significance of the Earth's magnetic field?",
      "How do scientists study the Earth's interior structure?",
    ]
  },
  {
    category: "Linguistics",
    icon: FaLanguage,
    suggestions: [
      "What is the difference between phonetics and phonology?",
      "Can you explain the concept of syntax in language?",
      "What is the Sapir-Whorf hypothesis?",
      "How do children acquire language?",
      "What are the main language families in the world?",
      "Explain the concept of linguistic relativity.",
      "What is the difference between descriptive and prescriptive grammar?",
      "How does code-switching function in bilingual communities?",
      "What are the main features of sign languages?",
      "Explain the concept of morphology in linguistics.",
      "What is the difference between synchronic and diachronic linguistics?",
      "How do pidgins and creoles develop?",
      "What are the main theories of language origin?",
      "Explain the concept of pragmatics in linguistics.",
      "What is the difference between first and second language acquisition?",
      "How does language change over time?",
      "What are the main approaches to translation theory?",
      "Explain the concept of linguistic universals.",
      "What is the role of corpus linguistics in language study?",
      "How does sociolinguistics study the relationship between language and society?",
    ]
  },
  {
    category: "Anthropology",
    icon: FaUsers,
    suggestions: [
      "What is the difference between physical and cultural anthropology?",
      "Can you explain the concept of cultural relativism?",
      "What is ethnocentrism and how does it affect cross-cultural understanding?",
      "How do anthropologists use participant observation in their research?",
      "What are the main theories of human evolution?",
      "Explain the concept of kinship systems in different cultures.",
      "What is the difference between emic and etic approaches in anthropology?",
      "How do rituals function in societies?",
      "What are the main features of hunter-gatherer societies?",
      "Explain the concept of cultural diffusion.",
      "What is the significance of material culture in anthropological studies?",
      "How do anthropologists study gender roles across cultures?",
      "What are the main debates surrounding the concept of race in anthropology?",
      "Explain the concept of ethnography.",
      "What is the difference between high context and low context cultures?",
      "How do anthropologists study religious beliefs and practices?",
      "What are the main theories about the origins of agriculture?",
      "Explain the concept of cultural adaptation.",
      "What is the role of language in shaping culture?",
      "How do anthropologists study globalization and its effects on local cultures?",
    ]
  },
  {
    category: "World Religions",
    icon: FaPray,
    suggestions: [
      "What are the Five Pillars of Islam?",
      "Can you explain the concept of karma in Hinduism and Buddhism?",
      "What is the difference between Theravada and Mahayana Buddhism?",
      "How does the concept of the Trinity function in Christianity?",
      "What are the main branches of Judaism?",
      "Explain the concept of Taoism's Yin and Yang.",
      "What is the role of the Guru in Sikhism?",
      "How do creation myths vary across different religions?",
      "What are the main features of Shinto beliefs?",
      "Explain the concept of reincarnation in various religions.",
      "What is the significance of the Vedas in Hinduism?",
      "How do different religions approach the concept of afterlife?",
      "What are the main differences between Sunni and Shia Islam?",
      "Explain the concept of salvation in Christianity.",
      "What is the role of meditation in Buddhism?",
      "How do different religions approach the concept of free will?",
      "What are the main features of Zoroastrianism?",
      "Explain the concept of the Covenant in Judaism.",
      "What is the significance of pilgrimages in various religions?",
      "How do different religions approach environmental ethics?",
    ]
  },
  {
    category: "Nutrition and Diet",
    icon: FaAppleAlt,
    suggestions: [
      "What is the difference between macronutrients and micronutrients?",
      "Can you explain the concept of a balanced diet?",
      "What is the glycemic index and how does it affect blood sugar?",
      "How do different types of fats affect health?",
      "What are the main functions of vitamins and minerals in the body?",
      "Explain the concept of metabolism and how it relates to weight management.",
      "What is the difference between probiotics and prebiotics?",
      "How does fiber contribute to digestive health?",
      "What are the main principles of the Mediterranean diet?",
      "Explain the concept of nutrient density in foods.",
      "What is the role of antioxidants in the diet?",
      "How do plant-based diets compare to omnivorous diets in terms of nutrition?",
      "What are the main causes and effects of dehydration?",
      "Explain the concept of intermittent fasting.",
      "What is the difference between food allergies and food intolerances?",
      "How does alcohol consumption affect nutrition and health?",
      "What are the main nutritional concerns for vegetarian and vegan diets?",
      "Explain the concept of mindful eating.",
      "What is the role of omega-3 fatty acids in the diet?",
      "How do nutritional needs change throughout the lifespan?",
    ]
  },
  {
    category: "Film Studies",
    icon: FaFilm,
    suggestions: [
      "What are the main elements of mise-en-scène in film?",
      "Can you explain the concept of montage in film editing?",
      "What is the difference between diegetic and non-diegetic sound in film?",
      "How does the auteur theory approach film analysis?",
      "What are the main characteristics of film noir?",
      "Explain the concept of the male gaze in feminist film theory.",
      "What is the difference between realism and formalism in film?",
      "How do different aspect ratios affect film composition?",
      "What are the main features of neorealism in Italian cinema?",
      "Explain the concept of the unreliable narrator in film.",
      "What is the significance of the French New Wave in film history?",
      "How does genre theory categorize and analyze films?",
      "What are the main techniques used in documentary filmmaking?",
      "Explain the concept of the MacGuffin in Hitchcock's films.",
      "What is the difference between classical and continuity editing?",
      "How does color grading affect the mood and atmosphere of a film?",
      "What are the main characteristics of expressionist cinema?",
      "Explain the concept of the fourth wall in film.",
      "What is the role of sound design in creating cinematic atmosphere?",
      "How do different camera movements convey meaning in film?",
    ]
  },
  {
    category: "Architecture",
    icon: FaBuilding,
    suggestions: [
      "What are the main orders of classical architecture?",
      "Can you explain the concept of form follows function in modern architecture?",
      "What is the difference between load-bearing and skeletal construction?",
      "How does sustainable architecture address environmental concerns?",
      "What are the main characteristics of Gothic architecture?",
      "Explain the concept of spatial organization in architecture.",
      "What is the difference between Art Nouveau and Art Deco in architecture?",
      "How does urban planning influence architectural design?",
      "What are the main features of Brutalist architecture?",
      "Explain the concept of parametric design in architecture.",
      "What is the significance of the Bauhaus movement in modern architecture?",
      "How do architects use light and shadow to create atmosphere?",
      "What are the main principles of landscape architecture?",
      "Explain the concept of adaptive reuse in architecture.",
      "What is the difference between vernacular and high-style architecture?",
      "How does climate influence architectural design in different regions?",
      "What are the main features of deconstructivist architecture?",
      "Explain the concept of the golden ratio in architectural proportion.",
      "What is the role of technology in contemporary architecture?",
      "How do different cultures approach the design of sacred spaces?"
    ]
  }
];

export const allSuggestions = categorizedSuggestions.flatMap(category => category.suggestions);