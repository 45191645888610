/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Copy, Check } from 'lucide-react';
import { FaFilePdf, FaFileAlt, FaCode, FaChevronRight } from 'react-icons/fa';
// Import mhchem extension for KaTeX
import 'katex/contrib/mhchem/mhchem';

const Citation = ({ citations }) => (
  <span className="citations">
    {citations.map((citation, index) => (
      <React.Fragment key={index}>
        {index > 0 && '; '}
        <span className="citation">{citation}</span>
      </React.Fragment>
    ))}
  </span>
);

const preprocessChemistry = (content) => {
  // Replace <span class="inline-chemistry"> with \ce{...} directly
  return content.replace(/<span class="inline-chemistry">\\ce\{([^}]+)\}<\/span>/g, '\\ce{$1}');
};

const preprocessLatex = (content) => {
  // Wrap cases environment in proper math delimiters if not already present
  content = content.replace(
    /\\text\{.*?\}[\s\S]*?\\begin\{cases\}[\s\S]*?\\end\{cases\}/g,
    match => {
      // Only wrap in $$ if not already wrapped
      if (!match.startsWith('$$')) {
        return `$$${match}$$`;
      }
      return match;
    }
  );
  
  // Handle standalone cases environments
  content = content.replace(
    /\\begin\{cases\}([\s\S]*?)\\end\{cases\}/g,
    match => {
      if (!match.startsWith('$$')) {
        return `$$${match}$$`;
      }
      return match;
    }
  );

  return content;
};

// New preprocessing function to fix backslashes in LaTeX
const fixLaTeXBackslashes = (content) => {
  return content.replace(/\\\\/g, '\\');
};

export const MarkdownRenderer = ({ content, allowedElements, disallowedElements, inline }) => {
  const [processedContent, setProcessedContent] = useState(content);

  useEffect(() => {
    let newContent = processChemistryInLine(content);
    newContent = preprocessLatex(newContent);
    newContent = fixLaTeXBackslashes(newContent);
    setProcessedContent(newContent);
  }, [content]);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeRaw, [rehypeKatex, { output: 'htmlAndMathml' }]]}
      components={{
        // This will render disallowed elements as normal text
        ...(disallowedElements || []).reduce((acc, el) => ({
          ...acc,
          [el]: ({ node, ...props }) => <span {...props} />
        }), {}),
        // This will only allow rendering of allowed elements
        ...(allowedElements || []).reduce((acc, el) => ({
          ...acc,
          [el]: props => React.createElement(el, props)
        }), {})
      }}
    >
      {processedContent}
    </ReactMarkdown>
  );
};

const PhysicsRenderer = ({ content }) => {
  // Custom rendering logic for physics content
  return <div className="physics-content">{content}</div>;
};

const ChemistryRenderer = ({ content }) => {
  // Custom rendering logic for chemistry content
  return <div className="chemistry-content">{content}</div>;
};

const BiologyRenderer = ({ content }) => {
  // Parse the LaTeX-like content
  const title = content.match(/title=(.*?)\]/)?.[1] || 'Biology Content';
  const bodyContent = content.replace(/\\begin\{tcolorbox\}.*?\n([\s\S]*?)\\end\{tcolorbox\}/s, '$1');

  // Convert LaTeX-like formatting to JSX
  const formattedContent = bodyContent
    .replace(/\\textbf\{(.*?)\}/g, '<strong>$1</strong>')
    .replace(/\\xrightarrow\{(.*?)\}/g, ' → ')
    .replace(/\\\[(.*?)\\\]/g, '<MathRenderer math="$1" />')
    .replace(/\\\((.*?)\\\)/g, '<MathRenderer math="$1" inline />')
    .replace(/\$([^$]+)\$/g, '<MathRenderer math="$1" inline />')
    .replace(/•/g, '•')
    .split('\n\n');

  return (
    <div className="biology-content" style={{ border: '2px solid green', padding: '10px', borderRadius: '5px' }}>
      <h3 style={{ color: 'green' }}>{title}</h3>
      {formattedContent.map((paragraph, index) => (
        <p key={index}>
          <MarkdownRenderer content={paragraph} />
        </p>
      ))}
    </div>
  );
};

const processInlinePhysicsAndChemistry = (line) => {
  // Handle inline physics
  line = line.replace(/\$\$physics\$\$(.*?)\$\$endphysics\$\$/g, '<span class="inline-physics">$1</span>');
  
  // Handle inline chemistry
  line = line.replace(/\\ce\{([^}]+)\}/g, '<span class="inline-chemistry">\\(\\ce{$1}\\)</span>');
  
  return line;
};

const processChemistryInLine = (line) => {
  return line
    // Ensure \ce{...} has the backslash
    .replace(/<span class="inline-chemistry">\\?ce\{([^}]+)\}<\/span>/g, (match, p1) => {
      return `\\ce{${p1}}`;
    })
    // Wrap \ce{...} with inline math delimiters $...$
    .replace(/\\ce\{([^}]+)\}/g, '$\\ce{$1}$')
    // Remove trailing line breaks within inline math
    .replace(/\$\\ce\{([^}]+)\}\$\\+/g, '$\\ce{$1}$')
    .replace(/\$\\(\w+)\{([^}]+)\}\$\\+/g, '$\\$1{$2}$');
};

const parseResponse = (text) => {
  let globalOrderedListCount = 1; // Initialize global counter
  const lines = text.split('\n');
  const parsedContent = [];
  let currentList = null;
  let currentListType = null; // Tracks list type
  let currentListItem = null;
  let inCodeBlock = false;
  let currentCodeBlock = null;
  let codeBlockLanguage = '';
  let codeBlockContent = '';
  let inTable = false;
  let tableHeaders = [];
  let tableRows = [];
  let inMathBlock = false;
  let mathBlockContent = '';
  let inPhysicsBlock = false;
  let physicsBlockContent = '';
  let inChemistryBlock = false;
  let chemistryBlockContent = '';
  let inBiologyBlock = false;
  let biologyBlockContent = '';
  let inListItemParagraph = false; // Flag for list item paragraphs
  let listItemParagraphContent = ''; // Content for list item paragraphs

  // Remove generation stopped markers
  text = text.replace(/\[Generation Stopped\]/g, '');

  const processMathExpression = (line) => {
    // Preserve inline and display math
    return line;
  };

  const processInlineCode = (line) => {
    const parts = line.split('`');
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return { type: 'inline-code', content: part };
      }
      return { type: 'text', content: part };
    });
  };

  const processListItem = (line, listType) => {
    const trimmedLine = line.trim();
    let content = '';
    let number = null;

    if (listType === 'ordered') {
      const match = trimmedLine.match(/^(\d+)\.\s+(.*)/);
      number = parseInt(match[1], 10);
      content = match[2];
    } else {
      content = trimmedLine.replace(/^([•\-+])\s+/, '');
    }

    const parts = content.split(/(`[^`]+`)/);
    const processedParts = parts.map(part => {
      if (part.startsWith('`') && part.endsWith('`')) {
        return { type: 'inline-code', content: part.slice(1, -1) };
      }
      return { type: 'text', content: part };
    });

    const newItem = { content: processedParts, subitems: [], paragraphs: [], number };

    if (currentList && currentListType === listType) {
      currentList.items.push(newItem);
      currentListItem = newItem;
      inListItemParagraph = false;
    } else {
      finalizePreviousList();
      currentList = { 
        items: [newItem], 
        isOrdered: listType === 'ordered',
        start: listType === 'ordered' ? number : null 
      };
      currentListType = listType;
      parsedContent.push(currentList);
      currentListItem = newItem;
      inListItemParagraph = false;
    }
  };

  const finalizePreviousList = () => {
    if (currentList) {
      if (currentList.isOrdered) {
        globalOrderedListCount += currentList.items.length;
      }
      // Remove empty lists
      if (currentList.items.length === 0) {
        parsedContent.pop();
      }
      currentList = null;
      currentListItem = null;
      currentListType = null;
      inListItemParagraph = false;
      listItemParagraphContent = '';
    }
  };

  const finalizeListItemParagraph = () => {
    if (inListItemParagraph && currentListItem) {
      currentListItem.paragraphs.push(listItemParagraphContent.trim());
      listItemParagraphContent = '';
      inListItemParagraph = false;
    }
  };

  const cleanupSpaces = (content) => {
    return content.replace(/\s+([.,;:!?])/g, '$1');
  };

  const parseCodeBlockMetadata = (content) => {
    const titleMatch = content.match(/\[CODE_BLOCK_TITLE:\s*(.*?)\]/);
    const versionMatch = content.match(/\[VERSION:\s*(.*?)\]/);
    const previousVersionMatch = content.match(/\[PREVIOUS_VERSION:\s*(.*?)\]/);

    return {
      title: titleMatch ? titleMatch[1] : null,
      version: versionMatch ? versionMatch[1] : null,
      previousVersion: previousVersionMatch ? previousVersionMatch[1] : null,
    };
  };

  const parseEssayMetadata = (content) => {
    const titleMatch = content.match(/\[ESSAY_TITLE:\s*(.*?)\]/);
    const versionMatch = content.match(/\[VERSION:\s*(.*?)\]/);
    const previousVersionMatch = content.match(/\[PREVIOUS_VERSION:\s*(.*?)\]/);

    return {
      title: titleMatch ? titleMatch[1] : null,
      version: versionMatch ? versionMatch[1] : null,
      previousVersion: previousVersionMatch ? previousVersionMatch[1] : null,
    };
  };

  const processLine = (line, lineIndex) => {
    const trimmedLine = line.trim();

    // Handle quotes first
    const quoteMatch = line.match(/<quote>([\s\S]*?)<\/quote>/);
    if (quoteMatch) {
      finalizePreviousList();
      parsedContent.push({ 
        type: 'quote', 
        content: quoteMatch[1].trim() 
      });
      return;
    }

    if (inMathBlock) {
      if (trimmedLine === '\\]') {
        parsedContent.push({ type: 'math-block', content: mathBlockContent.trim() });
        inMathBlock = false;
        mathBlockContent = '';
      } else {
        mathBlockContent += line + '\n';
      }
      return;
    }

    if (trimmedLine === '\\[') {
      inMathBlock = true;
      return;
    }

    if (trimmedLine.startsWith('```')) {
      if (inCodeBlock) {
        if (codeBlockLanguage === 'essay') {
          const metadata = parseEssayMetadata(codeBlockContent);
          parsedContent.push({
            type: 'essay',
            content: codeBlockContent.replace(/\[.*?\]\n/g, '').trim(),
            title: metadata.title,
            version: metadata.version,
            previousVersion: metadata.previousVersion,
          });
        } else {
          const metadata = parseCodeBlockMetadata(codeBlockContent);
          parsedContent.push({
            type: 'code-block',
            content: codeBlockContent.replace(/\[.*?\]\n/g, '').trim(),
            language: codeBlockLanguage,
            title: metadata.title,
            version: metadata.version,
            previousVersion: metadata.previousVersion,
          });
        }
        inCodeBlock = false;
        codeBlockContent = '';
        codeBlockLanguage = '';
      } else {
        inCodeBlock = true;
        codeBlockLanguage = trimmedLine.slice(3).trim() || 'plaintext';
      }
      return;
    } else if (line.startsWith('<code language="')) {
      const languageMatch = line.match(/<code language="(\w+)">/);
      if (languageMatch) {
        inCodeBlock = true;
        currentCodeBlock = { language: languageMatch[1], content: '' };
      }
      return;
    } else if ((line.startsWith('</code>') || trimmedLine === '```') && inCodeBlock) {
      if (currentCodeBlock) {
        parsedContent.push({ type: 'code-block', ...currentCodeBlock });
        currentCodeBlock = null;
      }
      inCodeBlock = false;
      return;
    } else if (inCodeBlock) {
      if (currentCodeBlock) {
        currentCodeBlock.content += line + '\n';
      } else {
        codeBlockContent += line + '\n';
      }
      return;
    }

    // Check for ordered list item
    const orderedListMatch = trimmedLine.match(/^(\d+)\.\s+(.*)/);
    if (orderedListMatch) {
      processListItem(line, 'ordered');
      return;
    }

    // Check for unordered list item
    const unorderedListMatch = trimmedLine.match(/^([•\-+])\s+(.*)/);
    if (unorderedListMatch) {
      processListItem(line, 'unordered');
      return;
    }

    // Handle other content types
    finalizePreviousList();
    finalizeListItemParagraph();

    // Handle headers using Markdown syntax
    const headerMatch = trimmedLine.match(/^(#{1,6})\s+(.*)/);
    if (headerMatch) {
      const level = headerMatch[1].length;
      const headerContent = headerMatch[2].trim();
      parsedContent.push({ type: 'heading', level, content: headerContent });
      return;
    }

    // Handle tables
    if (trimmedLine.startsWith('|') && trimmedLine.endsWith('|')) {
      if (!inTable) {
        inTable = true;
        tableHeaders = trimmedLine.split('|').slice(1, -1).map(header => header.trim());
      } else if (trimmedLine.includes('---')) {
        // Separator line, skip
      } else {
        tableRows.push(trimmedLine.split('|').slice(1, -1).map(cell => cell.trim()));
      }
      return;
    } else if (inTable) {
      // End of table
      parsedContent.push({ type: 'table', headers: tableHeaders, rows: tableRows });
      inTable = false;
      tableHeaders = [];
      tableRows = [];
    }

    // Handle physics and chemistry blocks
    if (trimmedLine.startsWith('$$physics$$')) {
      inPhysicsBlock = true;
      physicsBlockContent = '';
      return;
    } else if (inPhysicsBlock && trimmedLine === '$$endphysics$$') {
      parsedContent.push({ type: 'physics-block', content: physicsBlockContent.trim() });
      inPhysicsBlock = false;
      physicsBlockContent = '';
      return;
    } else if (inPhysicsBlock) {
      physicsBlockContent += line + '\n';
      return;
    }

    if (trimmedLine.startsWith('$$chemistry$$')) {
      inChemistryBlock = true;
      chemistryBlockContent = '';
      return;
    } else if (inChemistryBlock && trimmedLine === '$$endchemistry$$') {
      parsedContent.push({ type: 'chemistry-block', content: chemistryBlockContent.trim() });
      inChemistryBlock = false;
      chemistryBlockContent = '';
      return;
    } else if (inChemistryBlock) {
      chemistryBlockContent += line + '\n';
      return;
    }

    // Handle biology blocks
    if (trimmedLine.startsWith('Light-Independent Reactions') || inBiologyBlock) {
      if (!inBiologyBlock) {
        inBiologyBlock = true;
        biologyBlockContent = '\\begin{tcolorbox}[colback=green!5!white,colframe=green!75!black,title=Biology Content]\n';
      }
      biologyBlockContent += line + '\n';
      if (lineIndex === lines.length - 1 || lines[lineIndex + 1].trim() === '') {
        biologyBlockContent += '\\end{tcolorbox}';
        parsedContent.push({ type: 'biology-block', content: biologyBlockContent.trim() });
        inBiologyBlock = false;
        biologyBlockContent = '';
      }
      return;
    }

    // Handle horizontal rules
    if (trimmedLine === '---') {
      finalizePreviousList();
      parsedContent.push({ type: 'horizontal-rule' });
      return;
    }

    // Handle blockquotes
    if (trimmedLine.startsWith('> ')) {
      finalizePreviousList();
      parsedContent.push({ type: 'blockquote', content: trimmedLine.slice(2) });
      return;
    }

    // Handle links
    if (trimmedLine.match(/\[.*?\]\(.*?\)/)) {
      finalizePreviousList();
      const linkMatch = trimmedLine.match(/\[(.*?)\]\((.*?)\)/);
      parsedContent.push({ type: 'link', text: linkMatch[1], url: linkMatch[2] });
      return;
    }

    // Handle interrupted message
    if (trimmedLine.includes("[Generation stopped]")) {
      finalizePreviousList();
      parsedContent.push({ type: 'interrupted-message', content: 'This message was interrupted due to connection issues.' });
      return;
    }

    // Handle boldened text
    if (trimmedLine.match(/\*\*.*?\*\*/)) {
      finalizePreviousList();
      const parts = trimmedLine.split(/(\*\*.*?\*\*)/);
      parsedContent.push({
        type: 'paragraph',
        content: parts.map((part, index) => {
          if (part.startsWith('**') && part.endsWith('**')) {
            return { type: 'strong', content: part.slice(2, -2) };
          }
          return { type: 'text', content: part };
        }),
      });
      return;
    }

    // Handle paragraphs and other content
    const processedLine = processMathExpression(processChemistryInLine(line));

    if (processedLine.includes('<span class="inline-physics">') || processedLine.includes('<span class="inline-chemistry">')) {
      finalizePreviousList();
      parsedContent.push({ type: 'paragraph', content: processInlineCode(processedLine) });
    } else if (/^#+\s/.test(trimmedLine)) {
      finalizePreviousList();
      const level = trimmedLine.match(/^#+\s/)[0].length;
      const content = trimmedLine.replace(/^#+\s/, '');
      parsedContent.push({ type: 'heading', level, content });
    } else {
      finalizePreviousList();
      const parts = processedLine.split(/(`[^`]+`)/);
      const processedParts = parts.map(part => {
        if (part.startsWith('`') && part.endsWith('`')) {
          return { type: 'inline-code', content: part.slice(1, -1) };
        }
        return { type: 'text', content: part };
      });
      if (processedParts.some(part => part.type === 'inline-code')) {
        parsedContent.push({ type: 'paragraph', content: processedParts });
      } else {
        parsedContent.push({ type: 'paragraph', content: cleanupSpaces(processedLine) });
      }
    }

    // Add handling for display math blocks that start and end with $$
    if (trimmedLine.startsWith('$$') && trimmedLine.endsWith('$$')) {
      finalizePreviousList();
      parsedContent.push({ 
        type: 'math-block', 
        content: trimmedLine.slice(2, -2) // Remove $$ from start and end
      });
      return;
    }
  };

  lines.forEach((line, lineIndex) => processLine(line, lineIndex));

  finalizePreviousList();
  finalizeListItemParagraph(); // Finalize any remaining list item paragraphs

  return parsedContent;
};

const renderListItem = (item, index, isOrdered, level = 1) => {
  return (
    <li 
      key={index} 
      className="ai-response-list-item"
      value={isOrdered ? item.number : undefined}
      style={isOrdered ? { listStyleType: 'decimal' } : undefined}
    >
      <span className="ai-response-list-item-content">
        {item.content.map((part, partIndex) => {
          if (part.type === 'inline-code') {
            return (
              <code key={partIndex} className="ai-response-inline-code">
                {part.content}
              </code>
            );
          }
          return (
            <MarkdownRenderer 
              key={partIndex} 
              content={part.content}
              inline={true}
            />
          );
        })}
      </span>
      {item.subitems && item.subitems.length > 0 && (
        isOrdered ? (
          <ol className={`ai-response-sublist level-${level}`} style={{ listStyleType: 'decimal' }}>
            {item.subitems.map((subitem, subIndex) =>
              renderListItem(subitem, subIndex, isOrdered, level + 1)
            )}
          </ol>
        ) : (
          <ul className={`ai-response-sublist level-${level}`}>
            {item.subitems.map((subitem, subIndex) =>
              renderListItem(subitem, subIndex, false, level + 1)
            )}
          </ul>
        )
      )}
    </li>
  );
};

export const CodeBlock = ({ language, content }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Remove markdown code block syntax and language identifier
  const cleanContent = content.replace(/^```[\w-]*\n|```$/g, '').trim();

  // Remove the <code language="..."> line if present
  const finalContent = cleanContent.replace(/^<code language="[\w-]*">\n/, '').replace(/<\/code>$/, '').trim();

  // Remove duplicate lines
  const uniqueLines = finalContent.split('\n').filter((line, index, self) =>
    index === self.findIndex((t) => t.trim() === line.trim())
  );
  const uniqueContent = uniqueLines.join('\n');

  const lineCount = uniqueContent.split('\n').length;
  const showHeader = lineCount > 7;

  return (
    <div className="modern-code-display">
      {showHeader && (
        <div className="code-header">
          <span className="code-language">{language}</span>
          <button onClick={handleCopy} className="copy-button">
            {copied ? <Check size={16} /> : <Copy size={16} />}
            {copied ? 'Copied!' : 'Copy'}
          </button>
        </div>
      )}
      <SyntaxHighlighter
        language={language}

        wrapLines={true}
        wrapLongLines={true}
        customStyle={{
          margin: 0,
          borderRadius: showHeader ? '0 0 8px 8px' : '8px',
          padding: '1rem',
        }}
      >
        {uniqueContent}
      </SyntaxHighlighter>
    </div>
  );
};
export const renderParsedContent = (parsedContent) => {
  const renderParagraphContent = (content) => {
    // Return early if content contains math
    if (typeof content === 'string' && (content.includes('\\[') || content.includes('\\(') || content.includes('$'))) {
      return null;
    }

    if (Array.isArray(content)) {
      return content.map((part, index) => {
        // Skip math content
        if (part.type === 'math' || (typeof part.content === 'string' && 
            (part.content.includes('\\[') || part.content.includes('\\(') || part.content.includes('$')))) {
          return null;
        }

        if (part.type === 'inline-code') {
          return (
            <code key={index} className="ai-response-inline-code">
              {part.content}
            </code>
          );
        }
        if (part.type === 'strong') {
          return (
            <strong key={index} className="ai-response-strong">
              {part.content}
            </strong>
          );
        }
        return (
          <React.Fragment key={index}>
            {part.content}
          </React.Fragment>
        );
      });
    }
    return content;
  };

  return (
    <div className="ai-response-content">
      {parsedContent.map((item, index) => {
        if (item.isOrdered !== undefined && item.items) {
          return item.isOrdered ? (
            <ol 
              key={index} 
              className="ai-response-list ai-response-ordered-list" 
              start={item.start} // Add start attribute for ordered lists
            >
              {item.items.map((listItem, listIndex) =>
                renderListItem(listItem, listIndex, true, 1)
              )}
            </ol>
          ) : (
            <ul key={index} className="ai-response-list">
              {item.items.map((listItem, listIndex) =>
                renderListItem(listItem, listIndex, false, 1)
              )}
            </ul>
          );
        }

        // Existing rendering logic for other types (heading, paragraph, etc.)
        switch (item.type) {
          case 'heading':
            return React.createElement(`h${item.level}`, {
              key: index,
              className: `ai-response-heading ai-response-heading-${item.level}`,
              children: <MarkdownRenderer content={item.content} />
            });
          case 'paragraph':
            return <p key={index} className="ai-response-paragraph">{renderParagraphContent(item.content)}</p>;
          case 'strong':
            return <strong key={index} className="ai-response-strong">{item.content}</strong>;
          case 'blockquote':
            return <blockquote key={index} className="ai-response-blockquote">{item.content}</blockquote>;
          case 'link':
            return <a key={index} href={item.url} target="_blank" rel="noopener noreferrer" className="ai-response-link">{item.text}</a>;
          case 'horizontal-rule':
            return <hr key={index} className="ai-response-hr" />;
          case 'table':
            return (
              <div className="table-wrapper" key={index}>
                <table className="ai-response-table">
                  <thead>
                    <tr>
                      {item.headers.map((header, headerIndex) => (
                        <th key={headerIndex}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          case 'math-block':
            return (
              <div key={index} className="math-display">
                <MarkdownRenderer 
                  content={`$$${item.content}$$`}
                  remarkPlugins={[remarkMath]}
                  rehypePlugins={[[rehypeKatex, { output: 'htmlAndMathml' }]]}
                />
              </div>
            );
          case 'physics-block':
            return <PhysicsRenderer key={index} content={item.content.toString()} />;
          case 'chemistry-block':
            return <ChemistryRenderer key={index} content={item.content.toString()} />;
          case 'biology-block':
            return <BiologyRenderer key={index} content={item.content.toString()} />;
          case 'code-block':
            return <CodeBlock key={index} language={item.language} content={item.content} />;
          case 'essay':
            return null; // Assuming essay rendering is handled elsewhere or intentionally omitted
          case 'interrupted-message':
            return (
              <div className="interrupted-message">
                <p>{item.content}</p>
              </div>
            );
          case 'quote':
            return (
                <div className="quote-content">
                  {item.content}
                </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
export const AIDisplayRenderer = React.memo(({ content }) => {
  const parsedContent = useMemo(() => parseResponse(content), [content]);
  return renderParsedContent(parsedContent);
});

// Helper function to get the appropriate icon for each resource type
const getResourceIcon = (type) => {
  switch (type) {
    case 'code':
      return <FaCode />;
    case 'essay':
      return <FaFileAlt />;
    case 'pdf':
      return <FaFilePdf />;
    default:
      return <FaFileAlt />;
  }
};

export const AIResponseRenderer = ({ content, renderResource }) => {
  const [wasInterrupted, setWasInterrupted] = useState(false);

  const [processedContent, setProcessedContent] = useState([]);

  useEffect(() => {
    const processArtifacts = (text) => {
      const parts = [];
      let currentIndex = 0;
      let resourceCount = 0;
      let hasOpenArtifact = false;

      // Look for opening artifact tag
      while (true) {
        const artifactStart = text.indexOf('<artifacts>', currentIndex);
        if (artifactStart === -1) break;

        // Add text before the artifact
        if (artifactStart > currentIndex && !hasOpenArtifact) {
          let beforeArtifact = text.slice(currentIndex, artifactStart);
          beforeArtifact = beforeArtifact.replace(/<\|endofinput\|>.*$/s, '').trim();
          if (beforeArtifact) {
            parts.push(beforeArtifact);
          }
        }

        // Find closing tag
        const artifactEnd = text.indexOf('</artifacts>', artifactStart);
        if (artifactEnd === -1) {
          // If no closing tag found, mark as open and break
          hasOpenArtifact = true;
          break;
        }

        resourceCount++;
        const artifactContent = text.slice(artifactStart + 10, artifactEnd);
        const resourceId = `resource-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        const resource = parseArtifact(artifactContent, resourceId, resourceCount);
        parts.push(renderResource(resource));

        currentIndex = artifactEnd + 11; // 11 is length of '</artifacts>'
        hasOpenArtifact = false;
      }

      // Only add remaining text if there's no open artifact
      if (currentIndex < text.length && !hasOpenArtifact) {
        let remainingText = text.slice(currentIndex);
        remainingText = remainingText.replace(/<\|endofinput\|>.*$/s, '').trim();
        if (remainingText) {
          parts.push(remainingText);
        }
      }

      return parts;
    };

    let sanitizedContent = processArtifacts(content);

    // Further sanitize the content by removing any PDF title markers
    sanitizedContent = sanitizedContent.map(part => {
      if (typeof part === 'string') {
        return part.replace(/\[PDF TITLE: .*?\]/g, '').replace(/(?<!\S)>(?!\S)/g, '').trim();
      }
      return part;
    });

    setProcessedContent(sanitizedContent);
  }, [content, renderResource]);

  return (
    <div className="ai-response-content">
      {processedContent.map((part, index) => 
        typeof part === 'string' ? (
          <React.Fragment key={index}>
            <AIDisplayRenderer content={part} />
            {wasInterrupted && (
              <div className="interrupted-message">
                <p>This message was interrupted due to connection issues.</p>
              </div>
            )}
          </React.Fragment>
        ) : (
          part // This is already a React element
        )
      )}
    </div>
  );
};

// Ensure parseArtifact correctly identifies 'essay' and 'code' types
const parseArtifact = (content, id, count) => {
  if (content.includes('<essay>')) {
    const titleMatch = content.match(/<title>(.*?)<\/title>/);
    const contentMatch = content.match(/<content>([\s\S]*?)<\/content>/);
    return {
      id,
      type: 'essay',
      title: titleMatch ? titleMatch[1] : `Essay ${count}`,
      content: contentMatch ? contentMatch[1] : content,
    };
  } else if (content.includes('<code')) {
    const languageMatch = content.match(/language="(.*?)"/);
    const titleMatch = content.match(/title="(.*?)"/);
    return {
      id,
      type: 'code',
      title: titleMatch ? titleMatch[1] : `Code Snippet ${count}`,
      language: languageMatch ? languageMatch[1] : 'plaintext',
      content: content.replace(/<code.*?>([\s\S]*?)<\/code>/, '$1'),
    };
  } else if (content.includes('<visualization')) {
    const vizMatch = content.match(/<visualization.*?>([\s\S]*?)<\/visualization>/);
    const titleMatch = content.match(/title="(.*?)"/);
    
    if (vizMatch && titleMatch) {
      const vizContent = vizMatch[1];
      try {
        // Validate that the content is valid JSON
        JSON.parse(vizContent);
        return {
          id,
          type: 'visualization',
          title: titleMatch[1],
          content: vizContent,
        };
      } catch (error) {
        // If JSON parsing fails, return as generic content
        return {
          id,
          type: 'generic',
          title: `Resource ${count}`,
          content,
        };
      }
    }
  }
  
  // Default case for unrecognized content
  return {
    id,
    type: 'generic',
    title: `Resource ${count}`,
    content,
  };
};

